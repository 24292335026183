import { ApplicationState } from "..";
import { AppConfig, MailConnection, MailTemplate } from "../Configuration";
import { Language } from "../Language";

export const selectAppConfig = (rootState: ApplicationState): AppConfig => {
    return rootState.config!.appConfig;
}

export const selectMailConnections = (rootState: ApplicationState): MailConnection[] => {
    return rootState.config!.appConfig.mailConnections;
}

export const selectMailTemplates = (rootState: ApplicationState): MailTemplate[] => {
    return rootState.config!.appConfig.mailTemplates;
}

export const selectLanguages = (rootState: ApplicationState): Language[] => {
    return rootState.config!.appConfig.languages;
}

