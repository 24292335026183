import { CountUp } from 'use-count-up'
import React from 'react';
import { CountUpBlockData } from '../../../store/Block';

interface Props {
    isCounting: boolean,
    countUpBlock: CountUpBlockData,
}

const CountUpBlock = (props: Props) => <CountUp isCounting={props.isCounting} end={props.countUpBlock.countTo} duration={props.countUpBlock.duration} />

export default CountUpBlock;