import { Box, TextField, FormControl, InputLabel, Select, MenuItem, FormControlLabel, Checkbox, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import { BlockEventTrigger, EventTriggerType } from '../../../store/Block';
import useDeepEffect from '../../../hooks/useDeepEffect';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { EventContext } from '../../Contexts/BlockEventContext';

const PREFIX = 'EventTriggerDialog';

const classes = {
    formControl: `${PREFIX}-formControl`,
    dialogContent: `${PREFIX}-dialogContent`,
};

const StyledDialog = styled(Dialog)((
    {
        theme
    }
) => ({
    [`& .${classes.formControl}`]: {
        width: '100%',
    },
    [`& .${classes.dialogContent}`]: {
        '& > *': {
            marginTop: theme.spacing(1),
        },
    },
}));

interface Props {
    blockEventTrigger: BlockEventTrigger | undefined,
    dialogOpenHandler: () => void;
    submitHandler: (blockEventTrigger: BlockEventTrigger) => void;
    isOpen: boolean;
}

const EventTriggerDialog = (props: Props) => {

    const defaultEventTrigger: BlockEventTrigger = { id: 0, scrollHeight: 0, eventTriggerType: EventTriggerType.OnClick, zeroBased: false, initiallyTriggered: false };
    const { blockEventTrigger, isOpen, dialogOpenHandler, submitHandler } = props;

    const [currentBlockEventTrigger, setCurrentBlockEventTrigger] = useState<BlockEventTrigger>(defaultEventTrigger);

    useDeepEffect(() => {
        if (blockEventTrigger === undefined) {
            setCurrentBlockEventTrigger(defaultEventTrigger)
        } else {
            setCurrentBlockEventTrigger(blockEventTrigger)
        }
    }, [blockEventTrigger]);

    const eventContext = useContext(EventContext);

    const handleBlockEventChange = (eventId: number) => {
        const selectedEvent = eventContext.events
            .map(x => x.BlockEvent)
            .find(x => x.id === eventId);

        if (selectedEvent)
            setCurrentBlockEventTrigger({ ...currentBlockEventTrigger, blockEvent: selectedEvent });
    }

    const handleOnClose = () => {
        setCurrentBlockEventTrigger(defaultEventTrigger);
        dialogOpenHandler();
    }

    const handleOnSubmit = () => {
        submitHandler(currentBlockEventTrigger);
        handleOnClose();
    }


    return (
        <StyledDialog
            open={isOpen}
            onClose={handleOnClose}
            fullWidth
            maxWidth="md"
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{"Eventtrigger"}</DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <FormControl className={classes.formControl}>
                    <InputLabel id="event-trigger-type">Event type </InputLabel>
                    <Select
                        labelId="event-trigger-type"
                        value={currentBlockEventTrigger.eventTriggerType}
                        onChange={(e) => setCurrentBlockEventTrigger({ ...currentBlockEventTrigger, eventTriggerType: e.target.value as EventTriggerType })}
                    >
                        <MenuItem value="0">OnClick</MenuItem>
                        <MenuItem value="1">OnScroll</MenuItem>
                        <MenuItem value="2">OnSubmit</MenuItem>
                    </Select>
                </FormControl>{
                    currentBlockEventTrigger.eventTriggerType.valueOf() !== EventTriggerType.OnSubmit.valueOf() ? (
                        <>
                            <FormControl className={classes.formControl}>
                                <InputLabel id="event-trigger-event">Event</InputLabel>
                                <Select
                                    labelId="event-trigger-event"
                                    value={currentBlockEventTrigger?.blockEvent?.id ?? 0}
                                    onChange={(e) => handleBlockEventChange(Number(e.target.value))}
                                >
                                    {
                                        eventContext.events.map(x => x.BlockEvent)
                                            .map((be) => (
                                                <MenuItem key={be.id} value={be.id}>{be.eventName}</MenuItem>
                                            ))
                                    }
                                </Select>
                            </FormControl>
                            <FormControlLabel
                                value="end"
                                control={<Checkbox icon={<CheckBoxOutlineBlankIcon fontSize="small" />} checkedIcon={<CheckBoxIcon fontSize="small" />} color="primary" checked={currentBlockEventTrigger.initiallyTriggered} onChange={(e, checked) => setCurrentBlockEventTrigger({ ...currentBlockEventTrigger, initiallyTriggered: checked })} />}
                                label="Initially triggered"
                                labelPlacement="end"
                            />
                            {
                                currentBlockEventTrigger.eventTriggerType == EventTriggerType.OnScroll ? (
                                    <>
                                        <FormControlLabel
                                            value="end"
                                            control={<Checkbox icon={<CheckBoxOutlineBlankIcon fontSize="small" />} checkedIcon={<CheckBoxIcon fontSize="small" />} color="primary" checked={currentBlockEventTrigger.zeroBased} onChange={(e, checked) => setCurrentBlockEventTrigger({ ...currentBlockEventTrigger, zeroBased: checked })} />}
                                            label="Zero based scroll height"
                                            labelPlacement="end"
                                        />
                                        <TextField label="Scroll height" fullWidth placeholder="Scroll height" type="number" value={currentBlockEventTrigger.scrollHeight} onChange={(e) => setCurrentBlockEventTrigger({ ...currentBlockEventTrigger, scrollHeight: Number(e.target.value) })} />
                                    </>
                                ) : null
                            }
                        </>) : null}
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={handleOnClose}>
                    Cancel
                </Button>
                <Button color="primary" onClick={handleOnSubmit}>
                    Save
                </Button>
            </DialogActions>
        </StyledDialog>
    );
}

export default EventTriggerDialog;