import React, { useState, FormEvent, ChangeEvent } from 'react';
import { styled } from '@mui/material/styles';
import { ApplicationState } from '../../store';
import { connect, useDispatch, useSelector } from 'react-redux';
import { actionCreators } from '../../store/Media';
import * as MediaStore from '../../store/Media';
import MediaFiles from './MediaFiles';
import AddBoxSharpIcon from '@mui/icons-material/AddBoxSharp';
import { Theme } from '@mui/material/styles';
import { AppBar, Button, Grid, Menu, MenuItem, Dialog, Toolbar, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Breadcrumbs, Checkbox } from '@mui/material';
import { selectMedia } from '../../store/selectors/MediaSelector';
import MediaDialog from './Modals/FolderDialog';
import MediaFolders from './MediaFolders';
import { indexOf } from 'lodash';
import MoveFileDialog from './Modals/MoveFileDialog';

const PREFIX = 'MediaDataContainer';

const classes = {
    appBar: `${PREFIX}-appBar`,
    menuButton: `${PREFIX}-menuButton`,
    title: `${PREFIX}-title`,
    input: `${PREFIX}-input`,
    label: `${PREFIX}-label`,
    content: `${PREFIX}-content`,
    root: `${PREFIX}-root`,
    mainroot: `${PREFIX}-mainroot`,
    tableContainer: `${PREFIX}-tableContainer`,
    imageCell: `${PREFIX}-imageCell`,
    button: `${PREFIX}-button`,
    crumb: `${PREFIX}-crumb`,
};

const Root = styled('div')((
    {
        theme
    }
) => ({
    [`& .${classes.button}`]: {
        marginLeft: theme.spacing(1),
    },

    [`& .${classes.appBar}`]: {
        flexGrow: 1,
    },

    [`& .${classes.crumb}`]: {
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline'
        }
    },

    [`& .${classes.menuButton}`]: {
        marginRight: theme.spacing(2),
    },

    [`& .${classes.title}`]: {
        flexGrow: 1,
    },

    [`& .${classes.input}`]: {
        display: 'none',
    },

    [`& .${classes.label}`]: {
        marginBottom: 0,
    },

    [`& .${classes.tableContainer}`]: {
        maxHeight: 'calc(100vh - 64px)',
        '& th': {
            fontWeight: 'bold',
        }
    },

    [`& .${classes.root}`]: {
        display: 'flex',
    },

    [`&.${classes.mainroot}`]: {
        marginLeft: '239px',
    },
    [`& .${classes.imageCell}`]: {
        width: '40px',
    }
}));

const supportedTypes = [
    "image/gif",
    "image/jpeg",
    "image/png",
    "image/svg",
    "image/svg+xml",
    "video/mp4",
    "application/pdf"
]

const MediaDataContainer = () => {

    const defaultFolder = "/images"
    const hiddenFileInput = React.useRef<HTMLInputElement>(null);
    const [modifyFileOpen, setModifyFileOpen] = useState(false);
    const [currentMediaFile, setCurrentMediaFile] = useState<MediaStore.MediaFile | undefined>(undefined);
    const [folderDialogOpen, setFolderDialogOpen] = useState(false);
    const [currentFolder, setCurrentFolder] = useState("/images");
    const [selectedFiles, setSelectedFiles] = useState<number[]>([]);
    const [moveDialogOpen, setMoveDialogOpen] = useState<boolean>(false);

    const files = useSelector(selectMedia);
    const dispatch = useDispatch();

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event?.target?.files?.length) {
            dispatch(actionCreators.uploadFile(event.target.files, currentFolder));
        }
    };

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>, id: number) => {
        if (event?.target?.files?.length && supportedTypes.indexOf(event?.target?.files[0].type) > -1) {
            let file = event.target.files[0];
            let fileName = event.target.files[0].name;

            dispatch(actionCreators.uploadNewFile(fileName, file, id, currentFolder));
        }
    };

    const toggleMediaDialog = () => {
        setFolderDialogOpen(prev => !prev);
    }

    const updateMediaFileOpen = (mediaFile: MediaStore.MediaFile) => {
        setCurrentMediaFile({ ...mediaFile });
        toggleMediaDialog();
    }

    const fileDeleteHandler = () => {
        selectedFiles.forEach((fileId) => dispatch(actionCreators.deleteFile(fileId)));        
        setSelectedFiles([]);
    }

    const handleSelectedFilesChanged = (id: number) => {
        let newSelected = [...selectedFiles];

        if(newSelected.find(x => x === id)){
            newSelected = newSelected.filter(x => x !== id);
        } else {
            newSelected.push(id);
        }

        setSelectedFiles(newSelected);
    }

    const folders = files.filter(x =>
        x.extension === 'folder'
        && x.path.startsWith(currentFolder)
        && x.path.replace(currentFolder + "/", "") === x.name
        && x.path !== currentFolder
    );

    const currentFiles = files.filter(x =>
        x.extension !== 'folder'
        && x.path.replace(currentFolder + "/", "") === x.name + x.extension
        && x.path.startsWith(currentFolder)
    );

    const handleSelectAll = () => {
        if(selectedFiles.length === folders.length + currentFiles.length)
            setSelectedFiles([]);
        else {
            setSelectedFiles([...currentFiles.map((x) => x.id), ... folders.map((x) => x.id)]);
        }
    }


    const submitMedia = (media: MediaStore.MediaFile) => {
        if (media.extension === 'folder' && media.id === 0)
            dispatch(actionCreators.addFolder(media, currentFolder));
        else
            dispatch(actionCreators.updateFile(media, currentFolder));
    }

    const addFolderHandler = () => {
        setCurrentMediaFile(undefined);
        toggleMediaDialog();
    }

    const folderChangeHandler = (path: string) => {
        setCurrentFolder(path);
    }

    const moveFileHandler = (path: string) => {
        selectedFiles.forEach((y) => {
            const mediaFile = files.find(x => x.id === y);
            
            if(mediaFile !== undefined)
                dispatch(actionCreators.moveFile(mediaFile, path))
        })

        setSelectedFiles([]);
    }

    const clickBreadcrumbHandler = (text: string) => {
        const newPath = files.find(x => x.path.endsWith("/"+text))?.path ?? "/images";
        setCurrentFolder(newPath);
    }

    const closeMoveFileDialog = () => {
        setMoveDialogOpen(false);
    }

    React.useEffect(() => {
        dispatch(actionCreators.getFiles());
    }, [])


    return (
        <Root className={classes.mainroot}>
            <AppBar position="static" color="primary">
                <Toolbar>
                    <input
                        accept="image/gif, image/jpeg, image/png, video/mp4"
                        className={classes.input}
                        id="contained-button-file"
                        type="file"
                        ref={hiddenFileInput}
                        onChange={handleChange}
                        multiple
                    />
                    <label className={classes.label} htmlFor="contained-button-file">
                        <Button className={classes.button} variant="contained" color="secondary" component="span" startIcon={<AddBoxSharpIcon />}>
                            Upload
                        </Button>
                    </label>
                    <Button variant="contained" className={classes.button} color="secondary" onClick={addFolderHandler} startIcon={<AddBoxSharpIcon />}>
                        Add Folder
                    </Button>
                    <Button variant="contained" className={classes.button} disabled={selectedFiles.length < 1} color="secondary" onClick={() => setMoveDialogOpen(true)} startIcon={<AddBoxSharpIcon />}>
                        Move
                    </Button>
                    <Button variant="contained" className={classes.button} disabled={selectedFiles.length < 1} color="secondary" onClick={fileDeleteHandler} startIcon={<AddBoxSharpIcon />}>
                        Delete
                    </Button>
                </Toolbar>
            </AppBar>
            <TableContainer className={classes.tableContainer} component={Paper}>
                <Table stickyHeader sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableRow>
                        <TableCell>                            
                            <Checkbox checked={selectedFiles.length === folders.length + currentFiles.length} onClick={handleSelectAll}/>
                        </TableCell>
                        <TableCell colSpan={6}>
                            <Breadcrumbs>
                                {
                                    currentFolder.split("/").map((text) => (
                                        <Typography key={text} onClick={() => clickBreadcrumbHandler(text)} className={classes.crumb} color="text.primary">{text}</Typography>
                                    ))
                                }
                            </Breadcrumbs>
                        </TableCell>
                    </TableRow>
                    <MediaFolders handleSelectedFilesChanged={handleSelectedFilesChanged} selectedFiles={selectedFiles} files={folders} folderChangeHandler={folderChangeHandler} modifyHandler={updateMediaFileOpen} fileChangeHandler={handleFileChange} />
                    <MediaFiles handleSelectedFilesChanged={handleSelectedFilesChanged} selectedFiles={selectedFiles} files={currentFiles} modifyHandler={updateMediaFileOpen} fileChangeHandler={handleFileChange} />
                </Table>
            </TableContainer>
            <MediaDialog closeDialog={toggleMediaDialog} currentMediaFile={currentMediaFile} dialogOpen={folderDialogOpen} submitDialog={submitMedia} />
            <MoveFileDialog closeDialog={closeMoveFileDialog} dialogOpen={moveDialogOpen} folders={files.filter(x => x.extension === 'folder').map((folder) => folder.path)} submitDialog={moveFileHandler}/>
        </Root>
    );
}

export default MediaDataContainer;