import React, { MutableRefObject, ReactNode } from 'react';
import { Row } from 'react-grid-system';

/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react';
import { RowBlockData } from '../../../store/Block';


interface Props {
    classes: string,
    blockClickHandler: (e: React.MouseEvent<HTMLElement>) => void,
    children: ReactNode,
    styles: any,
    block: RowBlockData
}


const RowBlock = (props: Props) => {
    const { children, classes, blockClickHandler, styles, block } = props;

    const noGutterClass = block.noGutters ? ' g-0' : '';

    let displayedBlock = (
        <Row css={styles} className={classes + noGutterClass} onClick={blockClickHandler}>
            {children}
        </Row>
    );

    return (
        <>
            {displayedBlock}
        </>
    );
}

export default RowBlock;