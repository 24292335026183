import { AppThunkAction } from ".";
import { BlockData } from "./Block";
import Axios from '../services/api';
import { updateObject } from "../Utility";
import { Reducer } from "redux";
import SnackBarUtils from "../components/SnackBar/SnackBarOperations";

export interface TemplateData {
    id: number,
    name: string,
}

export interface TemplateState {
    templates: TemplateData[];
    selectedTemplate: TemplateData | null;
    error: string;
    loading: boolean;
};

export interface TemplateSuccessAction {
    type: 'TEMPLATE_SUCCESS',
    templates: TemplateData[]
}

export interface TemplateAddSuccessAction {
    type: 'TEMPLATE_ADD_SUCCESS',
    template: TemplateData
}

export interface TemplateDeleteSuccessAction {
    type: 'TEMPLATE_DELETE_SUCCESS',
    template: TemplateData
}

export interface TemplateUpdateSuccessAction {
    type: 'TEMPLATE_UPDATE_SUCCESS',
    template: TemplateData
}

export interface TemplateSelectAction {
    type: 'TEMPLATE_SELECT',
    template: TemplateData | null
}

export interface TemplateStartAction {
    type: 'TEMPLATE_START'
}

export interface TemplateFailAction {
    type: 'TEMPLATE_FAIL',
    error: string
}

export type KnownAction =
    | TemplateStartAction
    | TemplateFailAction
    | TemplateSuccessAction
    | TemplateAddSuccessAction
    | TemplateDeleteSuccessAction
    | TemplateUpdateSuccessAction
    | TemplateSelectAction;


export const actionCreators = {
    createTemplate: (template: TemplateData): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'TEMPLATE_START' });
        const appState = getState();

        if (!appState.auth)
            return;

        const url = '/template/';
        Axios.post(url, { Template: template, LocationPath: appState.block?.selectedBlock?.locationPath })
            .then(response => {
                SnackBarUtils.success('Template wurde erstellt.');
                dispatch({ type: 'TEMPLATE_ADD_SUCCESS', template: response.data });
            })
            .catch(error => {
                SnackBarUtils.error('Beim Erstellen des Templates ist ein Fehler aufgetreten.');
                dispatch({ type: 'TEMPLATE_FAIL', error: error.response.data.error });
            })
    },
    updateTemplate: (template: TemplateData): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'TEMPLATE_START' });
        const appState = getState();

        if (!appState.auth)
            return;

        const url = '/template/update';
        Axios.post(url, template)
            .then(response => {
                SnackBarUtils.success('Template wurde geändert.');
                dispatch({ type: 'TEMPLATE_UPDATE_SUCCESS', template: response.data });
            })
            .catch(error => {
                SnackBarUtils.error('Beim Ändern des Templates ist ein Fehler aufgetreten.');
                dispatch({ type: 'TEMPLATE_FAIL', error: error.response.data.error });
            })
    },
    selectTemplate: (template: TemplateData | null): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'TEMPLATE_SELECT', template: template });
    },
    deleteTemplate: (template: TemplateData): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'TEMPLATE_START' });
        const appState = getState();

        if (!appState.auth)
            return;

        const url = '/template/' + template.id;
        Axios.delete(url)
            .then(response => {
                SnackBarUtils.success('Template wurde gelöscht.');
                dispatch({ type: 'TEMPLATE_DELETE_SUCCESS', template: template });
            })
            .catch(error => {
                SnackBarUtils.error('Beim Löschen des Templates ist ein Fehler aufgetreten.');
                dispatch({ type: 'TEMPLATE_FAIL', error: error.response.data.error });
            })
    },
    getTemplates: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'TEMPLATE_START' });
        const appState = getState();

        if (!appState.auth)
            return;

        const url = '/template';
        Axios.get(url)
            .then(response => {
                SnackBarUtils.success('Templates wurden geladen.');
                dispatch({ type: 'TEMPLATE_SUCCESS', templates: response.data });
            })
            .catch(error => {
                SnackBarUtils.error('Beim Laden der Templates ist ein Fehler aufgetreten.');
                dispatch({ type: 'TEMPLATE_FAIL', error: error.response.data.error });
            })
    },
};

const unloadedState: TemplateState = {
    templates: [],
    selectedTemplate: null,
    error: "",
    loading: false,
};

export const reducer: Reducer<TemplateState | undefined> = (state: TemplateState | undefined, incomingAction: KnownAction): TemplateState | undefined => {
    if (state === undefined) {
        return unloadedState;
    }
    switch (incomingAction.type) {
        case 'TEMPLATE_SUCCESS':
            return updateObject(state, { templates: [...incomingAction.templates], error: null, loading: false });
        case 'TEMPLATE_ADD_SUCCESS':
            return updateObject(state, { templates: [...state.templates, incomingAction.template], error: null, loading: false });
        case 'TEMPLATE_DELETE_SUCCESS':
            return updateObject(state, { templates: [...state.templates.filter(x => x.id !== incomingAction.template.id)], error: null, loading: false });
        case 'TEMPLATE_UPDATE_SUCCESS':
            return updateObject(state, { templates: [...state.templates.filter((x: TemplateData) => x.id !== incomingAction.template.id), { ...incomingAction.template }], error: null, loading: false });
        case 'TEMPLATE_SELECT':
            return updateObject(state, { selectedTemplate: incomingAction.template });
        case 'TEMPLATE_START':
            return updateObject(state, { error: null, loading: true });
        case 'TEMPLATE_FAIL':
            return updateObject(state, { error: incomingAction.error, loading: false });
        default:
            return state;

    }
}