import { AppBar, Button, Card, CardActions, CardContent, CardHeader, Dialog, Divider, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Toolbar, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import DeleteSharpIcon from '@mui/icons-material/DeleteSharp';
import EditIcon from '@mui/icons-material/Edit';
import AutoAwesomeMosaicIcon from '@mui/icons-material/AutoAwesomeMosaic';
import AddBoxSharpIcon from '@mui/icons-material/AddBoxSharp';
import { useNavigate } from 'react-router-dom';
import { actionCreators, TemplateData, TemplateState } from '../../store/Template';
import TemplateModal from '../BlockBuilder/modals/TemplateModal';
import ConfirmationDialog from '../UI/ConfirmationDialog';
import { selectTemplates } from '../../store/selectors/TemplateSelector';

const PREFIX = 'TemplateList';

const classes = {
    root: `${PREFIX}-root`,
    gridroot: `${PREFIX}-gridroot`,
    expandOpen: `${PREFIX}-expandOpen`,
    button: `${PREFIX}-button`,
    extendedIcon: `${PREFIX}-extendedIcon`,
    buttonCell: `${PREFIX}-buttonCell`,
    tableContainer: `${PREFIX}-tableContainer`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
    {
        theme
    }
) => ({
    [`& .${classes.root}`]: {
        marginLeft: '239px',
    },
    [`& .${classes.gridroot}`]: {
        padding: theme.spacing(1),
    },
    [`& .${classes.expandOpen}`]: {
        transform: 'rotate(180deg)',
    },
    [`& .${classes.button}`]: {
        marginLeft: theme.spacing(1),
    },
    [`& .${classes.extendedIcon}`]: {
        marginRight: theme.spacing(1),
    },
    [`& .${classes.buttonCell}`]: {
        width: '150px',
    },
    [`& .${classes.tableContainer}`]: {
        maxHeight: 'calc(100vh - 80px)',
        '& th': {
            fontWeight: 'bold',
        }
    }
}));

const TemplateList = () => {

    const [currentTemplate, setCurrentTemplate] = useState<TemplateData | undefined>(undefined);
    const [templateModalOpen, setTemplateModalOpen] = useState(false);
    const [confirmOpen, setConfirmOpen] = useState(false);

    const dispatch = useDispatch();
    const templateData = useSelector(selectTemplates);

    let history = useNavigate();
    const submitTemplateModal = (newTemplate: TemplateData) => {
        toggleTemplateModal();

        if (newTemplate.id > 0) {
            dispatch(actionCreators.updateTemplate(newTemplate));
        } else {
            dispatch(actionCreators.createTemplate(newTemplate));
        }
    }

    const handleConfirmOpen = (template: TemplateData) => {
        setCurrentTemplate(template);
        toggleConfirmOpen();
    }

    const toggleConfirmOpen = () => {
        setConfirmOpen(prevState => {
            if (prevState) {
                setCurrentTemplate(undefined);
            }

            return !prevState
        });
    }

    const toggleEditTemplateModal = (selectedTemplate: TemplateData) => {
        setCurrentTemplate(selectedTemplate);
        toggleTemplateModal();
    };

    const toggleTemplateModal = () => setTemplateModalOpen(prevState => {
        if (prevState) {
            setCurrentTemplate(undefined);
        }

        return !prevState
    });

    const handleTemplateBuilder = (template: TemplateData) => {
        dispatch(actionCreators.selectTemplate(template));
        history("/builder");
    }

    const handleTemplateDelete = () => {
        if(currentTemplate)
            dispatch(actionCreators.deleteTemplate(currentTemplate));
    }

    useEffect(() => {
        dispatch(actionCreators.getTemplates());
    }, [])

    const templates = templateData.sort((n1: TemplateData, n2: TemplateData) => {
        if (n1.name > n2.name)
            return 1;

        if (n1.name < n2.name)
            return -1;
        return 0;
    }).map((template: TemplateData) => (

        <TableRow key={template.id}>
            <TableCell>{template.name}</TableCell>
            <TableCell className={classes.buttonCell}>
                <IconButton onClick={() => toggleEditTemplateModal(template)} size="small">
                    <EditIcon/>
                </IconButton>
                <IconButton onClick={() => handleTemplateBuilder(template)} size="small">
                    <AutoAwesomeMosaicIcon />
                </IconButton>
                <IconButton onClick={() => handleConfirmOpen(template)} size="small">
                    <DeleteSharpIcon />
                </IconButton>
            </TableCell>
        </TableRow>
    ));

    return (
        (<Root>
            <div className={classes.root}>
                <AppBar position="static" color="primary">
                    <Toolbar disableGutters>
                        <Button variant="contained" className={classes.button} color="secondary" onClick={toggleTemplateModal} startIcon={<AddBoxSharpIcon />}>
                            Add Template
                        </Button>
                    </Toolbar>
                </AppBar>
                <div className={classes.gridroot}>
                    <TableContainer className={classes.tableContainer} component={Paper}>
                        <Table stickyHeader sx={{ minWidth: 700 }} aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {templates}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Dialog fullWidth maxWidth="md" open={templateModalOpen} onClose={toggleTemplateModal} >
                        <TemplateModal templateData={currentTemplate} toggleTemplateModal={toggleTemplateModal} submitTemplateModal={submitTemplateModal} />
                    </Dialog>
                </div>
            </div>
            <ConfirmationDialog isOpen={confirmOpen} clickHandler={toggleConfirmOpen} submitFunction={handleTemplateDelete} />
        </Root>)
    );
}

export default TemplateList;