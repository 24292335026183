import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

interface Props {
    clickHandler: () => void,
    isOpen: boolean,
    submitFunction: () => void,
}

export default function ConfirmationDialog(props: Props) {
    const {clickHandler, submitFunction, isOpen} = props;

    const handleSubmit = () => {
        clickHandler();
        submitFunction();
    }

    return (
        <div>
            <Dialog
                open={isOpen}
                onClose={clickHandler}
                aria-labelledby="confirmation-dialog-title"
                aria-describedby="confirmation-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Bist du wirklich sicher?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Die Daten werden endgültig gelöscht!
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={clickHandler} color="primary">
                        cancel
                    </Button>
                    <Button onClick={handleSubmit} color="primary" autoFocus>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}