import React, { FormEvent, useState, ChangeEvent } from 'react';
import { connect } from 'react-redux';
import * as AuthStore from '../store/Auth';
import { ApplicationState } from '../store';
import { Button, Paper, styled, TextField, Typography } from '@mui/material';

const PREFIX = 'Login';

const classes = {
    root: `${PREFIX}-root`,
    paper: `${PREFIX}-paper`,
    inputs: `${PREFIX}-inputs`
};

const Root = styled('div')((
    { theme }
) => ({
    [`& .${classes.root}`]: {
        height: 'calc(100vh - 64px)',
    },

    [`& .${classes.paper}`]: {
        padding: theme.spacing(3),
        position: 'absolute',
        transform: 'translate(-50%, -50%)',
        top: '50%',
        left: '50%',
    },

    [`& .${classes.inputs}`]: {
        display: 'flex',
        flexDirection: 'column',
        '& > *:not(:last-child)': {
            marginBottom: theme.spacing(1)
        }
    }
}));
const Login = (props: any) => {

    const [userName, setUserName] = useState<string>("");
    const [password, setPassword] = useState<string>("");

    const LoginSubmitHandler = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        props.authenticate(userName, password);
    }

    const UserNameChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        setUserName(event.target.value);
    }

    const PasswordChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value);
    }

    return (
        <Root className={classes.root}>
            <Paper className={classes.paper} elevation={12}>
                <form onSubmit={LoginSubmitHandler} noValidate autoComplete="off" className={classes.inputs}>
                    <Typography variant="h4" component="h1">
                        Login
                    </Typography>
                    <TextField label="User" value={userName} onChange={UserNameChangeHandler} />
                    <TextField label="Password" type="password" value={password} onChange={PasswordChangeHandler} />
                    <Button variant="contained" color="primary" type="submit">
                        Submit
                    </Button>
                </form>
            </Paper>
        </Root>
    );
}

export default connect(
    (state: ApplicationState) => state.auth, // Selects which state properties are merged into the component's prop
    AuthStore.actionCreators // Selects which action creators are merged into the component's props
)(Login as any);