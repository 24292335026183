import { AppBar, Button, IconButton, Paper, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Toolbar } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectLanguages } from '../../store/selectors/ConfigSelector';
import { selectTranslationTags } from '../../store/selectors/LanguageSelector';
import DeleteSharpIcon from '@mui/icons-material/DeleteSharp';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import { actionCreators, Language, TranslationTag } from '../../store/Language';
import { actionCreators as configActions } from '../../store/Configuration';
import TranslationsDialog from './TranslationsDialog';
import AddBoxSharpIcon from '@mui/icons-material/AddBoxSharp';

const PREFIX = 'TranslationsTable';

const classes = {
    root: `${PREFIX}-root`,
    tableContainer: `${PREFIX}-tableContainer`,
    buttonCell: `${PREFIX}-buttonCell`,
    button: `${PREFIX}-button`,
    gridroot: `${PREFIX}-gridroot`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
    {
        theme
    }
) => ({
    [`& .${classes.root}`]: {
        marginLeft: '239px',
    },
    [`& .${classes.gridroot}`]: {
        padding: theme.spacing(1),
    },
    [`& .${classes.tableContainer}`]: {
        maxHeight: 'calc(100vh - 80px)',
        '& th': {
            fontWeight: 'bold',
        }
    },
    [`& .${classes.buttonCell}`]: {
        width: '150px',
    },
    [`& .${classes.button}`]: {
        marginLeft: theme.spacing(1),
    },
}));

const TranslationsTable = () => {

    const [currentTranslationTag, setCurrentTranslationTag] = React.useState<TranslationTag | undefined>(undefined);
    const [translationTagDialogOpen, SetTranslationTagDialogOpen] = React.useState(false);
    const dispatch = useDispatch();
    const languages = useSelector(selectLanguages);
    const translationTags = useSelector(selectTranslationTags);

    const languageHeader = languages
        .sort((a: Language, b: Language) => a.name > b.name ? -1 : 1)
        .map((lang) => (
            <TableCell key={lang.id}>{lang.name}</TableCell>
        ))

    const translationRows = translationTags
        .sort((a: TranslationTag, b: TranslationTag) => a.name > b.name ? -1 : 1)
        .map((translationTag) => (
            <TableRow key={translationTag.id}>
                <TableCell>{translationTag.name}</TableCell>
                {
                    languages.map((lang) => {
                        const translation = translationTag.translations.find(x => x.language.id === lang.id)
                        if (translation) {
                            return (
                                <TableCell key={translation.id}>{translation.text}</TableCell>
                            )
                        }
                    })
                }
                <TableCell>
                    <IconButton onClick={() => editTranslationTag(translationTag.id)} size="small">
                        <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => deleteTranslationTag(translationTag.id)} size="small">
                        <DeleteSharpIcon />
                    </IconButton>
                </TableCell>
            </TableRow>
        ));

    const submitDialog = (translationTag: TranslationTag) => {
        if (translationTag.id === 0)
            dispatch(actionCreators.createTranslationTag(translationTag));
        else
            dispatch(actionCreators.updateTranslationTag(translationTag));
    }

    const deleteTranslationTag = (id: number) => {
        dispatch(actionCreators.deleteTranslationTag(id));
    }

    const editTranslationTag = (id: number) => {
        const currentTag = translationTags.find(x => x.id === id);

        if (currentTag) {
            setCurrentTranslationTag(currentTag);
            SetTranslationTagDialogOpen(true);
        }
    }

    const closeDialog = () => {
        setCurrentTranslationTag(undefined);
        SetTranslationTagDialogOpen(false);
    }

    return (
        <Root>
            <div className={classes.root}>
                <AppBar position="static" color="primary">
                    <Toolbar disableGutters>
                        <Button className={classes.button} variant="contained" color="secondary" onClick={() => SetTranslationTagDialogOpen(true)} startIcon={<AddBoxSharpIcon />}>
                            Add Translation
                        </Button>
                    </Toolbar>
                </AppBar>
                <div className={classes.gridroot}>
                    <TableContainer className={classes.tableContainer} component={Paper}>
                        <Table stickyHeader sx={{ minWidth: 700 }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Tag</TableCell>
                                    {languageHeader}
                                    <TableCell className={classes.buttonCell} />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {translationRows}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>
            <TranslationsDialog handleClose={closeDialog} languages={languages} translationTag={currentTranslationTag} open={translationTagDialogOpen} handleSubmit={submitDialog} />
        </Root>
    );
}

export default TranslationsTable;