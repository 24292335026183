import { Box, TextField, FormControl, InputLabel, Select, MenuItem, FormControlLabel, Checkbox, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useState } from 'react';
import { BlockEvent, EventType } from '../../../store/Block';
import useDeepEffect from '../../../hooks/useDeepEffect';

const PREFIX = 'EventDialog';

const classes = {
    formControl: `${PREFIX}-formControl`,
    dialogContent: `${PREFIX}-dialogContent`
};

const StyledDialog = styled(Dialog)((
    {
        theme
    }
) => ({
    [`& .${classes.formControl}`]: {
        width: '100%',
    },
    [`& .${classes.dialogContent}`]: {
        '& > *': {
            marginTop: theme.spacing(1),
        },
    },
}));

interface Props {
    blockEvent: BlockEvent | undefined,
    dialogOpenHandler: () => void;
    submitHandler: (blockEvent: BlockEvent) => void;
    isOpen: boolean;
}

const EventDialog = (props: Props) => {

    const defaultEvent: BlockEvent = { id: 0, propagateSource: false, unTriggerOnClickAway: false, eventName: '', eventType: 0 };
    const { blockEvent, isOpen, dialogOpenHandler, submitHandler } = props;

    const [currentBlockEvent, setCurrentBlockEvent] = useState<BlockEvent>(defaultEvent);

    useDeepEffect(() => {
        if (blockEvent === undefined) {
            setCurrentBlockEvent(defaultEvent)
        } else {
            setCurrentBlockEvent(blockEvent)
        }
    }, [blockEvent]);

    const handleOnClose = () => {
        setCurrentBlockEvent(defaultEvent);
        dialogOpenHandler();
    }

    const handleOnSubmit = () => {
        submitHandler(currentBlockEvent);
        handleOnClose();
    }

    return (
        <StyledDialog
            open={isOpen}
            onClose={handleOnClose}
            fullWidth
            maxWidth="md"
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{"Event"}</DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <TextField label="Name" placeholder="Event Name" type="text" value={currentBlockEvent.eventName} onChange={(e) => setCurrentBlockEvent({ ...currentBlockEvent, eventName: e.target.value })} />
                <FormControl className={classes.formControl}>
                    <InputLabel id="event-type">Event type</InputLabel>
                    <Select
                        labelId="event-type"
                        value={currentBlockEvent.eventType}
                        onChange={(e) => setCurrentBlockEvent({ ...currentBlockEvent, eventType: e.target.value as EventType })}
                    >
                        <MenuItem value={EventType.Single}>Single</MenuItem>
                        <MenuItem value={EventType.Multiple}>Multiple</MenuItem>
                    </Select>
                </FormControl>
                <FormControlLabel
                    value="end"
                    control={<Checkbox color="primary" checked={currentBlockEvent.propagateSource} onChange={(e, checked) => setCurrentBlockEvent({ ...currentBlockEvent, propagateSource: checked })} />}
                    label="Propagate source"
                    labelPlacement="end"
                />
                <FormControlLabel
                    value="end"
                    control={<Checkbox color="primary" checked={currentBlockEvent.unTriggerOnClickAway} onChange={(e, checked) => setCurrentBlockEvent({ ...currentBlockEvent, unTriggerOnClickAway: checked })} />}
                    label="Untrigger on clickaway"
                    labelPlacement="end"
                />
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={handleOnClose}>
                    Cancel
                </Button>
                <Button color="primary" onClick={handleOnSubmit}>
                    Save
                </Button>
            </DialogActions>
        </StyledDialog>
    );
}

export default EventDialog;