import * as React from 'react';
import { Route, Routes } from 'react-router';
import Login from './components/Login';
import Documents from './components/DocumentData/MediaDataContainer';
import { connect, useDispatch, useSelector } from 'react-redux';
import BlockBuilder from './components/BlockBuilder/BlockBuilder';
import 'animate.css/animate.css';

import './custom.scss';
import 'react-before-after-slider-component/dist/build.css';
import TemplateList from './components/newUi/TemplateList';
import PageList from './components/newUi/PageList';
import NavMenu from './components/NavMenu';
import { Backdrop, CircularProgress } from '@mui/material';
import DynModelList from './components/newUi/DynModelList';
import DynDataSetList from './components/newUi/DynDataSetList';
import BlockEventContext from './components/Contexts/BlockEventContext';
import Configuration from './components/newUi/Configuration';
import TranslationsTable from './components/newUi/TranslationsTable';
import { actionCreators } from './store/Configuration';
import { actionCreators as authActions } from './store/Auth';
import { actionCreators as translateActions } from './store/Language';
import { actionCreators as blockActions } from './store/Block';
import { selectIsAuthenticated } from './store/selectors/AuthSelector';
import { selectIsLoading } from './store/selectors/BackdropSelector';
import BlockTranslationContext from './components/Contexts/TranslationContext';

const App = (props: any) => {

  const dispatch = useDispatch();
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const isLoading = useSelector(selectIsLoading);

  React.useEffect(() => {
    dispatch(authActions.checkAuth());
    dispatch(actionCreators.getConfig());
    dispatch(translateActions.getTranslationTags());
    dispatch(blockActions.getGlobalStyles());
  }, []);

  let routes = (
    <Routes>
      <Route path='/' element={<Login />} />
    </Routes>
  );

  if (isAuthenticated) {
    routes = (
      <Routes>
        <Route path='/' element={<PageList />} />
        <Route path='/templates' element={<TemplateList />} />
        <Route path='/builder' element={<BlockBuilder />} />
        <Route path='/documents' element={<Documents />} />
        <Route path='/blockbuilder' element={<BlockBuilder />} />
        <Route path='/models' element={<DynModelList />} />
        <Route path='/datasets' element={<DynDataSetList />} />
        <Route path='/translations' element={<TranslationsTable />} />
        <Route path='/configuration' element={<Configuration />} />
      </Routes>
    );
  }
  return (
    <BlockTranslationContext>
      <BlockEventContext>
        {isAuthenticated ? <NavMenu /> : null}
        {routes}
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </BlockEventContext>
    </BlockTranslationContext>
  );
};

export default App;

