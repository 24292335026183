import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';
import Axios from '../services/api';
import { updateObject } from '../Utility';


export interface AuthState {
    token: string;
    userId: string | null;
    error: string;
    loading: boolean;
    authRedirectPath: string;
};

export interface AuthStartAction { type: 'AUTH_START' }
export interface AuthSuccessAction {
    type: 'AUTH_SUCCESS',
    idToken: string,
    userId: string
}
export interface AuthFailAction {
    type: 'AUTH_FAIL',
    error: string
}
export interface AuthLogoutAction { type: 'AUTH_LOGOUT' }
export interface AuthSetRedirectPathAction {
    type: 'SET_AUTH_REDIRECT_PATH',
    path: string
}

export type KnownAction = AuthStartAction | AuthSuccessAction | AuthFailAction | AuthLogoutAction | AuthSetRedirectPathAction;

export const actionCreators = {
    authenticate: (userName: string, password: string): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'AUTH_START' });
        const authData = {
            username: userName,
            password: password
        };
        let url = '/login/authenticate';
 
        Axios.post(url, authData)
            .then(response => {
                localStorage.setItem('token', response.data.jwtToken);
                localStorage.setItem('userId', response.data.username);
                dispatch({ type: 'AUTH_SUCCESS', idToken: response.data.jwtToken, userId: response.data.username });
            })
            .catch(error => {
                dispatch({ type: 'AUTH_FAIL', error: error.response.data.error });
            })
    },
    checkAuth: (): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'AUTH_START' });

        const token = localStorage.getItem('token');
        const userId = localStorage.getItem('userId');

        if(token && userId)
            dispatch({type: 'AUTH_SUCCESS', idToken: token, userId: userId});
        else            
            dispatch({type: 'AUTH_LOGOUT'});
    },
    reAuthSuccess: (token: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const state = getState();

        dispatch({type: 'AUTH_SUCCESS', idToken: token!, userId: state.auth?.userId!}); 
    },
    logout: (): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({type: 'AUTH_LOGOUT'}); 
    },

};

const unloadedState: AuthState = {
    token: "",
    userId: "",
    error: "",
    loading: false,
    authRedirectPath: "/"
};

const authStart = (state: AuthState | undefined, action: KnownAction) => {
    return updateObject(state, { error: null, loading: true });
}

const authSuccess = (state: AuthState | undefined, action: AuthSuccessAction) => {
    return updateObject(state, {
        token: action.idToken,
        userId: action.userId,
        error: null,
        loading: false
    });
}

const authFail = (state: AuthState | undefined, action: AuthFailAction) => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
}

const authLogout = (state: AuthState | undefined, action: Action) => {
    return updateObject(state, { token: null, userId: null });
}

const setAuthRedirectPath = (state: AuthState, action: AuthSetRedirectPathAction) => {
    return updateObject(state, { authRedirectPath: action.path });
}


export const reducer: Reducer<AuthState | undefined> = (state: AuthState | undefined, incomingAction: Action): AuthState | undefined => {
    if (state === undefined) {
        return unloadedState;
    }
    switch (incomingAction.type) {
        case 'AUTH_START':
            return authStart(state, incomingAction);
        case 'AUTH_SUCCESS':
            return authSuccess(state, incomingAction as AuthSuccessAction);
        case 'AUTH_FAIL':
            return authFail(state, incomingAction as AuthFailAction);
        case 'AUTH_LOGOUT':            
            localStorage.removeItem('token');
            localStorage.removeItem('userId');
            return authLogout(state, incomingAction);
        case 'SET_AUTH_REDIRECT_PATH':
            return setAuthRedirectPath(state, incomingAction as AuthSetRedirectPathAction);
        default:
            return state;
    }
}