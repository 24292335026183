import { AppBar, Button, Card, CardActions, CardContent, CardHeader, Dialog, Divider, Fab, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Toolbar, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { actionCreators, PageData, PageState } from '../../store/Page';
import { actionCreators as blockActions } from '../../store/Block';
import PageModal from '../BlockBuilder/modals/PageModal';
import DeleteSharpIcon from '@mui/icons-material/DeleteSharp';
import EditIcon from '@mui/icons-material/Edit';
import AutoAwesomeMosaicIcon from '@mui/icons-material/AutoAwesomeMosaic';
import AddBoxSharpIcon from '@mui/icons-material/AddBoxSharp';
import { useNavigate } from 'react-router-dom';
import { Head } from '../Icons/Head';
import { Footer } from '../Icons/Footer';
import GetAppSharpIcon from '@mui/icons-material/GetAppSharp';
import ConfirmationDialog from '../UI/ConfirmationDialog';
import { ApplicationState } from '../../store';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { selectPages } from '../../store/selectors/PageSelector';
import { actionCreators as dynActions }  from '../../store/DynData';

const PREFIX = 'PageList';

const classes = {
    root: `${PREFIX}-root`,
    gridroot: `${PREFIX}-gridroot`,
    expandOpen: `${PREFIX}-expandOpen`,
    button: `${PREFIX}-button`,
    extendedIcon: `${PREFIX}-extendedIcon`,
    buttonCell: `${PREFIX}-buttonCell`,
    tableContainer: `${PREFIX}-tableContainer`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
    {
        theme
    }
) => ({
    [`& .${classes.root}`]: {
        marginLeft: '239px',
    },
    [`& .${classes.gridroot}`]: {
        padding: theme.spacing(1),
    },
    [`& .${classes.expandOpen}`]: {
        transform: 'rotate(180deg)',
    },
    [`& .${classes.button}`]: {
        marginLeft: theme.spacing(1),
    },
    [`& .${classes.extendedIcon}`]: {
        marginRight: theme.spacing(1),
    },
    [`& .${classes.buttonCell}`]: {
        width: '150px',
    },
    [`& .${classes.tableContainer}`]: {
        maxHeight: 'calc(100vh - 80px)',
        '& th': {
            fontWeight: 'bold',
        }
    }
}));

const PageList = () => {

    const [currentPage, setCurrentPage] = useState<PageData | undefined>(undefined);
    const [pageModalOpen, setPageModalOpen] = useState(false);
    const [confirmOpen, setConfirmOpen] = useState(false);

    const dispatch = useDispatch();
    const pageData = useSelector(selectPages);

    let history = useNavigate();

    const handleConfirmOpen = (page: PageData) => {
        setCurrentPage(page);
        toggleConfirmOpen();
    }

    const toggleConfirmOpen = () => {
        setConfirmOpen(prevState => {
            if (prevState) {
                setCurrentPage(undefined);
            }

            return !prevState
        });
    }

    const togglePageModal = () => setPageModalOpen(prevState => {
        if (prevState) {
            setCurrentPage(undefined);
        }

        return !prevState
    });

    const toggleEditPageModal = (selectedPage: PageData) => {
        setCurrentPage(selectedPage);
        togglePageModal();
    };

    const submitPageModal = (newPage: PageData) => {
        togglePageModal();
        if (newPage.id > 0) {
            dispatch(actionCreators.updatePage(newPage));
        } else {
            dispatch(actionCreators.createPage(newPage));
        }
    }

    const handlePageBuilder = (page: PageData) => {
        dispatch(actionCreators.selectPage(page));
        history("/builder");
    }

    const handlePageDelete = () => {
        if(currentPage)
            dispatch(actionCreators.deletePage(currentPage));
    }

    const editHeader = () => {
        dispatch(actionCreators.selectHeader(true));
        history("/builder");
    }

    const editFooter = () => {
        dispatch(actionCreators.selectFooter(true));
        history("/builder");
    }

    const deployData = () => {
        dispatch(blockActions.deployData());
    }

    useEffect(() => {
        dispatch(actionCreators.getPages());
        dispatch(actionCreators.getHeader());
        dispatch(actionCreators.getFooter());
        dispatch(dynActions.getDataSets());
        dispatch(dynActions.getModels());
    }, [])


    const pages = pageData.sort((n1: PageData, n2: PageData) => {
        if (n1.name > n2.name)
            return 1;

        if (n1.name < n2.name)
            return -1;
        return 0;
    }).map((page: PageData) => (
        <TableRow key={page.id}>
            <TableCell>{page.name}</TableCell>
            <TableCell>{page.metaTitle}</TableCell>
            <TableCell>{page.metaDescription}</TableCell>
            <TableCell align='center'>{page.withNavbar ? <CheckIcon /> : <CloseIcon />}</TableCell>
            <TableCell align='center'>{page.withFooter ? <CheckIcon /> : <CloseIcon />}</TableCell>
            <TableCell className={classes.buttonCell}>
                <IconButton onClick={() => toggleEditPageModal(page)} size="small">
                    <EditIcon />
                </IconButton>
                <IconButton onClick={() => handlePageBuilder(page)} size="small">
                    <AutoAwesomeMosaicIcon />
                </IconButton>
                <IconButton onClick={() => handleConfirmOpen(page)} size="small">
                    <DeleteSharpIcon />
                </IconButton>
            </TableCell>
        </TableRow>
    ));

    return (
        (<Root>
            <div className={classes.root}>
                <AppBar position="static" color="primary">
                    <Toolbar disableGutters>
                        <Button className={classes.button} variant="contained" color="secondary" onClick={togglePageModal} startIcon={<AddBoxSharpIcon />}>
                            Add Page
                        </Button>
                        <Button className={classes.button} variant="contained" color="secondary" onClick={editHeader} startIcon={<Head />}>
                            Edit Navbar
                        </Button>
                        <Button className={classes.button} variant="contained" color="secondary" onClick={editFooter} startIcon={<Footer />}>
                            Edit Footer
                        </Button>
                        <Button className={classes.button} variant="contained" color="secondary" onClick={deployData} startIcon={<GetAppSharpIcon />}>
                            Deploy
                        </Button>
                    </Toolbar>
                </AppBar>
                <div className={classes.gridroot}>
                    <TableContainer className={classes.tableContainer} component={Paper}>
                        <Table stickyHeader sx={{ minWidth: 700 }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Metatitle</TableCell>
                                    <TableCell>Metadescription</TableCell>
                                    <TableCell>Navbar</TableCell>
                                    <TableCell>Footer</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {pages}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Dialog fullWidth maxWidth="md" open={pageModalOpen} onClose={togglePageModal} >
                        <PageModal pageData={currentPage} togglePageModal={togglePageModal} submitPageModal={submitPageModal} />
                    </Dialog>
                </div>
            </div>
            <ConfirmationDialog isOpen={confirmOpen} clickHandler={toggleConfirmOpen} submitFunction={handlePageDelete} />
        </Root>)
    );
}

export default PageList;