import React, { useState, useContext } from 'react';
import { BlockData, BlockStyle, BlockEvent, BlockEventTrigger, EventType, EventTriggerType, actionCreators } from '../../store/Block';
import { Box, Collapse, Divider, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, Paper, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { getReferencedBlockStyles } from '../../Utility';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import BlockPropsDialog from './BlockEditDialogs/BlockPropsDialog';
import EventTriggerDialog from './BlockEditDialogs/EventTriggerDialog';
import EditSharpIcon from '@mui/icons-material/EditSharp';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import BlockStyleDialog from './BlockEditDialogs/BlockStyleDialog';
import EventDialog from './BlockEditDialogs/EventDialog';
import { PageData } from '../../store/Page';
import { DynPropAssignment } from '../../store/DynData';
import { useDispatch } from 'react-redux';
import PropAssignmentDialog from './BlockEditDialogs/PropAssignmentDialog';
import { EventContext } from '../Contexts/BlockEventContext';

interface Props {
    selectedBlock: BlockData;
    selectedPage: PageData | null;
    updateBlockHandler: (block: BlockData) => void;
    addEventHandler: (blockEvent: BlockEvent) => void;
    deleteEventHandler: (eventId: number) => void;
    updateEventHandler: (blockEvent: BlockEvent) => void;
    addEventTriggerHandler: (blockEventTrigger: BlockEventTrigger) => void;
    deleteEventTriggerHandler: (eventTriggerId: number) => void;
    updateEventTriggerHandler: (updatedEventTrigger: BlockEventTrigger) => void;
    addBlockStyleHandler: (newBlockStyle: BlockStyle) => void;
    deleteBlockStyleHandler: (styleId: number) => void;
    updateBlockStyleHandler: (updatedBlockStyle: BlockStyle) => void;
    dynamic: boolean;
}

const PREFIX = 'BlockEdit';

const classes = {
    editRoot: `${PREFIX}-editRoot`,
    listroot: `${PREFIX}-listroot`,
    tabwidth: `${PREFIX}-tabwidth`,
    propsInputs: `${PREFIX}-propsInputs`,
    input: `${PREFIX}-input`,
    tabContent: `${PREFIX}-tabContent`,
    formControl: `${PREFIX}-formControl`,
    selectEmpty: `${PREFIX}-selectEmpty`,
    button: `${PREFIX}-button`,
    contentInput: `${PREFIX}-contentInput`,
    mediaCardImageBox: `${PREFIX}-mediaCardImageBox`,
    mediaCardImage: `${PREFIX}-mediaCardImage`,
    eventPaper: `${PREFIX}-eventPaper`,
    buttonCol: `${PREFIX}-buttonCol`,
    styleRow: `${PREFIX}-styleRow`,
    section: `${PREFIX}-section`,
};

const Root = styled('div')((
    {
        theme
    }
) => ({
    [`&.${classes.editRoot}`]: {
        flex: '1',
        '& th': {
            fontWeight: 'bold',
        },
        maxHeight: 'calc(100vh - 65px)',
        overflowY: 'scroll',
    },
    [`& .${classes.listroot}`]: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    [`& .${classes.tabwidth}`]: {
        minWidth: 0
    },
    [`& .${classes.propsInputs}`]: {
        '& > *': {
            margin: theme.spacing(1, 0),
        },
    },
    [`& .${classes.input}`]: {
        fontSize: 'small',
    },
    [`& .${classes.tabContent}`]: {
        height: 'calc(100vh - 65px)',
        maxHeight: 'calc(100vh - 65px)',
        overflowY: 'scroll'
    },
    [`& .${classes.formControl}`]: {
        width: '100%',
    },
    [`& .${classes.selectEmpty}`]: {
        marginTop: theme.spacing(2),
    },
    [`& .${classes.button}`]: {
        margin: theme.spacing(1)
    },
    [`& .${classes.contentInput}`]: {
        width: '100%',
        height: '200px',
    },
    [`& .${classes.mediaCardImageBox}`]: {
        height: '264px',
        width: '100%',
    },
    [`& .${classes.mediaCardImage}`]: {
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundColor: theme.palette.primary.main,
        margin: 'auto',
        height: '264px',
    },
    [`& .${classes.eventPaper}`]: {
        margin: theme.spacing(1, 0)
    },
    [`& .${classes.buttonCol}`]: {
        width: '5%'
    },
    [`& .${classes.styleRow}`]: {
        marginBottom: theme.spacing(1)
    },
    [`& .${classes.section}`]: {
        '& span': {
            fontWeight: 'bold',
        },
        textTransform: 'uppercase'
    },
}));

const BlockEditNew = (props: Props) => {
    const {
        selectedBlock,
        updateBlockHandler,
        addBlockStyleHandler,
        updateBlockStyleHandler,
        deleteBlockStyleHandler,
        addEventHandler,
        updateEventHandler,
        deleteEventHandler,
        addEventTriggerHandler,
        updateEventTriggerHandler,
        deleteEventTriggerHandler,
        dynamic,
        selectedPage,
    } = props;

    const eventContext = useContext(EventContext);

    const [collapses, setCollapses] = useState<string[]>(["props", "events", "eventtriggers", "style"]);
    const [BlockPropsDialogOpen, setBlockPropsDialogOpen] = useState(false);
    const [EventDialogOpen, setEventDialogOpen] = useState(false);
    const [EventTriggerDialogOpen, setEventTriggerDialogOpen] = useState(false);
    const [BlockStyleDialogOpen, setBlockStyleDialogOpen] = useState(false);
    const [PropAssignmentDialogOpen, setPropAssignmentDialogOpen] = useState(false);

    const [SelectedEvent, setSelectedEvent] = useState<BlockEvent | undefined>();
    const [SelectedEventTrigger, setSelectedEventTrigger] = useState<BlockEventTrigger | undefined>();
    const [SelectedBlockStyle, setSelectedBlockStyle] = useState<BlockStyle | undefined>();

    const dispatch = useDispatch();

    const blockStyles = selectedBlock ?
        selectedBlock.templateReference != null ?
            [...getReferencedBlockStyles(selectedBlock.templateReference), ...selectedBlock.blockStyles]
            : selectedBlock.blockStyles
        : [];

    const handleCollapses = (collapseTrigger: string) => {
        if (collapses.findIndex(x => x === collapseTrigger) > -1) {
            setCollapses([...collapses.filter(x => x !== collapseTrigger)]);
        } else {
            const newCollapses = [...collapses];
            newCollapses.push(collapseTrigger);
            setCollapses(newCollapses);
        }
    }

    const submitBlockStyleDialogHandler = (blockStyle: BlockStyle) => {
        if (blockStyle.id === 0) {
            addBlockStyleHandler(blockStyle);
        }
        else {
            updateBlockStyleHandler(blockStyle);
        }
    }

    const closeBlockStyleDialogHandler = () => {
        setSelectedBlockStyle(undefined);
        setBlockStyleDialogOpen(prev => !prev);
    }

    const openBlockStyleDialogHandler = (id?: number) => {
        if (id) {
            const blockStyle = selectedBlock.blockStyles.find(x => x.id === id);
            if (blockStyle !== undefined) {
                setSelectedBlockStyle(blockStyle);
            }
        }

        setBlockStyleDialogOpen(prev => !prev);
    }

    const submitEventDialogHandler = (blockEvent: BlockEvent) => {
        if (blockEvent.id === 0) {
            addEventHandler(blockEvent);
        }
        else {
            updateEventHandler(blockEvent);
        }
    }

    const closeEventDialogHandler = () => {
        setSelectedEvent(undefined);
        setEventDialogOpen(prev => !prev);
    }

    const submitPropAssignmentDialogHandler = (propAssignments: DynPropAssignment[]) => {
        dispatch(actionCreators.updateBlockPropAssignments(propAssignments));
    }

    const openEventDialogHandler = (id?: number) => {
        if (id) {
            const event = selectedBlock.blockEvents.find(x => x.id === id);
            if (event !== undefined) {
                setSelectedEvent(event);
            }
        }

        setEventDialogOpen(prev => !prev);
    }

    const submitEventTriggerDialogHandler = (eventTrigger: BlockEventTrigger) => {
        if (eventTrigger.id === 0) {
            addEventTriggerHandler(eventTrigger);
        }
        else {
            updateEventTriggerHandler(eventTrigger);
        }
    }

    const closeEventTriggerDialogHandler = () => {
        setSelectedEventTrigger(undefined);
        setEventTriggerDialogOpen(prev => !prev);
    }


    const openEventTriggerDialogHandler = (id?: number) => {
        if (id) {
            const eventTrigger = selectedBlock.blockEventTriggers.find(x => x.id === id);
            if (eventTrigger !== undefined) {
                setSelectedEventTrigger(eventTrigger);
            }
        }

        setEventTriggerDialogOpen(prev => !prev);
    }

    const sortStyles = (n1: BlockStyle, n2: BlockStyle) => {
        if (n1.selector > n2.selector)
            return 1;

        if (n1.selector < n2.selector)
            return -1;

        return 0;
    }

    return (
        <Root className={classes.editRoot}>
            <form noValidate autoComplete="off">
                <List>
                    <ListItem className={classes.section} button onClick={() => handleCollapses("props")}>
                        <ListItemText primary={`Props`} />
                        <ListItemSecondaryAction>
                            <IconButton
                                edge="end"
                                onClick={() => setBlockPropsDialogOpen(prev => !prev)}
                                size="large">
                                <EditSharpIcon />
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                    <Divider />
                    <Collapse in={collapses.indexOf("props") > -1}>
                        <Box margin={1}>
                            <TableContainer elevation={3} component={Paper}>
                                <Table size="small" aria-label="a dense table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Name</TableCell>
                                            <TableCell>Value</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>AnimationName</TableCell>
                                            <TableCell>{selectedBlock.animationName}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>AnimateOnLoad</TableCell>
                                            <TableCell>{selectedBlock.animateOnLoad ? <CheckIcon /> : <CloseIcon />}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>onScroll</TableCell>
                                            <TableCell>{selectedBlock.onScroll ? <CheckIcon /> : <CloseIcon />}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>scrollYPosition</TableCell>
                                            <TableCell>{selectedBlock.scrollYPosition ? <CheckIcon /> : <CloseIcon />}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                        <Divider />
                    </Collapse>
                    {
                        selectedBlock.type === 'text'
                            || selectedBlock.type === 'header'
                            || selectedBlock.type === 'link'
                            || selectedBlock.type === 'media' ? (
                            <>
                                <ListItem className={classes.section} button onClick={() => handleCollapses("propassignment")}>
                                    <ListItemText primary={`prop assignment`} />
                                    <ListItemSecondaryAction>
                                        <IconButton
                                            edge="end"
                                            size="large"
                                            onClick={() => setPropAssignmentDialogOpen(prev => !prev)}>
                                            <EditSharpIcon />
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                                <Divider />
                            </>
                        ) : null
                    }
                    <ListItem className={classes.section} button onClick={() => handleCollapses("style")}>
                        <ListItemText primary={`style`} />
                        <ListItemSecondaryAction>
                            <IconButton
                                edge="end"
                                onClick={() => openBlockStyleDialogHandler()}
                                size="large">
                                <AddCircleIcon />
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                    <Divider />
                    <Collapse in={collapses.indexOf("style") > -1}>
                        {
                            blockStyles?.length ?
                                <>
                                    <Box margin={1}>
                                        <List style={{ width: '100%' }} dense>
                                            {
                                                blockStyles
                                                    .sort(sortStyles)
                                                    .map((style) => (
                                                        <Paper elevation={3} key={style.id}>
                                                            <Divider />
                                                            <ListItem>
                                                                <ListItemText primary={style.selector + "-" + style.id.toString()} secondary={style.screenMode} />
                                                                <ListItemSecondaryAction>
                                                                    <IconButton edge="end" disabled={selectedBlock.templateReference?.blockStyles.includes(style)} size="small" onClick={() => openBlockStyleDialogHandler(style.id)}>
                                                                        <EditSharpIcon />
                                                                    </IconButton>
                                                                    <IconButton
                                                                        edge="end"
                                                                        disabled={selectedBlock.templateReference?.blockStyles.includes(style)}
                                                                        onClick={() => deleteBlockStyleHandler(style.id)}
                                                                        size="large">
                                                                        <RemoveCircleIcon />
                                                                    </IconButton>
                                                                </ListItemSecondaryAction>
                                                            </ListItem>
                                                            {
                                                                style.blockStyleData.length ?
                                                                    <TableContainer className={classes.styleRow} elevation={3} component={Paper}>
                                                                        <Table size="small" aria-label="a dense table">
                                                                            <TableHead>
                                                                                <TableRow>
                                                                                    <TableCell>Attribute</TableCell>
                                                                                    <TableCell>Value</TableCell>
                                                                                </TableRow>
                                                                            </TableHead>
                                                                            <TableBody>
                                                                                {style.blockStyleData.map((row) => (
                                                                                    <TableRow key={row.id}>
                                                                                        <TableCell>
                                                                                            {row.attributeKey}
                                                                                        </TableCell>
                                                                                        <TableCell>
                                                                                            {row.attributeValue}
                                                                                        </TableCell>
                                                                                    </TableRow>
                                                                                ))}
                                                                            </TableBody>
                                                                        </Table>
                                                                    </TableContainer>
                                                                    : null
                                                            }
                                                        </Paper>
                                                    ))
                                            }
                                        </List>
                                    </Box>
                                    <Divider />
                                </>
                                : null
                        }
                    </Collapse>
                    <ListItem className={classes.section} button onClick={() => handleCollapses("events")}>
                        <ListItemText primary={`events`} />
                        <ListItemSecondaryAction>
                            <IconButton edge="end" onClick={() => openEventDialogHandler()} size="large">
                                <AddCircleIcon />
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                    <Divider />
                    <Collapse in={collapses.indexOf("events") > -1}>
                        {
                            selectedBlock.blockEvents?.length ?
                                <>
                                    <Box margin={1}>
                                        <List style={{ width: '100%' }} dense>
                                            {
                                                selectedBlock.blockEvents.map((blockEvent) => (
                                                    <React.Fragment key={blockEvent.id}>
                                                        <ListItem>
                                                            <ListItemText primary={blockEvent.eventName} secondary={EventType[blockEvent.eventType]} />
                                                            <ListItemSecondaryAction>
                                                                <IconButton
                                                                    edge="end"
                                                                    onClick={() => openEventDialogHandler(blockEvent.id)}
                                                                    size="large">
                                                                    <EditSharpIcon />
                                                                </IconButton>
                                                                <IconButton edge="end" onClick={() => deleteEventHandler(blockEvent.id)} size="large">
                                                                    <RemoveCircleIcon />
                                                                </IconButton>
                                                            </ListItemSecondaryAction>
                                                        </ListItem>
                                                        <Divider />
                                                    </React.Fragment>
                                                ))
                                            }
                                        </List>
                                    </Box>
                                    <Divider />
                                </>
                                : null
                        }
                    </Collapse>
                    <ListItem className={classes.section} button onClick={() => handleCollapses("eventtriggers")}>
                        <ListItemText primary={`eventtriggers`} />
                        <ListItemSecondaryAction>
                            <IconButton edge="end" onClick={() => openEventTriggerDialogHandler()} size="large">
                                <AddCircleIcon />
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                    <Divider />
                    <Collapse in={collapses.indexOf("eventtriggers") > -1}>
                        {
                            selectedBlock.blockEventTriggers?.length ?
                                <>
                                    <Box margin={1}>
                                        <List style={{ width: '100%' }} dense>
                                            {
                                                selectedBlock.blockEventTriggers.map((blockEventTrigger) => (
                                                    <React.Fragment key={blockEventTrigger.id}>
                                                        <ListItem>
                                                            <ListItemText primary={blockEventTrigger.blockEvent?.eventName} secondary={EventTriggerType[blockEventTrigger.eventTriggerType]} />
                                                            <ListItemSecondaryAction>
                                                                <IconButton
                                                                    edge="end"
                                                                    onClick={() => openEventTriggerDialogHandler(blockEventTrigger.id)}
                                                                    size="large">
                                                                    <EditSharpIcon />
                                                                </IconButton>
                                                                <IconButton
                                                                    edge="end"
                                                                    onClick={() => deleteEventTriggerHandler(blockEventTrigger.id)}
                                                                    size="large">
                                                                    <RemoveCircleIcon />
                                                                </IconButton>
                                                            </ListItemSecondaryAction>
                                                        </ListItem>
                                                        <Divider />
                                                    </React.Fragment>
                                                ))
                                            }
                                        </List>
                                    </Box>
                                    <Divider />
                                </>
                                : null
                        }
                    </Collapse>
                </List>
            </form>
            <BlockPropsDialog
                block={selectedBlock}
                updateBlockHandler={updateBlockHandler}
                isOpen={BlockPropsDialogOpen}
                dynamic={dynamic}
                dialogOpenHandler={() => setBlockPropsDialogOpen(prev => !prev)}
            />
            <EventDialog blockEvent={SelectedEvent} dialogOpenHandler={closeEventDialogHandler} isOpen={EventDialogOpen} submitHandler={submitEventDialogHandler} />
            <EventTriggerDialog blockEventTrigger={SelectedEventTrigger} dialogOpenHandler={closeEventTriggerDialogHandler} isOpen={EventTriggerDialogOpen} submitHandler={submitEventTriggerDialogHandler} />
            <BlockStyleDialog blockStyle={SelectedBlockStyle} dialogOpenHandler={closeBlockStyleDialogHandler} isOpen={BlockStyleDialogOpen} submitHandler={submitBlockStyleDialogHandler} />
            <PropAssignmentDialog dialogOpenHandler={() => setPropAssignmentDialogOpen(prev => !prev)} dynProps={selectedPage?.dynDataSet?.dynModel.dynPropSets ?? []} isOpen={PropAssignmentDialogOpen} propAssignments={selectedBlock.dynPropAssignments} selectedBlockType={selectedBlock.type} submitHandler={submitPropAssignmentDialogHandler} />

        </Root>
    );
}

export default BlockEditNew;