import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button } from '@mui/material';
import React from 'react'
import { useSelector } from 'react-redux';
import { Language, Translation, TranslationTag } from '../../store/Language';
import { selectLanguages } from '../../store/selectors/ConfigSelector';

interface Props {
    open: boolean,
    handleClose: () => void,
    handleSubmit: (translationTag: TranslationTag) => void,
    translationTag: TranslationTag | undefined,
    languages: Language[],
}

const TranslationsDialog = (props: Props) => {
    const { open, handleClose, translationTag, handleSubmit, languages } = props;
    const defaultTranslationTag: TranslationTag = { id: 0, name: '', translations: languages.map((lang): Translation => ({ id: 0, language: lang, text: '' })) }
    const [currentTranslationTag, SetCurrentTranslationTag] = React.useState(defaultTranslationTag);

    React.useEffect(() => {
        if (translationTag)
            SetCurrentTranslationTag(translationTag);
        else
            SetCurrentTranslationTag(defaultTranslationTag);
    }, [translationTag])

    const handleOnSubmit = () => {
        handleSubmit(currentTranslationTag);
        handleOnClose();
    }

    const handleOnClose = () => {
        SetCurrentTranslationTag(defaultTranslationTag);
        handleClose();
    }

    const handleTranslationChanged = (languageId: number, value: string) => {
        let newTranslationTag = { ...currentTranslationTag };
        let translation = currentTranslationTag.translations.find(x => x.language.id === languageId);

        if (translation) {
            translation.text = value;
            newTranslationTag.translations = [...currentTranslationTag.translations.filter(x => x.language.id !== languageId), translation];
            SetCurrentTranslationTag(newTranslationTag);
        }
    }
    const inputs = languages
        .sort((a: Language, b: Language) => a.name > b.name ? -1 : 1)
        .map((language) => {
            const value = currentTranslationTag.translations.find(x => x.language.name === language.name)?.text;
            return (
                <TextField key={language.id} label={language.name} value={value} onChange={(e) => handleTranslationChanged(language.id, e.target.value)} />
            )
        });

    return (
        <Dialog
            open={open}
            onClose={handleClose}
        >
            <DialogTitle>
                Translation
            </DialogTitle>
            <DialogContent sx={{
                '& > :not(style)': { m: 1, width: '25ch' },
            }}>
                <TextField label="Name" placeholder="home_h1" value={currentTranslationTag.name} onChange={(e) => SetCurrentTranslationTag({ ...currentTranslationTag, name: e.target.value })} />
                {inputs}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleOnClose}>cancel</Button>
                <Button onClick={handleOnSubmit} autoFocus>
                Submit
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default TranslationsDialog;