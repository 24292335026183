import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { BlockData, SliderBlockData } from '../../../store/Block';

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { MediaFile } from '../../../store/Media';

interface Props {
    blockData: SliderBlockData,
    classes: string,
    blockClickHandler: (e: React.MouseEvent<HTMLElement>) => void,
    items: BlockData[],
    previousBlock: BlockData | undefined,
    currentBlock: BlockData | undefined,
    selectedBlockId: number,
    disabled: boolean,
    canvasMode: string,
    children: ReactNode,
    size: number,
}

const SliderBlock = (props: Props) => {
    const { blockData, children, size } = props;
    const [responsive, setResponsive] = useState({
        amount: {
            breakpoint: { max: 4000, min: 1320 },
            items: 1
        }
    });

    let carousel = useRef<Carousel | null>(null);

    useEffect(() => {
        setResponsive({
            amount: {
                breakpoint: { max: 4000, min: 0 },
                items: size
            }
        });
    }, [size]);

    useEffect(() => {
        if (carousel && carousel.current) {

            if (carousel.current.state.currentSlide !== 0)
                carousel.current.goToSlide(0);

            carousel.current.setItemsToShow(true);
        }
    }, [responsive, blockData.infinite]);

    return (
        <>
            <Carousel
                ref={carousel}
                responsive={responsive}
                autoPlay={blockData.autoPlay}
                swipeable={blockData.swipeable}
                draggable={blockData.draggable}
                autoPlaySpeed={blockData.autoPlaySpeed}
                infinite={blockData.infinite ? true : undefined}
                arrows={blockData.arrows}
                showDots={blockData.showDots}
                renderDotsOutside={true}
                slidesToSlide={blockData.slidesToSlide}
                centerMode={blockData.centerMode}>
                {children !== null ? children : <div></div>}
            </Carousel>
        </>
    );
}

export default SliderBlock;