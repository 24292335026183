import { ApplicationState } from "..";
import { BlockData } from "../Block";

export const selectPages = (rootState: ApplicationState) => {
    return rootState.page!.pages ?? [];
}

export const selectSelectedPage = (rootState: ApplicationState) => {
    return rootState.page!.selectedPage;
}

export const selectHeaderSelected = (rootState: ApplicationState) => {
    return rootState.page!.headerSelected;
}

export const selectFooterSelected = (rootState: ApplicationState) => {
    return rootState.page!.footerSelected;
}

export const selectHeader = (rootState: ApplicationState): BlockData[] => {
    return rootState.page!.header;
}

export const selectFooter = (rootState: ApplicationState): BlockData[] => {
    return rootState.page!.footer;
}

export const selectPageDynProps = (rootState: ApplicationState) => {
    return rootState.page?.selectedPage?.dynDataSet?.dynModel?.dynPropSets ?? [];
}