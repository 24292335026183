import { Reducer } from 'redux';
import { AppThunkAction } from '.';
import Axios from '../services/api';
import { updateObject } from '../Utility';
import { MediaFile } from './Media';
import { FooterData, HeaderData, PageData } from './Page';
import SnackBarUtils from "../components/SnackBar/SnackBarOperations";
import { DynModel, DynPropAssignment, DynProps } from './DynData';
import { MailConnection, MailTemplate } from './Configuration';
import { AppsTwoTone, RouteRounded } from '@mui/icons-material';

export interface BlockState {
    blocks: BlockData[];
    globalStyles: BlockStyle[];
    selectedBlock: BlockData | undefined;
    blockClipboard: string;
    error: string;
    loading: boolean;
};

export enum EventType {
    Single,
    Multiple,
}

export enum EventTriggerType {
    OnClick,
    OnScroll,
    OnSubmit,
}

export enum InputType {
    text,
    textarea,
    checkbox,
    color,
    date,
    email,
    month,
    number,
    password,
    range,
    reset,
    search,
    tel,
    time,
    week,
}

export interface BlockEvent {
    id: number,
    eventName: string,
    eventType: EventType,
    propagateSource: boolean,
    unTriggerOnClickAway: boolean,
}

export interface BlockEventTrigger {
    id: number,
    eventTriggerType: EventTriggerType,
    blockEvent?: BlockEvent,
    scrollHeight: number,
    zeroBased: boolean,
    initiallyTriggered: boolean,
}

export interface BlockData {
    id: number,
    locationPath: string,
    sortPath: number,
    type: string,
    animationName: string,
    animateOnLoad: boolean,
    templateReference: BlockData | null,
    blockStyles: BlockStyle[],
    onScroll: boolean,
    scrollYPosition: number,
    dynPropAssignments: DynPropAssignment[],
    blockEvents: BlockEvent[],
    blockEventTriggers: BlockEventTrigger[],
    overwriteWindowScroll: boolean,
}

export interface InputBlockData extends BlockData {
    inputType: InputType,
    dynProp: DynProps,
    placeHolder: string,
    required: boolean,
}

export interface FormBlockData extends BlockData {
    name: string,
    dynModel: DynModel,
    mailConnection: MailConnection,
    mailTemplate: MailTemplate,
    recipient: string,
    subject: string,
}

export interface TextBlockData extends BlockData {
    content: string,
}

export interface HeaderBlockData extends BlockData {
    number: number,
    content: string,
}

export interface LinkBlockData extends BlockData {
    content: string,
    link: string,
    anchor: string,
    blank: boolean
}

export interface CustomBlockData extends BlockData {
    name: string,
    textParam: string,
}

export interface MediaBlockData extends BlockData {
    mediaFile: MediaFile | null,
    layout: string,
    preload: boolean,
    altText: string
}

export interface ContainerBlockData extends BlockData {
    fluid: boolean,
}

export interface RowBlockData extends BlockData {
    xs: number,
    sm: number,
    md: number,
    lg: number,
    xl: number,
    noGutters: boolean,
}

export interface ColBlockData extends BlockData {
    xs: number,
    sm: number,
    md: number,
    lg: number,
    xl: number,
}

export interface SliderBlockData extends BlockData {
    xs: number,
    sm: number,
    md: number,
    lg: number,
    xl: number,
    slidesToSlide: number,
    draggable: boolean,
    swipeable: boolean,
    arrows: boolean,
    infinite: boolean,
    autoPlay: boolean,
    autoPlaySpeed: number,
    showDots: boolean,
    renderDotsOutside: boolean,
    centerMode: boolean,
    useImageCollector: boolean
}

export interface SectionBlockData extends BlockData {
}

export interface IframeBlockData extends BlockData {
    title: string,
    link: string
}

export interface RatingBlockData extends BlockData {
    amount: number,
    rating: number,
}

export interface BeforeAfterBlockData extends BlockData {
    firstImgSource: MediaFile | null,
    secondImgSource: MediaFile | null,
}

export interface CountUpBlockData extends BlockData {
    countTo: number,
    duration: number,
    countOnLoad: boolean,
}

export interface BlockStyleData {
    id: number,
    attributeKey: string,
    attributeValue: string
}

export interface BlockStyle {
    id: number,
    screenMode: string,
    selector: string,
    blockStyleData: BlockStyleData[]
}

export interface BlockStartAction {
    type: 'BLOCK_START'
}

export interface BlockFailAction {
    type: 'BLOCK_FAIL',
    error: string
}

export interface BlockSuccessAction {
    type: 'BLOCK_SUCCESS',
    blocks: BlockData[]
}

export interface BlockCopyAction {
    type: 'BLOCK_COPY',
    locationPath: string
}

export interface BlockSelectAction {
    type: 'BLOCK_SELECT',
    block: BlockData | undefined
}

export interface BlockAddSuccessAction {
    type: 'BLOCK_ADD_SUCCESS',
    blocks: BlockData[]
}

export interface BlockUpdateSuccessAction {
    type: 'BLOCK_UPDATE_SUCCESS',
    blocks: BlockData[]
}

export interface BlockDeleteSuccessAction {
    type: 'BLOCK_DELETE_SUCCESS',
    block: BlockData,
}

export interface BlockAddTemplateSuccessAction {
    type: 'BLOCK_ADD_TEMPLATE_SUCCESS',
}

export interface BlockSortSuccessAction {
    type: 'BLOCK_SORT_SUCCESS',
    blocks: BlockData[]
}

export interface EventAddSuccessAction {
    type: 'EVENT_ADD_SUCCESS',
    event: BlockEvent
}

export interface EventDeleteSuccessAction {
    type: 'EVENT_DELETE_SUCCESS',
    eventId: number,
}

export interface EventUpdateSuccessAction {
    type: 'EVENT_UPDATE_SUCCESS',
    event: BlockEvent,
}

export interface EventTriggerAddSuccessAction {
    type: 'EVENTTRIGGER_ADD_SUCCESS',
    eventTrigger: BlockEventTrigger,
}

export interface EventTriggerDeleteSuccessAction {
    type: 'EVENTTRIGGER_DELETE_SUCCESS',
    eventTriggerId: number,
}

export interface EventTriggerUpdateSuccessAction {
    type: 'EVENTTRIGGER_UPDATE_SUCCESS',
    eventTrigger: BlockEventTrigger,
}

export interface BlockStyleAddSuccessAction {
    type: 'BLOCKSTYLE_ADD_SUCCESS',
    blockStyle: BlockStyle,
}

export interface BlockStyleDeleteSuccessAction {
    type: 'BLOCKSTYLE_DELETE_SUCCESS',
    blockStyleId: number,
}

export interface BlockStyleUpdateSuccessAction {
    type: 'BLOCKSTYLE_UPDATE_SUCCESS',
    blockStyle: BlockStyle,
}

export interface BlockDynPropAssignmentUpdateSuccessAction {
    type: 'BLOCK_DYN_PROP_ASSIGNMENT_UPDATE_SUCCESS',
    propAssignments: DynPropAssignment[],
}

export interface BlockGlobalStyleSuccessAction {
    type: 'BLOCK_GLOBAL_STYLE_SUCCESS',
    blockStyles: BlockStyle[],
}

export type KnownAction =
    | BlockFailAction
    | BlockSuccessAction
    | BlockStartAction
    | BlockAddSuccessAction
    | BlockSelectAction
    | BlockUpdateSuccessAction
    | BlockAddTemplateSuccessAction
    | BlockDeleteSuccessAction
    | BlockCopyAction
    | BlockSortSuccessAction
    | EventAddSuccessAction
    | EventDeleteSuccessAction
    | EventUpdateSuccessAction
    | EventTriggerAddSuccessAction
    | EventTriggerUpdateSuccessAction
    | EventTriggerDeleteSuccessAction
    | BlockStyleAddSuccessAction
    | BlockStyleUpdateSuccessAction
    | BlockStyleDeleteSuccessAction
    | BlockDynPropAssignmentUpdateSuccessAction
    | BlockGlobalStyleSuccessAction;

export const actionCreators = {
    getGlobalStyles: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'BLOCK_START' });
        const appState = getState();

        if (!appState.auth)
            return;

        const url = '/block/blockstyles';
        Axios.get(url)
            .then(response => {
                dispatch({ type: 'BLOCK_GLOBAL_STYLE_SUCCESS', blockStyles: response.data });
            })
            .catch(error => {
                dispatch({ type: 'BLOCK_FAIL', error: error.response.data.error });
            })
    },
    updateBlockPropAssignments: (assignments: DynPropAssignment[]): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'BLOCK_START' });
        const appState = getState();

        if (!appState.auth)
            return;

        const url = '/block/propassignment';
        Axios.post(url, { assignments, blockId: appState.block?.selectedBlock?.id })
            .then(response => {
                dispatch({ type: 'BLOCK_DYN_PROP_ASSIGNMENT_UPDATE_SUCCESS', propAssignments: response.data });
            })
            .catch(error => {
                dispatch({ type: 'BLOCK_FAIL', error: error.response.data.error });
            })
    },
    getBlocks: (reference: string, referenceId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'BLOCK_START' });
        const appState = getState();

        if (!appState.auth)
            return;

        const url = '/block/blocks';
        Axios.post(url, { ReferenceType: reference, ReferenceId: referenceId })
            .then(response => {
                SnackBarUtils.success('Blöcke wurden erfolgreich geladen.');
                dispatch({ type: 'BLOCK_SUCCESS', blocks: response.data });
            })
            .catch(error => {
                SnackBarUtils.error('Beim Laden der Blöcke ist ein Fehler aufgetreten.');
                dispatch({ type: 'BLOCK_FAIL', error: error.response.error });
            })
    },
    createBlock: (blockType: string, reference: string, referenceId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'BLOCK_START' });
        const appState = getState();

        if (!appState.auth)
            return;

        const url = '/block/';
        Axios.post(url, { BlockType: blockType, ReferenceType: reference, ReferenceId: referenceId, LocationPath: appState.block?.selectedBlock?.locationPath ?? '' })
            .then(response => {
                SnackBarUtils.success('Block wurde erfolgreich hinzugefügt.');
                dispatch({ type: 'BLOCK_ADD_SUCCESS', blocks: response.data });
            })
            .catch(error => {
                SnackBarUtils.error('Beim Hinzufügen des Blocks ist ein Fehler aufgetreten.');
                dispatch({ type: 'BLOCK_FAIL', error: error.response.data.error });
            })
    },
    updateBlock: (newBlock: BlockData): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'BLOCK_START' });
        const appState = getState();

        if (!appState.auth)
            return;

        const url = '/block/update';
        Axios.post(url, newBlock)
            .then(response => {
                SnackBarUtils.success('Block wurde erfolgreich geändert.');
                dispatch({ type: 'BLOCK_UPDATE_SUCCESS', blocks: response.data });
            })
            .catch(error => {
                SnackBarUtils.error('Beim Ändern des Blocks ist ein Fehler aufgetreten.');
                dispatch({ type: 'BLOCK_FAIL', error: error.response.data.error });
            })
    },
    sortBlocks: (direction: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'BLOCK_START' });
        const appState = getState();

        if (!appState.auth)
            return;

        let indexOfSecond: number = 0;
        let second;
        let sortedList;

        if(appState.block?.selectedBlock){
            const searchLocationPath = appState.block.selectedBlock.locationPath.indexOf('-') > -1 ? appState.block.selectedBlock.locationPath.substring(0, appState.block.selectedBlock.locationPath.lastIndexOf('-') + 1) : '';
    
            sortedList = appState.block?.blocks
                .filter(x => x.locationPath === searchLocationPath + x.id)
                .sort((a, b) => a.sortPath - b.sortPath);
            if (appState.block!.selectedBlock && sortedList) {
                indexOfSecond = sortedList.map(function (e) { return e.locationPath; }).indexOf(appState.block!.selectedBlock.locationPath) + direction;
                if (indexOfSecond < sortedList.length && indexOfSecond >= 0) {
                    second = sortedList[indexOfSecond];
                }
            }
    
            const url = '/block/sort';
            Axios.post(url, { firstBlock: appState.block?.selectedBlock, secondBlock: second })
                .then(response => {
                    SnackBarUtils.success('Block wurde erfolgreich sortiert.');
                    dispatch({ type: 'BLOCK_SORT_SUCCESS', blocks: response.data });
                })
                .catch(error => {
                    SnackBarUtils.error('Beim Sortieren der Blöcke ist ein Fehler aufgetreten.');
                    dispatch({ type: 'BLOCK_FAIL', error: error.response.data.error });
                })
        }
        else {
            dispatch({ type: 'BLOCK_FAIL', error: 'No block selected.' });
        }
    },
    copyBlock: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        SnackBarUtils.info('Blöcke wurden erfolgreich kopiert.');
        dispatch({ type: 'BLOCK_COPY', locationPath: appState.block?.selectedBlock?.locationPath ?? "" });
    },
    pasteBlocks: (reference: string, referenceId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'BLOCK_START' });
        const appState = getState();

        if (!appState.auth)
            return;

        const url = '/block/paste';
        Axios.post(url, { copyLocationPath: appState.block?.blockClipboard, blockId: appState.block?.selectedBlock?.id, ReferenceType: reference, ReferenceId: referenceId })
            .then(response => {
                SnackBarUtils.success('Block wurde erfolgreich eingefügt.');
                dispatch({ type: 'BLOCK_ADD_SUCCESS', blocks: response.data });
            })
            .catch(error => {
                SnackBarUtils.error('Beim Einfügen der Blöcke ist ein Fehler aufgetreten.');
                dispatch({ type: 'BLOCK_FAIL', error: error.response.data.error });
            })
    },
    appendBlockTemplate: (templateId: number, referenced: boolean, reference: string, referenceId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'BLOCK_START' });
        const appState = getState();

        if (!appState.auth)
            return;

        const url = '/template/append';
        Axios.post(url, { templateId: templateId, blockId: appState.block?.selectedBlock?.id, referenced, ReferenceType: reference, ReferenceId: referenceId })
            .then(response => {
                SnackBarUtils.success('Template wurde eingefügt.');
                dispatch({ type: 'BLOCK_ADD_SUCCESS', blocks: response.data });
            })
            .catch(error => {
                SnackBarUtils.error('Beim Einfügen des Templates ist ein Fehler aufgetreten.');
                dispatch({ type: 'BLOCK_FAIL', error: error.response.data.error });
            })
    },
    selectBlock: (block?: BlockData): AppThunkAction<KnownAction> => (dispatch, getState) => {

        const appState = getState();
        if(block !== undefined && appState.block?.selectedBlock !== undefined && block.id === appState.block.selectedBlock.id)
            return;

        dispatch({ type: 'BLOCK_SELECT', block: block });
    },
    deleteBlock: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'BLOCK_START' });
        const appState = getState();

        if (!appState.auth || !appState.block?.selectedBlock)
            return;

        const selectedBlock = appState.block?.selectedBlock;
        const url = '/block/' + selectedBlock.id;
        Axios.delete(url)
            .then(response => {
                SnackBarUtils.success('Block wurde erfolgreich gelöscht.');
                dispatch({ type: 'BLOCK_DELETE_SUCCESS', block: selectedBlock });
            })
            .catch(error => {
                dispatch({ type: 'BLOCK_FAIL', error: error.response.data.error });
                SnackBarUtils.error('Beim Löschen der Blöcke ist ein Fehler aufgetreten.');
            })
    },
    deployData: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();

        if (!appState.auth)
            return;

        const url = '/deployment';
        Axios.post(url)
            .catch(error => {
                dispatch({ type: 'BLOCK_FAIL', error: error.response.data.error });
            })
    },
    createEvent: (blockEvent: BlockEvent): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'BLOCK_START' });
        const appState = getState();

        if (!appState.auth || !appState.block?.selectedBlock)
            return;

        const url = '/block/addevent';
        Axios.post(url, { BlockEvent: blockEvent, BlockId: appState.block?.selectedBlock.id })
            .then(response => {
                dispatch({ type: 'EVENT_ADD_SUCCESS', event: response.data });
            })
            .catch(error => {
                dispatch({ type: 'BLOCK_FAIL', error: error.response.data.error });
            })
    },
    deleteEvent: (eventId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'BLOCK_START' });
        const appState = getState();

        if (!appState.auth || !appState.block?.selectedBlock)
            return;

        const url = '/block/event/' + eventId;
        Axios.delete(url)
            .then(response => {
                dispatch({ type: 'EVENT_DELETE_SUCCESS', eventId: eventId });
            })
            .catch(error => {
                dispatch({ type: 'BLOCK_FAIL', error: error.response.data.error });
            })
    },
    updateEvent: (blockEvent: BlockEvent): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'BLOCK_START' });
        const appState = getState();

        if (!appState.auth)
            return;

        const url = '/block/updateevent';
        Axios.post(url, blockEvent)
            .then(response => {
                dispatch({ type: 'EVENT_UPDATE_SUCCESS', event: response.data });
            })
            .catch(error => {
                dispatch({ type: 'BLOCK_FAIL', error: error.response.data.error });
            })
    },
    createEventTrigger: (blockEventTrigger: BlockEventTrigger): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'BLOCK_START' });
        const appState = getState();

        if (!appState.auth || !appState.block?.selectedBlock)
            return;

        const url = '/block/addeventtrigger';
        Axios.post(url, { BlockEventTrigger: blockEventTrigger, BlockId: appState.block?.selectedBlock.id })
            .then(response => {
                dispatch({ type: 'EVENTTRIGGER_ADD_SUCCESS', eventTrigger: response.data });
            })
            .catch(error => {
                dispatch({ type: 'BLOCK_FAIL', error: error.response.data.error });
            })
    },
    deleteEventTrigger: (eventTriggerId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'BLOCK_START' });
        const appState = getState();

        if (!appState.auth || !appState.block?.selectedBlock)
            return;

        const url = '/block/eventtrigger/' + eventTriggerId;
        Axios.delete(url)
            .then(() => {
                dispatch({ type: 'EVENTTRIGGER_DELETE_SUCCESS', eventTriggerId: eventTriggerId });
            })
            .catch(error => {
                dispatch({ type: 'BLOCK_FAIL', error: error.response.data.error });
            })
    },
    updateEventTrigger: (blockEventTrigger: BlockEventTrigger): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'BLOCK_START' });
        const appState = getState();

        if (!appState.auth)
            return;

        const url = '/block/updateeventtrigger';
        Axios.post(url, blockEventTrigger)
            .then(response => {
                dispatch({ type: 'EVENTTRIGGER_UPDATE_SUCCESS', eventTrigger: response.data });
            })
            .catch(error => {
                dispatch({ type: 'BLOCK_FAIL', error: error.response.data.error });
            })
    },
    createBlockStyle: (newStyle: BlockStyle): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'BLOCK_START' });
        const appState = getState();

        if (!appState.auth)
            return;

        const url = '/block/addblockstyle';
        Axios.post(url, { NewBlockStyle: newStyle, BlockId: appState?.block?.selectedBlock?.id ?? 0 })
            .then(response => {
                dispatch({ type: 'BLOCKSTYLE_ADD_SUCCESS', blockStyle: response.data });
            })
            .catch(error => {
                dispatch({ type: 'BLOCK_FAIL', error: error.response.data.error });
            })
    },
    deleteBlockStyle: (blockStyleId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'BLOCK_START' });
        const appState = getState();

        if (!appState.auth)
            return;

        const url = '/block/blockstyle/' + blockStyleId;
        Axios.delete(url)
            .then(() => {
                dispatch({ type: 'BLOCKSTYLE_DELETE_SUCCESS', blockStyleId: blockStyleId });
            })
            .catch(error => {
                dispatch({ type: 'BLOCK_FAIL', error: error.response.data.error });
            })
    },
    updateBlockStyle: (blockStyle: BlockStyle): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'BLOCK_START' });
        const appState = getState();

        if (!appState.auth)
            return;

        const url = '/block/updateblockstyle';
        Axios.post(url, blockStyle)
            .then(response => {
                dispatch({ type: 'BLOCKSTYLE_UPDATE_SUCCESS', blockStyle: response.data });
            })
            .catch(error => {
                dispatch({ type: 'BLOCK_FAIL', error: error.response.data.error });
            })
    },
};

const unloadedState: BlockState = {
    blocks: [],
    globalStyles: [],
    selectedBlock: undefined,
    blockClipboard: "",
    error: "",
    loading: false,
};

export const reducer: Reducer<BlockState | undefined> = (state: BlockState | undefined, incomingAction: KnownAction): BlockState | undefined => {
    if (state === undefined) {
        return unloadedState;
    }
    switch (incomingAction.type) {        
        case 'BLOCK_GLOBAL_STYLE_SUCCESS':
            return updateObject(state, { globalStyles: [...incomingAction.blockStyles], error: null, loading: false });
        case 'BLOCK_DYN_PROP_ASSIGNMENT_UPDATE_SUCCESS':
            let newBlock = { ...state.selectedBlock };
            newBlock.dynPropAssignments = incomingAction.propAssignments;
            return updateObject(state, { blocks: [...state.blocks.filter((x: BlockData) => state.selectedBlock?.id !== x.id), newBlock], error: null, loading: false, selectedBlock: newBlock });
        case 'BLOCK_DELETE_SUCCESS':
            return updateObject(state, { blocks: state.blocks.filter(x => !x.locationPath.startsWith(incomingAction.block.locationPath)), loading: false });
        case 'BLOCK_ADD_SUCCESS':
            return updateObject(state, { blocks: [...state.blocks, ...incomingAction.blocks], error: null, loading: false, selectedBlock: incomingAction.blocks[0] });
        case 'BLOCK_UPDATE_SUCCESS':
            const updatedBlockData = [...state.blocks.filter((x: BlockData) => incomingAction.blocks.findIndex(y => y.id == x.id) == -1), ...incomingAction.blocks];
            return updateObject(state, { blocks: updatedBlockData, error: null, loading: false, selectedBlock: incomingAction.blocks[0] });
        case 'BLOCK_SORT_SUCCESS':
            const addedBlockData = [...state.blocks.filter((x: BlockData) => incomingAction.blocks.findIndex(y => y.id == x.id) == -1), ...incomingAction.blocks];
            return updateObject(state, { blocks: addedBlockData, error: null, loading: false, selectedBlock: incomingAction.blocks.find(x => x.id === state.selectedBlock?.id) });
        case 'BLOCK_START':
            return updateObject(state, { error: null, loading: true });
        case 'BLOCK_SUCCESS':
            return updateObject(state, { blocks: [...incomingAction.blocks], error: null, loading: false });
        case 'BLOCK_COPY':
            return updateObject(state, { blockClipboard: incomingAction.locationPath });
        case 'BLOCK_FAIL':
            return updateObject(state, { error: incomingAction.error, loading: false });
        case 'BLOCK_SELECT':
            return updateObject(state, { selectedBlock: incomingAction.block });
        case 'EVENT_DELETE_SUCCESS':
            const deletedEventBlock = { ...state.selectedBlock };
            if (deletedEventBlock.blockEvents) {
                deletedEventBlock.blockEvents = [...deletedEventBlock.blockEvents?.filter(x => x.id !== incomingAction.eventId)];
                return updateObject(state, { blocks: [...state.blocks.filter(x => x.id !== deletedEventBlock.id), deletedEventBlock], selectedBlock: deletedEventBlock, loading: false });
            }
            return state;
        case 'EVENT_ADD_SUCCESS':
            const AddedEventBlock = { ...state.selectedBlock };
            if (AddedEventBlock.blockEvents) {
                AddedEventBlock.blockEvents = [...AddedEventBlock.blockEvents, incomingAction.event];

                return updateObject(state, { blocks: [...state.blocks.filter(x => x.id !== AddedEventBlock.id), AddedEventBlock], selectedBlock: AddedEventBlock, loading: false });
            }
            return state;
        case 'EVENT_UPDATE_SUCCESS':
            const updatedEventBlock = { ...state.selectedBlock };
            if (updatedEventBlock.blockEvents) {
                updatedEventBlock.blockEvents = [...updatedEventBlock.blockEvents?.filter(x => x.id !== incomingAction.event.id), incomingAction.event];

                return updateObject(state, { blocks: [...state.blocks.filter(x => x.id !== updatedEventBlock.id), updatedEventBlock], selectedBlock: updatedEventBlock, loading: false });
            }
            return state;
        case 'EVENTTRIGGER_DELETE_SUCCESS':
            const deletedEventTriggerBlock = { ...state.selectedBlock };
            if (deletedEventTriggerBlock.blockEventTriggers) {
                deletedEventTriggerBlock.blockEventTriggers = [...deletedEventTriggerBlock.blockEventTriggers?.filter(x => x.id !== incomingAction.eventTriggerId)];

                return updateObject(state, { blocks: [...state.blocks.filter(x => x.id !== deletedEventTriggerBlock.id), deletedEventTriggerBlock], selectedBlock: deletedEventTriggerBlock, loading: false });
            }
            return state;
        case 'EVENTTRIGGER_ADD_SUCCESS':
            const AddedEventTriggerBlock = { ...state.selectedBlock };
            if (AddedEventTriggerBlock.blockEventTriggers) {
                AddedEventTriggerBlock.blockEventTriggers = [...AddedEventTriggerBlock.blockEventTriggers, incomingAction.eventTrigger];

                return updateObject(state, { blocks: [...state.blocks.filter(x => x.id !== AddedEventTriggerBlock.id), AddedEventTriggerBlock], selectedBlock: AddedEventTriggerBlock, loading: false });
            }
            return state;
        case 'EVENTTRIGGER_UPDATE_SUCCESS':
            const updatedEventTriggerBlock = { ...state.selectedBlock };
            if (updatedEventTriggerBlock.blockEventTriggers) {
                updatedEventTriggerBlock.blockEventTriggers = [...updatedEventTriggerBlock.blockEventTriggers?.filter(x => x.id !== incomingAction.eventTrigger.id), incomingAction.eventTrigger];

                return updateObject(state, { blocks: [...state.blocks.filter(x => x.id !== updatedEventTriggerBlock.id), updatedEventTriggerBlock], selectedBlock: updatedEventTriggerBlock, loading: false });
            }
            return state;
        case 'BLOCKSTYLE_DELETE_SUCCESS':
            const globalStyle = state.globalStyles.find(x => x.id === incomingAction.blockStyleId);

            if(globalStyle){
                return updateObject(state, { globalStyles: [...state.globalStyles.filter(x => x.id !== incomingAction.blockStyleId)], loading: false });
            }

            const deletedBlockStyleBlock = { ...state.selectedBlock };
            if (deletedBlockStyleBlock.blockStyles) {
                deletedBlockStyleBlock.blockStyles = [...deletedBlockStyleBlock.blockStyles.filter(x => x.id !== incomingAction.blockStyleId)];

                return updateObject(state, { blocks: [...state.blocks.filter(x => x.id !== deletedBlockStyleBlock.id), deletedBlockStyleBlock], selectedBlock: deletedBlockStyleBlock, loading: false });
            }
            return state;
        case 'BLOCKSTYLE_ADD_SUCCESS':  

            if(state.selectedBlock === undefined) {
                return updateObject(state, { globalStyles: [...state.globalStyles, incomingAction.blockStyle], loading: false });
            }

            const AddedBlockStyleBlock = { ...state.selectedBlock };

            if (AddedBlockStyleBlock.blockStyles) {
                AddedBlockStyleBlock.blockStyles = [...AddedBlockStyleBlock.blockStyles, incomingAction.blockStyle];

                return updateObject(state, { blocks: [...state.blocks.filter(x => x.id !== AddedBlockStyleBlock.id), AddedBlockStyleBlock], selectedBlock: AddedBlockStyleBlock, loading: false });
            }

            return state;
        case 'BLOCKSTYLE_UPDATE_SUCCESS':

            if(state.selectedBlock === undefined) {
                return updateObject(state, { globalStyles: [...state.globalStyles.filter(x => x.id !== incomingAction.blockStyle.id), incomingAction.blockStyle], loading: false })
            }

            const updatedBlockStyleBlock = { ...state.selectedBlock };

            if (updatedBlockStyleBlock.blockStyles) {
                updatedBlockStyleBlock.blockStyles = [...updatedBlockStyleBlock.blockStyles?.filter(x => x.id !== incomingAction.blockStyle.id), incomingAction.blockStyle];

                const newState = updateObject(state, { blocks: [...state.blocks.filter(x => x.id !== updatedBlockStyleBlock.id), updatedBlockStyleBlock], selectedBlock: updatedBlockStyleBlock, loading: false });
                return newState;
            }
            return state;
        default:
            return state;
    }
}