import { isEqual } from 'lodash';
import * as React from 'react';

export default function useDeepEffect(func: any, deps: any) {
  const isFirst = React.useRef(true);
  const prevDeps = React.useRef(deps);

  React.useEffect(() => {
    const isSame = prevDeps.current.every((obj: any, index: any) => isEqual(obj, deps[index]));

    if (isFirst.current || !isSame) {
      func();
    }

    isFirst.current = false;
    prevDeps.current = deps;
  }, deps);
}