import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function RealMode(props: SvgIconProps) {
        return (
                <SvgIcon {...props}>
                       <path d="M21.3,2.2H2.7C1.2,2.2,0,3.4,0,4.9V13c0,1.5,1.2,2.7,2.7,2.7h8.1c0,0,0,0,0,0v4.6H7.6c-0.3,0-0.6,0.3-0.6,0.6
		v0.3c0,0.3,0.3,0.6,0.6,0.6h8.8c0.3,0,0.6-0.3,0.6-0.6v-0.3c0-0.3-0.3-0.6-0.6-0.6h-3.2v-4.6c0,0,0,0,0,0h8.1
		c1.5,0,2.7-1.2,2.7-2.7V4.9C24,3.4,22.8,2.2,21.3,2.2z"/>
                </SvgIcon>
        );
}