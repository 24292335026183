import React, { MutableRefObject, ReactNode } from 'react';
import { ContainerBlockData } from '../../../store/Block';
import { Container } from 'react-grid-system';

/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react';


interface Props {
    blockData: ContainerBlockData,
    classes: string,
    blockClickHandler: (e: React.MouseEvent<HTMLElement>) => void,
    canvasMode: string,
    children: ReactNode,
    styles: any,
}


const ContainerBlock = (props: Props) => {
    const { canvasMode, children, blockData, classes, blockClickHandler, styles } = props;
    let maxWidth: string = "";
    switch (canvasMode) {
        case 'sm':
            maxWidth = "540px";
            break;
        case 'md':
            maxWidth = "720px";
            break;
        case 'lg':
            maxWidth = "960px";
            break;
        case 'xl':
            maxWidth = "1140px";
            break;
        case 'xxl':
            maxWidth = "1320px";
            break;
        default:
            maxWidth = "";
            break;
    }

    let displayedBlock = (
        <Container css={styles} className={classes} onClick={blockClickHandler} fluid={blockData.fluid}>
            { children }
        </Container>
    );

    return (
        <>
            {displayedBlock}
        </>
    );
}

export default ContainerBlock;