import { Reducer } from "redux";
import { updateObject } from "../Utility";
import Axios from '../services/api';
import { ApplicationState, AppThunkAction } from '.';
import SnackBarUtils from "../components/SnackBar/SnackBarOperations";

export interface DynDataState {
    models: DynModel[],
    dataSets: DynDataSet[],
    loading: boolean,
};

const unloadedState: DynDataState = {
    models: [],
    dataSets: [],
    loading: false,
};

export interface DynProps {
    id: number,
    type: string,
    tag: string,
}

export interface DynModel {
    id: number,
    name: string,
    dynPropSets: DynProps[],
}

export interface DynDataEntryProp {
    tag: string,
    value: string,
}

export interface DynDataEntry {
    id: number,
    data: string,
}

export interface DynDataSet {
    id: number,
    dynModel: DynModel,
    dynDataEntries: DynDataEntry[],
}

export interface DynPropAssignment {
    id: number,
    dynProp: DynProps,
    blockProp: string,
}

export interface DynDataSuccessAction {
    type: 'DYN_START',
}

export interface DynDataFailAction {
    type: 'DYN_FAIL',
    error: string
}

export interface DynDataModelSuccessAction {
    type: 'DYN_MODEL_SUCCESS',
    models: DynModel[],
}

export interface DynDataUpdateModelSuccessAction {
    type: 'DYN_MODEL_UPDATE_SUCCESS',
    model: DynModel,
}

export interface DynDataAddModelSuccessAction {
    type: 'DYN_MODEL_ADD_SUCCESS',
    model: DynModel,
}

export interface DynDataDeleteModelSuccessAction {
    type: 'DYN_MODEL_DELETE_SUCCESS',
    model: DynModel,
}

export interface DynDataSetSuccessAction {
    type: 'DYN_DATA_SET_SUCCESS',
    dataSets: DynDataSet[],
}

export interface DynDataUpdateDataSetSuccessAction {
    type: 'DYN_DATA_SET_UPDATE_SUCCESS',
    dataSet: DynDataSet,
}

export interface DynDataAddDataSetSuccessAction {
    type: 'DYN_DATA_SET_ADD_SUCCESS',
    dataSet: DynDataSet,
}

export interface DynDataDeleteDataSetSuccessAction {
    type: 'DYN_DATA_SET_DELETE_SUCCESS',
    dataSet: DynDataSet,
}

export type KnownAction =
    | DynDataSuccessAction
    | DynDataFailAction
    | DynDataModelSuccessAction
    | DynDataSetSuccessAction
    | DynDataUpdateModelSuccessAction
    | DynDataDeleteModelSuccessAction
    | DynDataAddModelSuccessAction
    | DynDataUpdateDataSetSuccessAction
    | DynDataDeleteDataSetSuccessAction
    | DynDataAddDataSetSuccessAction;

export const actionCreators = {
    createModel: (newModel: DynModel): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'DYN_START' });
        const appState = getState();

        if (!appState.auth)
            return;

        const url = '/dyndata/model';
        Axios.post(url, newModel)
            .then(response => {
                dispatch({ type: 'DYN_MODEL_SUCCESS', models: response.data });
            })
            .catch(error => {
                dispatch({ type: 'DYN_FAIL', error: error.response.data.error });
            })
    },
    updateModel: (dynModel: DynModel): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'DYN_START' });
        const appState = getState();

        if (!appState.auth)
            return;

        const url = '/dyndata/model/update';
        Axios.post(url, dynModel)
            .then(response => {
                dispatch({ type: 'DYN_MODEL_UPDATE_SUCCESS', model: response.data });
            })
            .catch(error => {
                dispatch({ type: 'DYN_FAIL', error: error.response.data.error });
            })
    },
    deleteModel: (model: DynModel): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'DYN_START' });
        const appState = getState();

        if (!appState.auth)
            return;

        const url = '/dyndata/model/' + model.id;
        Axios.delete(url)
            .then(response => {
                dispatch({ type: 'DYN_MODEL_DELETE_SUCCESS', model: model });
            })
            .catch(error => {
                dispatch({ type: 'DYN_FAIL', error: error.response.data.error });
            })
    },
    getModels: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'DYN_START' });
        const appState = getState();

        if (!appState.auth)
            return;

        const url = '/dyndata/model';
        Axios.get(url)
            .then(response => {
                dispatch({ type: 'DYN_MODEL_SUCCESS', models: response.data });
            })
            .catch(error => {
                dispatch({ type: 'DYN_FAIL', error: error.response.data.error });
            })
    },
    getDataSets: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'DYN_START' });
        const appState = getState();

        if (!appState.auth)
            return;

        const url = '/dyndata';
        Axios.get(url)
            .then(response => {
                dispatch({ type: 'DYN_DATA_SET_SUCCESS', dataSets: response.data });
            })
            .catch(error => {
                dispatch({ type: 'DYN_FAIL', error: error.response.data.error });
            })
    },
    createDataSet: (newDataSet: DynDataSet): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'DYN_START' });
        const appState = getState();

        if (!appState.auth)
            return;

        const url = '/dyndata';
        Axios.post(url, newDataSet)
            .then(response => {
                dispatch({ type: 'DYN_DATA_SET_ADD_SUCCESS', dataSet: response.data });
            })
            .catch(error => {
                dispatch({ type: 'DYN_FAIL', error: error.response.data.error });
            })
    },
    updateDataSet: (dynDataSet: DynDataSet): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'DYN_START' });
        const appState = getState();

        if (!appState.auth)
            return;

        const url = '/dyndata/update';
        Axios.post(url, dynDataSet)
            .then(response => {
                dispatch({ type: 'DYN_DATA_SET_UPDATE_SUCCESS', dataSet: response.data });
            })
            .catch(error => {
                dispatch({ type: 'DYN_FAIL', error: error.response.data.error });
            })
    },
    deleteDataSet: (dynDataSet: DynDataSet): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'DYN_START' });
        const appState = getState();

        if (!appState.auth)
            return;

        const url = '/dyndata/' + dynDataSet.id;
        Axios.delete(url)
            .then(response => {
                dispatch({ type: 'DYN_DATA_SET_DELETE_SUCCESS', dataSet: dynDataSet });
            })
            .catch(error => {
                dispatch({ type: 'DYN_FAIL', error: error.response.data.error });
            })
    },
};

export const reducer: Reducer<DynDataState | undefined> = (state: DynDataState | undefined, incomingAction: KnownAction): DynDataState | undefined => {
    if (state === undefined) {
        return unloadedState;
    }
    switch (incomingAction.type) {
        case 'DYN_START':
            return updateObject(state, { loading: true });
        case 'DYN_FAIL':
            return updateObject(state, { loading: false });
        case 'DYN_MODEL_SUCCESS':
            return updateObject(state, { loading: false, models: [...incomingAction.models] });
        case 'DYN_MODEL_ADD_SUCCESS':
            return updateObject(state, { loading: false, models: [...state.models, incomingAction.model] });
        case 'DYN_MODEL_UPDATE_SUCCESS':
            return updateObject(state, { loading: false, models: [...state.models.filter(x => x.id !== incomingAction.model.id), incomingAction.model] });
        case 'DYN_MODEL_DELETE_SUCCESS':
            return updateObject(state, { loading: false, models: state.models.filter(x => x.id !== incomingAction.model.id) });
        case 'DYN_DATA_SET_SUCCESS':
            return updateObject(state, { loading: false, dataSets: incomingAction.dataSets });
        case 'DYN_DATA_SET_ADD_SUCCESS':
            return updateObject(state, { loading: false, dataSets: [...state.dataSets, incomingAction.dataSet] });
        case 'DYN_DATA_SET_UPDATE_SUCCESS':
            return updateObject(state, { loading: false, dataSets: [...state.dataSets.filter(x => x.id !== incomingAction.dataSet.id), incomingAction.dataSet] });
        case 'DYN_DATA_SET_DELETE_SUCCESS':
            return updateObject(state, { loading: false, dataSets: state.dataSets.filter(x => x.id !== incomingAction.dataSet.id) });
        default:
            return state;
    }
}