import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button } from '@mui/material';
import React from 'react'
import { useSelector } from 'react-redux';
import { selectMailConnections } from '../../store/selectors/ConfigSelector';
import { MailTemplate } from '../../store/Configuration';

interface Props {
    open: boolean,
    handleClose: () => void,
    handleSubmit: (mailTemplate: MailTemplate) => void,
    mailTemplate: MailTemplate | undefined,
}

const MailTemplateDialog = (props: Props) => {

    const defaultMailTemplate: MailTemplate = { id: 0, name: '', content: '' }
    const { open, handleClose, mailTemplate, handleSubmit } = props;
    const [currentMailConnection, SetCurrentMailTemplate] = React.useState(defaultMailTemplate);

    React.useEffect(() => {
        if (mailTemplate)
            SetCurrentMailTemplate(mailTemplate);
        else
            SetCurrentMailTemplate(defaultMailTemplate);
    }, [mailTemplate])

    const handleOnSubmit = () => {
        handleSubmit(currentMailConnection);
        handleOnClose();
    }

    const handleOnClose = () => {
        SetCurrentMailTemplate(defaultMailTemplate);
        handleClose();
    }

    return (
        <Dialog
            fullWidth 
            maxWidth="md"
            open={open}
            onClose={handleClose}
        >
            <DialogTitle>
                Mail Template
            </DialogTitle>
            <DialogContent>
                <TextField label="Name" placeholder="Name" value={currentMailConnection.name} onChange={(e) => SetCurrentMailTemplate({ ...currentMailConnection, name: e.target.value })} />
                <div>
                    <TextField multiline fullWidth rows={20} maxRows={20} label="Content" placeholder="Content" value={currentMailConnection.content} onChange={(e) => SetCurrentMailTemplate({ ...currentMailConnection, content: e.target.value })} />
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>cancel</Button>
                <Button onClick={handleOnSubmit} autoFocus>
                Submit
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default MailTemplateDialog;