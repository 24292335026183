import React from 'react';
import { actionCreators, BlockData } from '../../store/Block';
import BlockMapBlock from './BlockMapBlock';
import { List, ListItem, ListItemButton, ListItemText, styled } from '@mui/material';
import { useDispatch } from 'react-redux';

interface Props {
    blocks: BlockData[];
    selectedBlock: BlockData | undefined;
}

const PREFIX = 'BlockMap';

const classes = {
    blockMapContainer: `${PREFIX}-blockMapContainer`
};

const Root = styled('div')((
    {
        theme
    }
) => ({
    [`&.${classes.blockMapContainer}`]: {
        flex: '0 0 42%',
        height: '100%',
        maxHeight: 'calc(100vh - 65px)',
        overflowY: 'scroll',
        padding: theme.spacing(0, 1),
    }
}));

const BlockMap = (props: Props) => {
    const { blocks, selectedBlock } = props;
    const filteredBlocks = blocks.filter(x => x.locationPath?.indexOf('-') === -1);
    const dispatch = useDispatch();

    const displayedBlocks = filteredBlocks
        .sort((a, b) => a.sortPath - b.sortPath)
        .map((block, index) => {

            return (
                <BlockMapBlock
                    key={block.id}
                    currentBlock={block}
                    blocks={blocks.filter(x => x.locationPath?.startsWith(block.locationPath + '-'))}
                    selectedBlock={selectedBlock}
                    level={1}
                    firstEntry={index === 0}
                    lastEntry={filteredBlocks.length - 1 === index}
                />
            )
        });

    return (
        <Root className={classes.blockMapContainer}>
            <List component="div" disablePadding>
                <ListItem disablePadding>
                    <ListItemButton sx={{padding: 0}} onClick={() => dispatch(actionCreators.selectBlock(undefined))} selected={selectedBlock === undefined} disableGutters>
                        <ListItemText sx={{marginLeft: '24px'}} primary="<main>" />
                    </ListItemButton>
                </ListItem>
                <List component="div" disablePadding>
                    {displayedBlocks}
                </List>
                <ListItem disablePadding>
                    <ListItemButton sx={{padding: 0}} onClick={() => dispatch(actionCreators.selectBlock(undefined))} selected={selectedBlock === undefined} disableGutters>
                        <ListItemText sx={{marginLeft: '24px'}} primary="</main>" />
                    </ListItemButton>
                </ListItem>
            </List>
        </Root>
    )
}

export default BlockMap;