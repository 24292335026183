import { BlockData } from "./store/Block";

const autoCreatedTypes: string[] = [

]

const noChildTypes = [
    "header",
    "text",
    "media",
    "countup",
    "tabpanelsholder",
    "rating",
    "beforeafter",
    "accordionitemheader",
    "accordionitembody",
]

export const CanBeSorted = (blocks: BlockData[], blockToSort: BlockData, direction: number): boolean => {
    if(blockToSort === undefined || blockToSort === null || !blockToSort.locationPath)
        return true;

    if ((blockToSort?.templateReference && blockToSort.templateReference?.locationPath?.indexOf('-') > 0))
        return true;

    if (autoCreatedTypes.indexOf(blockToSort?.type) > -1)
        return true;

    let indexOfSecond: number = 0;
    let second;
    let sortedList;
    const searchLocationPath = blockToSort.locationPath.substring(0, blockToSort.locationPath.lastIndexOf('-') + 1);

    sortedList = blocks
        .filter(x => x.locationPath === searchLocationPath + x.id)
        .sort((a, b) => a.sortPath - b.sortPath);
    if (blockToSort && sortedList) {
        indexOfSecond = sortedList.map(function (e) { return e.locationPath; }).indexOf(blockToSort.locationPath) + direction;
        if (indexOfSecond < sortedList.length && indexOfSecond >= 0) {
            second = sortedList[indexOfSecond];
        }
    }

    if (second)
        return false;
    else
        return true;
}

export const CanBeCopied = (blockToCopy: BlockData): boolean => {
    if(blockToCopy === undefined || blockToCopy === null)
        return true;

    if (blockToCopy?.templateReference && blockToCopy.templateReference.locationPath.indexOf('-') > 0)
        return true;

    if (autoCreatedTypes.indexOf(blockToCopy?.type) > -1)
        return true;

    return false;
}

export const CanBeDeleted = (blockToDelete: BlockData): boolean => {
    if(blockToDelete === undefined || blockToDelete === null)
        return true;
    if (blockToDelete?.templateReference && blockToDelete.templateReference.locationPath.indexOf('-') > 0)
        return true;

    if (autoCreatedTypes.indexOf(blockToDelete?.type) > -1)
        return true;

    return false;
}

export const CanBeCreated = (blockToCreate: BlockData): boolean => {
    if (blockToCreate?.templateReference && blockToCreate.templateReference.locationPath.indexOf('-') > 0)
        return true;

    if (noChildTypes.indexOf(blockToCreate?.type) > -1)
        return true;

    return false;
}

export const CanBePasted = (blockToCreate: BlockData): boolean => {
    if (noChildTypes.indexOf(blockToCreate?.type) > -1)
        return true;

    return false;
}
