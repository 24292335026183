import React, { useContext } from 'react';
import clsx from 'clsx';
import { BlockData } from '../../store/Block';
import { Button, Dialog, DialogContent, DialogTitle, Fab, Grid, List, ListItem, ListItemText, styled, Typography } from '@mui/material';
import { LoadTemplate } from '../Icons/LoadTemplate';
import { SaveTemplate } from '../Icons/SaveTemplate';
import DeleteSharpIcon from '@mui/icons-material/DeleteSharp';
import FileCopySharpIcon from '@mui/icons-material/FileCopySharp';
import InsertDriveFileSharpIcon from '@mui/icons-material/InsertDriveFileSharp';
import ArrowUpwardSharpIcon from '@mui/icons-material/ArrowUpwardSharp';
import ArrowDownwardSharpIcon from '@mui/icons-material/ArrowDownwardSharp';
import { Devmode } from '../Icons/Devmode';
import { RealMode } from '../Icons/RealMode';
import AddIcon from '@mui/icons-material/Add';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { CanBeCopied, CanBeDeleted, CanBePasted, CanBeSorted } from '../../Validate';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { DynDataSet } from '../../store/DynData';

import CropSquareSharpIcon from '@mui/icons-material/CropSquareSharp';
import FormatSizeSharpIcon from '@mui/icons-material/FormatSizeSharp';
import FormatAlignJustifySharpIcon from '@mui/icons-material/FormatAlignJustifySharp';
import PermMediaSharpIcon from '@mui/icons-material/PermMediaSharp';
import InsertLinkSharpIcon from '@mui/icons-material/InsertLinkSharp';
import BorderHorizontalSharpIcon from '@mui/icons-material/BorderHorizontalSharp';
import CalendarViewMonthSharpIcon from '@mui/icons-material/CalendarViewMonthSharp';
import ViewColumnSharpIcon from '@mui/icons-material/ViewColumnSharp';
import DashboardSharpIcon from '@mui/icons-material/DashboardSharp';
import DashboardCustomizeSharpIcon from '@mui/icons-material/DashboardCustomizeSharp';
import FilterFramesSharpIcon from '@mui/icons-material/FilterFramesSharp';
import ViewCarouselSharpIcon from '@mui/icons-material/ViewCarouselSharp';
import HourglassTopSharpIcon from '@mui/icons-material/HourglassTopSharp';
import BorderVerticalSharpIcon from '@mui/icons-material/BorderVerticalSharp';
import { useSelector } from 'react-redux';
import { selectLanguages } from '../../store/selectors/ConfigSelector';
import { TranslationContext } from '../Contexts/TranslationContext';

const PREFIX = 'BlockAppBar';

const classes = {
    fabContainer: `${PREFIX}-fabContainer`,
    fabMarginLeft: `${PREFIX}-fabMarginLeft`,
    fabLeftPageButton: `${PREFIX}-fabLeftPageButton`,
    fabRightPageButton: `${PREFIX}-fabRightPageButton`,
    fabPageCount: `${PREFIX}-fabPageCount`,
    grid: `${PREFIX}-grid`,
    fabLanguageCount: `${PREFIX}-fabLanguageCount`,
};

const Root = styled('div')((
    {
        theme
    }
) => ({
    [`&.${classes.fabContainer}`]: {
        position: 'fixed',
        padding: theme.spacing(1),
        left: 0,
        bottom: 0,
        opacity: '50%',
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        '&>*': {
            marginLeft: theme.spacing(1)
        },
        '&:hover': {
            opacity: '100%',
        },
        zIndex: 50
    },
    [`&.${classes.fabMarginLeft}`]: {
        marginLeft: '600px',
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    [`& .${classes.fabLeftPageButton}`]: {
        borderBottomRightRadius: 0,
        borderTopRightRadius: 0,
    },
    [`& .${classes.fabRightPageButton}`]: {
        borderBottomLeftRadius: 0,
        borderTopLeftRadius: 0,
        marginLeft: "0 !important",
    },
    [`& .${classes.fabPageCount}`]: {
        borderRadius: 0,
        marginLeft: "0 !important",
    },
    [`& .${classes.grid}`]: {
            height: '200px'
    },
    [`& .${classes.fabLanguageCount}`]: {
        borderRadius: 0,
        marginLeft: "0 !important",
        width: "85px",
    },
}));


interface Props {
    toggleLeftOpenHandler: () => void,
    toggleTemplateListOpen: () => void,
    isLeftOpen: boolean,
    selectedViewMode: string
    selectViewMode: (viewMode: string) => void,
    createNewTemplate: () => void,
    createNewBlockHandler: (type: string) => void,
    deleteBlock: () => void,
    copyBlocks: () => void,
    pasteBlocks: () => void,
    sortUp: () => void,
    sortDown: () => void,
    selectedBlock: BlockData | undefined,
    headerSelected: boolean,
    blocks: BlockData[],
    clipboardEmpty: boolean,
    pageDataSet?: DynDataSet,
    handlePageCount: (nextIndex: number) => void,
    pageIndex: number,
}

export default function BlockAppBar(props: Props) {
    const {
        isLeftOpen,
        toggleLeftOpenHandler,
        toggleTemplateListOpen,
        selectedViewMode,
        selectedBlock,
        createNewTemplate,
        selectViewMode,
        createNewBlockHandler,
        deleteBlock,
        copyBlocks,
        pasteBlocks,
        sortUp,
        sortDown,
        blocks,
        clipboardEmpty,
        pageDataSet,
        handlePageCount,
        pageIndex,
    } = props;

    const [openDialog, SetOpenDialog] = React.useState(false);

    const languages = useSelector(selectLanguages);
    const translationContext = useContext(TranslationContext);


    const handleDialogOpen = () => {
        SetOpenDialog(prev => !prev);
    }

    const clickDialogHandler = (typeName: string) => {
        createNewBlockHandler(typeName);
        handleDialogOpen();
    }

    const handleLanguageCount = (nextIndex: number) => {
        translationContext.setSelectedLanguageIndex(nextIndex);
    }

    return (

        <Root className={clsx(classes.fabContainer, {
            [classes.fabMarginLeft]: isLeftOpen,
        })}>
            <Fab color="primary" onClick={toggleLeftOpenHandler} size="small">
                {isLeftOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </Fab>
            <Fab color="primary" size="small" onClick={handleDialogOpen}>
                <AddIcon />
            </Fab>
            <Fab color="primary" onClick={deleteBlock} size="small" disabled={!selectedBlock || CanBeDeleted(selectedBlock)}>
                <DeleteSharpIcon />
            </Fab>
            <Fab color="primary" onClick={copyBlocks} size="small" disabled={!selectedBlock || CanBeCopied(selectedBlock)}>
                <FileCopySharpIcon />
            </Fab>
            <Fab color="primary" onClick={pasteBlocks} size="small" disabled={(selectedBlock && CanBePasted(selectedBlock)) || clipboardEmpty}>
                <InsertDriveFileSharpIcon />
            </Fab>
            <Fab color="primary" onClick={sortUp} size="small" disabled={!selectedBlock || CanBeSorted(blocks, selectedBlock, -1)}>
                <ArrowUpwardSharpIcon />
            </Fab>
            <Fab color="primary" onClick={sortDown} size="small" disabled={!selectedBlock || CanBeSorted(blocks, selectedBlock, 1)}>
                <ArrowDownwardSharpIcon />
            </Fab>
            <Fab color="primary" onClick={() => selectViewMode(selectedViewMode === 'development' ? 'real' : 'development')} size="small">
                {selectedViewMode === 'development' ? <Devmode /> : <RealMode />}
            </Fab>
            <Fab color="primary" onClick={createNewTemplate} size="small" disabled={!selectedBlock || CanBeCopied(selectedBlock)}>
                <SaveTemplate />
            </Fab>
            <Fab color="primary" onClick={toggleTemplateListOpen} size="small" disabled={selectedBlock && CanBePasted(selectedBlock)}>
                <LoadTemplate />
            </Fab>
            {
                pageDataSet?.dynDataEntries?.length ? (
                    <>
                        <Fab className={classes.fabLeftPageButton} disabled={pageIndex <= 0} onClick={() => handlePageCount(-1)} color="primary" size="small">
                            <KeyboardDoubleArrowLeftIcon />
                        </Fab>
                        <Fab className={classes.fabPageCount} color="primary" size="small" disabled={true}>
                            {pageIndex}
                        </Fab>
                        <Fab className={classes.fabRightPageButton} disabled={pageIndex >= pageDataSet.dynDataEntries.length - 1} onClick={() => handlePageCount(1)} color="primary" size="small">
                            <KeyboardDoubleArrowRightIcon />
                        </Fab>
                    </>
                ) : null
            }
            {
                languages?.length ? (
                    <>
                        <Fab className={classes.fabLeftPageButton} disabled={translationContext.languageIndex <= 0} onClick={() => handleLanguageCount(-1)} color="primary" size="small">
                            <KeyboardDoubleArrowLeftIcon />
                        </Fab>
                        <Fab className={classes.fabLanguageCount} color="primary" size="small" disabled={true}>
                            {languages[translationContext.languageIndex].abbreviation}
                        </Fab>
                        <Fab className={classes.fabRightPageButton} disabled={translationContext.languageIndex >= languages.length - 1} onClick={() => handleLanguageCount(1)} color="primary" size="small">
                            <KeyboardDoubleArrowRightIcon />
                        </Fab>
                    </>
                ) : null
            }
            <Dialog onClose={handleDialogOpen} aria-labelledby="simple-dialog-title" open={openDialog}>
                <DialogTitle id="simple-dialog-title">Create Block</DialogTitle>
                <DialogContent dividers>
                    <Typography sx={{ mt: 2}} variant="h6" gutterBottom component="div">
                        Basic
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={4} >
                            <Button onClick={() => clickDialogHandler('base')} startIcon={<CropSquareSharpIcon />} fullWidth variant="contained">{'<div>'}</Button>
                        </Grid>
                        <Grid item xs={4}>
                            <Button onClick={() => clickDialogHandler('header')} startIcon={<FormatSizeSharpIcon />} fullWidth variant="contained">{'<header>'}</Button>
                        </Grid>
                        <Grid item xs={4}>
                            <Button onClick={() => clickDialogHandler('media')} startIcon={<PermMediaSharpIcon />} fullWidth variant="contained">{'<img>/<video>'}</Button>
                        </Grid>
                        <Grid item xs={4}>
                            <Button onClick={() => clickDialogHandler('link')} startIcon={<InsertLinkSharpIcon />} fullWidth variant="contained">{'<link>'}</Button>
                        </Grid>
                        <Grid item xs={4}>
                            <Button onClick={() => clickDialogHandler('text')} startIcon={<FormatAlignJustifySharpIcon />} fullWidth variant="contained">{'<p>'}</Button>
                        </Grid>
                        <Grid item xs={4}>
                            <Button onClick={() => clickDialogHandler('section')} startIcon={<BorderHorizontalSharpIcon />} fullWidth variant="contained">{'<section>'}</Button>
                        </Grid>
                        <Grid item xs={4}>
                            <Button onClick={() => clickDialogHandler('iframe')} startIcon={<FilterFramesSharpIcon />} fullWidth variant="contained">{'<iframe>'}</Button>
                        </Grid>
                    </Grid>
                    <Typography sx={{ mt: 2}} variant="h6" gutterBottom component="div">
                        Layout
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={4} >
                            <Button onClick={() => clickDialogHandler('container')} startIcon={<CropSquareSharpIcon />} fullWidth variant="contained">{'<container>'}</Button>
                        </Grid>
                        <Grid item xs={4}>
                            <Button onClick={() => clickDialogHandler('row')} startIcon={<CalendarViewMonthSharpIcon />} fullWidth variant="contained">{'<row>'}</Button>
                        </Grid>
                        <Grid item xs={4}>
                            <Button onClick={() => clickDialogHandler('col')} startIcon={<ViewColumnSharpIcon />} fullWidth variant="contained">{'<col>'}</Button>
                        </Grid>
                    </Grid>
                    <Typography sx={{ mt: 2}} variant="h6" gutterBottom component="div">
                        Form
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={4} >
                            <Button onClick={() => clickDialogHandler('form')} startIcon={<DashboardSharpIcon />} fullWidth variant="contained">{'<form>'}</Button>
                        </Grid>
                        <Grid item xs={4}>
                            <Button onClick={() => clickDialogHandler('input')} startIcon={<FormatSizeSharpIcon />} fullWidth variant="contained">{'<input>'}</Button>
                        </Grid>
                    </Grid>
                    <Typography sx={{ mt: 2}} variant="h6" gutterBottom component="div">
                        Special
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={4} >
                            <Button onClick={() => clickDialogHandler('custom')} startIcon={<DashboardCustomizeSharpIcon />} fullWidth variant="contained">{'<custom>'}</Button>
                        </Grid>
                    </Grid>
                    <Typography sx={{ mt: 2}} variant="h6" gutterBottom component="div">
                        Special
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={4} >
                            <Button onClick={() => clickDialogHandler('slider')} startIcon={<ViewCarouselSharpIcon />} fullWidth variant="contained">{'<slider>'}</Button>
                        </Grid>
                        <Grid item xs={4} >
                            <Button onClick={() => clickDialogHandler('beforeafter')} startIcon={<HourglassTopSharpIcon />} fullWidth variant="contained">{'<beforeafter>'}</Button>
                        </Grid>
                        <Grid item xs={4} >
                            <Button onClick={() => clickDialogHandler('countup')} startIcon={<BorderVerticalSharpIcon />} fullWidth variant="contained">{'<countup>'}</Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </Root>
    );
}