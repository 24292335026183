import React, { ChangeEvent, useState } from 'react';
import { IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { DynModel, DynProps } from '../../store/DynData';
import DynPropControlRow from './DynPropControlRow';

interface Props {
    handlePropTagChange: (value: string, id: number) => void,
    handlePropTypeChange: (value: string, id: number) => void,
    handleDeleteProps: (id: number) => void,
    model: DynModel,
}

const DynPropControl = (props: Props) => {
    const { handlePropTagChange, handleDeleteProps, handlePropTypeChange, model } = props;

    const inputs = model?.dynPropSets
        ?.sort((a: DynProps, b: DynProps) => a.id - b.id)
        .map((attr) => (
            <DynPropControlRow
                key={attr.id}
                handlePropTagChange={handlePropTagChange}
                handlePropTypeChange={handlePropTypeChange}
                handleDeleteProps={handleDeleteProps}
                attr={attr} />
        ));

    return (
        <TableContainer component={Paper}>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Attribute</TableCell>
                        <TableCell>Value</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {inputs}
                </TableBody>
            </Table>
        </TableContainer >
    );
}

export default DynPropControl;