import { connect } from 'react-redux';
import { styled } from '@mui/material/styles';
import React, { useState } from 'react';
import { ApplicationState } from '../../../store';
import * as MediaStore from '../../../store/Media';
import { Card, CardActionArea, DialogTitle, Grid, IconButton, InputBase, Paper, Toolbar, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import { AutorenewTwoTone } from '@mui/icons-material';

const PREFIX = 'SelectMediaFiles';

const classes = {
    container: `${PREFIX}-container`,
    root: `${PREFIX}-root`,
    title: `${PREFIX}-title`,
    mediaCardImageBox: `${PREFIX}-mediaCardImageBox`,
    mediaCardImage: `${PREFIX}-mediaCardImage`,
    search: `${PREFIX}-search`,
    searchIcon: `${PREFIX}-searchIcon`,
    inputRoot: `${PREFIX}-inputRoot`,
    inputInput: `${PREFIX}-inputInput`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
    {
        theme
    }
) => ({
    [`& .${classes.container}`]: {          
        maxHeight: '600px',
        height: '600px',
        overflowY: 'scroll',  
        '& > *': {
            padding: theme.spacing(1),
        },
    },

    [`& .${classes.root}`]: {
        '& > *': {
            padding: theme.spacing(1),
        },
    },

    [`& .${classes.title}`]: {
      flexGrow: 1,
      display: 'none',
      [theme.breakpoints.up('sm')]: {
        display: 'block',
      },
    },

    [`& .${classes.mediaCardImageBox}`]: {
        height: '264px',
        width: '100%',
    },

    [`& .${classes.mediaCardImage}`]: {
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundColor: theme.palette.primary.main,
        margin: 'auto',
        height: '264px',
    },

    [`& .${classes.search}`]: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: '#ffffff87',
        '&:hover': {
            backgroundColor: '#ffffff29',
        },
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(1),
            width: 'auto',
        },
    },

    [`& .${classes.searchIcon}`]: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },

    [`& .${classes.inputRoot}`]: {
        color: 'inherit',
    },

    [`& .${classes.inputInput}`]: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch',
            },
        },
    }
}));

const SelectMediaFiles = (props: any) => {


    const [filter, setFilter] = useState("");

    let files: MediaStore.MediaFile[] = props.files;

    if(filter != "")
        files = files.filter(x => x.name.indexOf(filter) > -1);

    const blocks = files
        .sort((a: MediaStore.MediaFile, b: MediaStore.MediaFile) => {
            if (a.name > b.name) return 1;
            if (a.name < b.name) return -1;

            return 0;
        })
        .map((file: MediaStore.MediaFile) => {
            return (
                <Grid key={file.id} xs={6} item>
                    <Card className={classes.root}>
                        <CardActionArea onClick={() => props.setMediaFile(file)}>
                            <Typography gutterBottom variant="h5" component="h2">
                                {file.name}
                            </Typography>
                            {
                                file.extension === ".mp4" ?
                                    <video muted autoPlay className="media-card-video">
                                        <source src={file.path} type="video/mp4" />
                                    </video>
                                    : <div className={classes.mediaCardImageBox}>
                                        <div className={classes.mediaCardImage} style={{ backgroundImage: `url(${file.path})` }} />
                                    </div>
                            }
                        </CardActionArea>
                    </Card>
                </Grid>
            )
        });

    React.useEffect(() => {
        props.getFiles();
    }, [])

    return (
        (<Root>
            <Toolbar>
                <Typography className={classes.title} variant="h6" noWrap>
                    Media
                </Typography>
                <div className={classes.search}>
                    <div className={classes.searchIcon}>
                        <SearchIcon />
                    </div>
                    <InputBase
                        placeholder="Search…"
                        classes={{
                            root: classes.inputRoot,
                            input: classes.inputInput,
                        }}
                        inputProps={{ 'aria-label': 'search' }}
                        onChange={(e) => setFilter(e.target.value)}
                    />
                </div>
            </Toolbar>
            <Grid container className={classes.container}>
                {blocks}
            </Grid>
        </Root>)
    );
}

interface Props {
    setMediaFile: ((file: MediaStore.MediaFile) => void)
}

export default connect(
    (state: ApplicationState, ownProps: Props) => state.media, // Selects which state properties are merged into the component's prop
    MediaStore.actionCreators // Selects which action creators are merged into the component's props
)(SelectMediaFiles as any);
