import React, { ReactNode } from 'react';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import { styled, Typography } from '@mui/material';

const drawerWidth = 600;

const PREFIX = 'BlockMapDrawer';

const classes = {
    drawer: `${PREFIX}-drawer`,
    drawerPaper: `${PREFIX}-drawerPaper`,
    drawerHeader: `${PREFIX}-drawerHeader`,
    close: `${PREFIX}-close`,
};

const Root = styled(Drawer)((
    {
        theme
    }
) => ({
    [`&.${classes.drawer}`]: {
        width: drawerWidth,
        flexShrink: 0,
        [`& > div`]: {
            width: drawerWidth,
            height: `100vh`
        },
    },
    [`& .${classes.drawerHeader}`]: {
        display: 'flex',
        padding: theme.spacing(2, 1),
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        justifyContent: 'space-between',
    },
    [`& .${classes.close}`]: {
        position: 'fixed',
        zIndex: 9999,
        top: '50%',
        marginRight: '600px',
        backgroundColor: theme.palette.background.paper,
    },
}));

interface Props {
    isOpen: boolean,
    children: ReactNode
}

export default function BlockMapDrawer(props: Props) {
    const { isOpen, children } = props;

    return (
        <Root
            className={classes.drawer}
            variant="persistent"
            anchor="left"
            open={isOpen}
        >
            <div className={classes.drawerHeader}>
                <Typography variant="h6">
                    Bloxxi
                </Typography>
            </div>
            <Divider />
            {children}
        </Root>
    );
}
