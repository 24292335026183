import React, { ChangeEvent, useState } from 'react';
import { FormControl, IconButton, MenuItem, Paper, Select, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { DynPropAssignment, DynProps } from '../../store/DynData';
import RemoveIcon from '@mui/icons-material/Remove';

interface Props {
    handleDynPropChange: (value: string, id: number) => void,
    handleBlockPropChange: (value: string, id: number) => void,
    handleDeletePropAssignment: (id: number) => void,
    propAssignment: DynPropAssignment,
    dynProps: DynProps[],
    selectedBlockType: string,
}

const PREFIX = 'PropAssignmentControl';

const classes = {
    button: `${PREFIX}-button`,
    removeIcon: `${PREFIX}-removeIcon`,
    removeCell: `${PREFIX}-removeCell`,
};

const Root = styled(TableRow)((
    {
        theme
    }
) => ({
    [`& .${classes.button}`]: {
        padding: 0,
    },
    [`& .${classes.removeIcon}`]: {
        fontSize: '18px',
    },
    [`& .${classes.removeCell}`]: {
        width: '12px',
    },
}));
const PropAssignmentControl = (props: Props) => {
    const { handleDynPropChange, handleBlockPropChange, handleDeletePropAssignment, propAssignment, dynProps, selectedBlockType } = props;

    return (
        <Root>
            <TableCell>
                <FormControl>
                    <Select
                        value={propAssignment.dynProp?.tag}
                        onChange={(e: any) => handleDynPropChange(e.target.value as string, propAssignment.id)}
                        variant="standard"
                        fullWidth
                    >
                        {
                            dynProps.map((prop) => (
                                <MenuItem key={prop.id} value={prop.tag}>{prop.tag}</MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
            </TableCell>
            <TableCell>
                <FormControl>
                    <Select
                        value={propAssignment.blockProp}
                        onChange={(e: any) => handleBlockPropChange(e.target.value as string, propAssignment.id)}
                        variant="standard"
                        fullWidth
                    >
                        {
                            selectedBlockType === 'text'
                                || selectedBlockType === 'header'
                                || selectedBlockType === 'link' ?
                                (
                                    <MenuItem value="content">content</MenuItem>
                                ) : null

                        }

                        {
                            selectedBlockType === 'media' ?
                                (
                                    <>
                                        <MenuItem value="media-file">media-file</MenuItem>
                                        <MenuItem value="alt-text">alt-text</MenuItem>
                                    </>
                                ) : null

                        }

                        {
                            selectedBlockType === 'link' ?
                                (
                                    <>
                                    <MenuItem value="link">link</MenuItem>
                                    <MenuItem value="anchor">anchor</MenuItem>
                                    </>
                                ) : null

                        }
                    </Select>
                </FormControl>
            </TableCell>
            <TableCell className={classes.removeCell}>
                <IconButton className={classes.button} edge="end" aria-label="delete" color="primary" size="small" onClick={() => handleDeletePropAssignment(propAssignment.id)}>
                    <RemoveIcon className={classes.removeIcon} />
                </IconButton>
            </TableCell>
        </Root>
    );
}

export default PropAssignmentControl;