
import * as Auth from './Auth';
import * as Media from './Media';
import * as Block from './Block';
import * as Page from './Page';
import * as Template from './Template';
import * as DynData from './DynData';
import * as Config from './Configuration';
import * as Language from './Language';

// The top-level state object
export interface ApplicationState {
    auth: Auth.AuthState | undefined;
    media: Media.MediaState | undefined;
    block: Block.BlockState | undefined;
    page: Page.PageState | undefined;
    template: Template.TemplateState | undefined;
    dynData: DynData.DynDataState | undefined;
    config: Config.ConfigState | undefined;
    language: Language.LanguageState | undefined;
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
    auth: Auth.reducer,
    media: Media.reducer,
    block: Block.reducer,
    page: Page.reducer,
    template: Template.reducer,
    dynData: DynData.reducer,
    config: Config.reducer,
    language: Language.reducer
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
    (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}
