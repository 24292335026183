import * as React from 'react';
import { styled } from '@mui/material/styles';
import './NavMenu.css';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Link as RouterLink } from 'react-router-dom';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Paper } from '@mui/material';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import WidgetsIcon from '@mui/icons-material/Widgets';
import ImageIcon from '@mui/icons-material/Image';
import { useLocation } from 'react-router-dom';
import ArchitectureIcon from '@mui/icons-material/Architecture';
import StorageIcon from '@mui/icons-material/Storage';
import SettingsIcon from '@mui/icons-material/Settings';
import TranslateSharpIcon from '@mui/icons-material/TranslateSharp';
import { useSelector } from 'react-redux';
import { selectLanguages } from '../store/selectors/ConfigSelector';

const PREFIX = 'NavMenu';

const classes = {
    root: `${PREFIX}-test`,
    buttonText: `${PREFIX}-buttonText`,
    title: `${PREFIX}-title`,
    drawer: `${PREFIX}-drawer`,
    drawerPaper: `${PREFIX}-drawerPaper`,
    box: `${PREFIX}-box`
};

const Root = styled('header')((
    {
        theme
    }
) => ({
    [`&.${classes.root}`]: {
        display: 'flex',
    },

    [`& .${classes.buttonText}`]: {
        '&:hover': {
            textDecoration: 'underline',
            color: 'inherit',
        }
    },

    [`& .${classes.title}`]: {
        flexGrow: 1,
    },

    [`& .${classes.drawer}`]: {
        width: drawerWidth,
        flexShrink: 0,
    },

    [`& .${classes.drawerPaper}`]: {
        width: drawerWidth,
    },

    [`& .${classes.box}`]: {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
    }
}));

const drawerWidth = 240;

const NavMenu = () => {
    const location = useLocation();
    const languages = useSelector(selectLanguages)

    return location.pathname.endsWith("builder") ? null :
        <Root className={classes.root}>

            <Drawer
                className={classes.drawer}
                variant="permanent"
                classes={{
                    paper: classes.drawerPaper,
                }}
                anchor="left"
            >
                <AppBar position="static">
                    <Toolbar>
                        <Typography variant="h6" className={classes.title}>
                            Bloxxi
                        </Typography>
                    </Toolbar>
                </AppBar>
                <List component="nav">
                    <ListItem button component={RouterLink} to="/">
                        <ListItemIcon>
                            <MenuBookIcon />
                        </ListItemIcon>
                        <ListItemText primary="Pages" />
                    </ListItem>
                    <ListItem button component={RouterLink} to="/templates">
                        <ListItemIcon>
                            <WidgetsIcon />
                        </ListItemIcon>
                        <ListItemText primary="Templates" />
                    </ListItem>
                    <ListItem button component={RouterLink} to="/documents">
                        <ListItemIcon>
                            <ImageIcon />
                        </ListItemIcon>
                        <ListItemText primary="Media" />
                    </ListItem>
                    <ListItem button component={RouterLink} to="/models">
                        <ListItemIcon>
                            <ArchitectureIcon />
                        </ListItemIcon>
                        <ListItemText primary="Models" />
                    </ListItem>
                    <ListItem button component={RouterLink} to="/datasets">
                        <ListItemIcon>
                            <StorageIcon />
                        </ListItemIcon>
                        <ListItemText primary="Data" />
                    </ListItem>
                    <ListItem button component={RouterLink} disabled={languages?.length < 1} to="/translations">
                        <ListItemIcon>
                            <TranslateSharpIcon />
                        </ListItemIcon>
                        <ListItemText primary="Translations" />
                    </ListItem>
                    <ListItem button component={RouterLink} to="/configuration">
                        <ListItemIcon>
                            <SettingsIcon />
                        </ListItemIcon>
                        <ListItemText primary="Configuration" />
                    </ListItem>
                </List>
        </Drawer>
    </Root >;
}

export default NavMenu;