import { TableContainer, Paper, TableHead, TableRow, TableCell, TableBody, IconButton, Table } from '@mui/material';
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import DeleteSharpIcon from '@mui/icons-material/DeleteSharp';
import EditIcon from '@mui/icons-material/Edit';
import { Language } from '../../store/Language';
import { actionCreators } from '../../store/Configuration';
import CheckIcon from '@mui/icons-material/Check';

interface Props {
    languages: Language[],
    editLanguage: (id: number) => void
}

const LanguageTable = (props: Props) => {
    const { languages, editLanguage } = props;
    const dispatch = useDispatch();

    const deleteLanguage = (id: number) => {
        dispatch(actionCreators.deleteLanguage(id));
    }

    return (
        <>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Abbreviation</TableCell>
                            <TableCell>Default</TableCell>
                            <TableCell sx={{ width: '85px' }}>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {languages?.map((row) => (
                            <TableRow
                                key={row.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell>{row.name}</TableCell>
                                <TableCell>{row.abbreviation}</TableCell>
                                <TableCell align='center'>{row.defaultLanguage ? <CheckIcon /> : null}</TableCell>
                                <TableCell>
                                    <IconButton size="small" onClick={() => editLanguage(row.id)}>
                                        <EditIcon />
                                    </IconButton>
                                    <IconButton size="small" onClick={() => deleteLanguage(row.id)}>
                                        <DeleteSharpIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

export default LanguageTable;