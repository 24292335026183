import React, { ChangeEvent, useState } from 'react';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { BlockStyle, BlockStyleData } from '../../store/Block';
import BlockStyleControlRow from './BlockStyleControlRow';
import { IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

interface Props {
    handleAttributeKeyChange: (value: string, id: number) => void,
    handleAttributeValueChange: (value: string, id: number) => void,
    handleDeleteStyleAttribute: (id: number) => void,
    handleNewStyleAttribute: () => void,
    blockStyle: BlockStyle,
}

const BlockStyleControl = (props: Props) => {
    const { handleAttributeKeyChange, handleAttributeValueChange, handleNewStyleAttribute, handleDeleteStyleAttribute, blockStyle } = props;

    const AttributeKeyChangeHandler = (value: string, id: number) => {
        handleAttributeKeyChange(value, id);
    }
    const AttributeValueChangeHandler = (e: ChangeEvent<HTMLInputElement>, id: number) => {
        handleAttributeValueChange(e.target.value, id);
    }

    const inputs = blockStyle?.blockStyleData
        ?.sort((a: BlockStyleData, b: BlockStyleData) => a.id - b.id)
        .map((attr) => (
            <BlockStyleControlRow
                key={attr.id}
                handleAttributeKeyChange={AttributeKeyChangeHandler}
                handleAttributeValueChange={AttributeValueChangeHandler}
                handleDeleteStyleAttribute={handleDeleteStyleAttribute}
                attr={attr} />
        ));

    return (
        <TableContainer component={Paper}>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Attribute</TableCell>
                        <TableCell>Value</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {inputs}
                </TableBody>
            </Table>
        </TableContainer >
    );
}

export default BlockStyleControl;