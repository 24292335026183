import React, { ReactNode } from 'react';
import clsx from 'clsx';
import { styled } from '@mui/material/styles';

const PREFIX = 'BlockBuilderContent';

const classes = {
    content: `${PREFIX}-content`,
    contentLeft: `${PREFIX}-contentLeft`,
    contentLeftShift: `${PREFIX}-contentLeftShift`
};

const Root = styled('div')((
        {theme}
) => ({
    [`&.${classes.content}`]: {            
        flexGrow: 1,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },

    [`&.${classes.contentLeft}`]: {
        marginLeft: -drawerWidth,
    },

    [`&.${classes.contentLeftShift}`]: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    }
}));

interface Props {
    isLeftOpen: boolean,
    children: ReactNode
}

const drawerWidth = 600;


export default function BlockBuilderContent(props: Props) {
    const { isLeftOpen, children } = props;

    return (
        <Root
            className={clsx(classes.content,{
                [classes.contentLeft]: !isLeftOpen,
                [classes.contentLeftShift]: isLeftOpen,
            })}
        >
                {children}
        </Root>
    );
}
