import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function LoadTemplate(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <g>
                <polygon points="22.1,4.8 1.9,4.8 12,19.1 	" />
                <path d="M21.9,6.8l-0.6,0.8c0,0.1,0.1,0.2,0.1,0.3v2c0,0.3-0.3,0.6-0.6,0.6h-1.5l-0.7,0.9h2.1c0.8,0,1.5-0.7,1.5-1.5v-2
		C22.3,7.4,22.1,7.1,21.9,6.8z"/>
                <path d="M2.7,7.6L2.1,6.8C1.9,7.1,1.7,7.4,1.7,7.8v2c0,0.8,0.7,1.5,1.5,1.5h2.1l-0.7-0.9H3.2c-0.3,0-0.6-0.3-0.6-0.6v-2
		C2.6,7.8,2.7,7.7,2.7,7.6z"/>
                <path d="M1.7,16.2c0,0.8,0.7,1.5,1.5,1.5h6.6l-0.7-0.9H3.2c-0.3,0-0.6-0.3-0.6-0.6v-2c0-0.3,0.3-0.6,0.6-0.6h3.7l-0.7-0.9h-3
		c-0.8,0-1.5,0.7-1.5,1.5V16.2z"/>
                <path d="M20.8,12.6h-3l-0.7,0.9h3.7c0.3,0,0.6,0.3,0.6,0.6v2c0,0.3-0.3,0.6-0.6,0.6h-5.9l-0.7,0.9h6.6c0.8,0,1.5-0.7,1.5-1.5v-2
		C22.3,13.3,21.6,12.6,20.8,12.6z"/>
                <path d="M20.8,18.9h-7.5l-0.7,0.9h8.1c0.3,0,0.6,0.3,0.6,0.6v2c0,0.3-0.3,0.6-0.6,0.6H3.2c-0.3,0-0.6-0.3-0.6-0.6v-2
		c0-0.3,0.3-0.6,0.6-0.6h8.1l-0.7-0.9H3.2c-0.8,0-1.5,0.7-1.5,1.5v2c0,0.8,0.7,1.5,1.5,1.5h17.5c0.8,0,1.5-0.7,1.5-1.5v-2
		C22.3,19.6,21.6,18.9,20.8,18.9z"/>
                <path d="M2.6,1.5c0-0.3,0.3-0.6,0.6-0.6h17.5c0.3,0,0.6,0.3,0.6,0.6v1.9h0.9V1.5c0-0.8-0.7-1.5-1.5-1.5H3.2C2.4,0,1.7,0.7,1.7,1.5
		v1.9h0.9V1.5z"/>
            </g>
        </SvgIcon>
    );
}