import { BlockEvent, EventTriggerType, EventType } from "../../store/Block";

export interface TriggerSource {
  id: number,
  height?: number,
}

export class Signal {
  private handlers: Array<(a: string[]) => void> = [];
  private triggerSources: TriggerSource[];

  BlockEvent: BlockEvent;

  constructor(newEvent: BlockEvent) {
    this.BlockEvent = newEvent;
    this.triggerSources = [];
  }

  public on(handler: (triggers: string[]) => void): void {
    this.handlers.push(handler);
  }

  public off(handler: (triggers: string[]) => void): void {
    this.handlers = this.handlers.filter(h => h !== handler);
  }

  public trigger(eventTriggerType: EventTriggerType): void {
    const eventClasses = this.getEventClasses(eventTriggerType);
    this.handlers.slice(0).forEach(h => h(eventClasses));
  }

  public unTrigger(): void {
    this.handlers.slice(0).forEach(h => h([]));
  }

  private getEventClasses(eventTriggerType: EventTriggerType): string[] {
    let eventClasses: string[] = [];
    let triggers = this.triggerSources;

    if (eventTriggerType === EventTriggerType.OnScroll.valueOf() && this.BlockEvent.eventType === EventType.Multiple.valueOf() && this.BlockEvent.propagateSource) {
      const highest = Math.max.apply(null, this.triggerSources.map(x => x?.height ?? 0));
      triggers = this.triggerSources.filter(x => (x?.height ?? 0) >= highest);
    }

    if (this.BlockEvent.propagateSource) {
      triggers.forEach(x => eventClasses.push(this.BlockEvent.eventName + '-' + x.id.toString()));
    } else if(triggers.length) {
      eventClasses.push(this.BlockEvent.eventName);
    }

    return eventClasses;
  }

  public isTriggered(sourceId: number): boolean {
    if (this.BlockEvent.eventType === EventType.Multiple || this.BlockEvent.propagateSource)
      return this.triggerSources.map(x => x.id).includes(sourceId);

    return this.triggerSources.length > 0;
  }

  public wouldBeUntriggered(): boolean {
    return this.triggerSources.length > 0;
  }

  public handleTriggerSources(sourceId: number, height?: number, empty: boolean = false): void {
    if (empty) {
      this.triggerSources = [];
      return;
    }

    switch (this.BlockEvent.eventType) {
      case EventType.Single.valueOf(): {
        if (this.BlockEvent.propagateSource) {
          if (this.triggerSources.map(x => x.id).includes(sourceId)) {
            this.triggerSources = this.triggerSources.filter(x => x.id != sourceId);
          } else {
            this.triggerSources = [{ id: sourceId, height }];
          }
        } else if (!this.triggerSources.length) {
          this.triggerSources.push({ id: sourceId, height });
        } else {
          this.triggerSources = [];
        }
        break;
      }
      case EventType.Multiple.valueOf(): {
        if (this.triggerSources.map(x => x.id).includes(sourceId)) {
          this.triggerSources = this.triggerSources.filter(x => x.id != sourceId);
        } else {
          this.triggerSources.push({ id: sourceId, height });
        }
        break;
      }
    }
  }
}