import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { styled } from '@mui/material';
import { MediaFile } from '../../../store/Media';
import useDeepEffect from '../../../hooks/useDeepEffect';

const PREFIX = 'FolderDialog';

const classes = {
    formControl: `${PREFIX}-formControl`,
    dialogContent: `${PREFIX}-dialogContent`,
    addButton: `${PREFIX}-addButton`
};

const StyledDialog = styled(Dialog)((
    {
        theme
    }
) => ({
    [`& .${classes.formControl}`]: {
        width: '100%',
    },
    [`& .${classes.dialogContent}`]: {
        '& > *': {
            marginBottom: theme.spacing(1)
        },
    },
    [`& .${classes.addButton}`]: {
        width: '20px',
        minWidth: '20px',
        height: 'inherit'
    }
}));

interface Props {
    dialogOpen: boolean,
    closeDialog: () => void,
    submitDialog: (folder: MediaFile) => void,
    currentMediaFile: MediaFile | undefined,
}

export default function MediaDialog(props: Props) {
    const { dialogOpen, closeDialog, submitDialog, currentMediaFile } = props;

    const defaultMediaFile: MediaFile = { id: 0, extension: 'folder', path: '', name: '', size: '' };
    const [mediaFile, setMediaFile] = React.useState<MediaFile>(defaultMediaFile);

    useDeepEffect(() => {
        if (currentMediaFile === undefined) {
            setMediaFile(defaultMediaFile);
        } else {
            setMediaFile(currentMediaFile);
        }

    }, [currentMediaFile]);

    const handleOnClose = () => {
        setMediaFile(defaultMediaFile);
        closeDialog();
    }

    const handleOnSubmit = () => {
        submitDialog(mediaFile);
        handleOnClose();
    }

    return (
        <StyledDialog open={dialogOpen} fullWidth maxWidth="sm" onClose={closeDialog}>
            <DialogTitle>Folder</DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <TextField label="Name" placeholder="name" type="text" value={mediaFile.name} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setMediaFile({...mediaFile, name: e.target.value})} />     
            </DialogContent>
            <DialogActions>
                <Button onClick={handleOnClose}>Cancel</Button>
                <Button onClick={handleOnSubmit}>Save</Button>
            </DialogActions>
        </StyledDialog>
    );
}