import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button, Checkbox, FormControlLabel } from '@mui/material';
import React, { ChangeEvent } from 'react'
import { Language } from '../../store/Language';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

interface Props {
    open: boolean,
    handleClose: () => void,
    handleSubmit: (language: Language) => void,
    language: Language | undefined,
}

const LanguageDialog = (props: Props) => {

    const defaultLanguage: Language = { id: 0, name: '', abbreviation: '', defaultLanguage: false }
    const { open, handleClose, language, handleSubmit } = props;
    const [currentLanguage, SetCurrentLanguage] = React.useState(defaultLanguage);

    React.useEffect(() => {
        if (language)
            SetCurrentLanguage(language);
        else
            SetCurrentLanguage(defaultLanguage);
    }, [language])

    const handleOnSubmit = () => {
        handleSubmit(currentLanguage);
        handleOnClose();
    }

    const handleOnClose = () => {
        SetCurrentLanguage(defaultLanguage);
        handleClose();
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
        >
            <DialogTitle>
                Language
            </DialogTitle>
            <DialogContent sx={{
                        '& > :not(style)': { m: 1, width: '25ch' },
                    }}>
                <TextField label="Name" placeholder="Deutsch" value={currentLanguage.name} onChange={(e) => SetCurrentLanguage({ ...currentLanguage, name: e.target.value })} />
                <TextField label="Abbreviation" placeholder="de" value={currentLanguage.abbreviation} onChange={(e) => SetCurrentLanguage({ ...currentLanguage, abbreviation: e.target.value })} />
                
                <FormControlLabel
                            value="start"
                            control={<Checkbox icon={<CheckBoxOutlineBlankIcon fontSize="small" />} checkedIcon={<CheckBoxIcon fontSize="small" />} color="primary" checked={currentLanguage.defaultLanguage} onChange={(e, checked) => SetCurrentLanguage({ ...currentLanguage, defaultLanguage: checked})} />}
                            label="Default"
                            labelPlacement="start"
                        />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>cancel</Button>
                <Button onClick={handleOnSubmit} autoFocus>
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default LanguageDialog;