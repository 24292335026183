import { Box, TextField, FormControl, InputLabel, Select, MenuItem, Button, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useState } from 'react';
import { BlockStyle } from '../../../store/Block';
import useDeepEffect from '../../../hooks/useDeepEffect';
import BlockStyleControl from '../BlockStyleControl';
import AddIcon from '@mui/icons-material/Add';

const PREFIX = 'BlockStyleDialog';

const classes = {
    formControl: `${PREFIX}-formControl`,
    dialogContent: `${PREFIX}-dialogContent`,
    addButton: `${PREFIX}-addButton`
};

const StyledDialog = styled(Dialog)((
    {
        theme
    }
) => ({
    [`& .${classes.formControl}`]: {
        width: '100%',
    },
    [`& .${classes.dialogContent}`]: {
        '& > *': {
            marginBottom: theme.spacing(1)
        },
    },
    [`& .${classes.addButton}`]: {
        width: '20px',
        minWidth: '20px',
        height: 'inherit'
    }
}));

interface Props {
    blockStyle: BlockStyle | undefined,
    dialogOpenHandler: () => void;
    submitHandler: (blockStyle: BlockStyle) => void;
    isOpen: boolean;
}

const BlockStyleDialog = (props: Props) => {

    const defaultBlockStyle: BlockStyle = { id: 0, blockStyleData: [], screenMode: 'xs', selector: '' };
    const {
        blockStyle,
        isOpen,
        dialogOpenHandler,
        submitHandler
    } = props;

    const [currentBlockStyle, setCurrentBlockStyle] = useState<BlockStyle>(defaultBlockStyle);

    useDeepEffect(() => {
        if (blockStyle === undefined) {
            setCurrentBlockStyle(defaultBlockStyle);
        } else {
            setCurrentBlockStyle(blockStyle);
        }

    }, [blockStyle]);

    const handleOnClose = () => {
        setCurrentBlockStyle(defaultBlockStyle);
        dialogOpenHandler();
    }

    const handleOnSubmit = () => {
        submitHandler(currentBlockStyle);
        handleOnClose();
    }

    const handleAttributeKeyChange = (value: string, id: number) => {
        let newStyle: BlockStyle = { ...currentBlockStyle };
        const newStyleData = newStyle.blockStyleData.find(x => x.id === id);
        if (newStyleData) {
            newStyleData.attributeKey = value;
            newStyle.blockStyleData = [...newStyle.blockStyleData.filter(x => x.id !== newStyleData.id), newStyleData]
            setCurrentBlockStyle(newStyle);
        }
    }

    const handleAttributeValueChange = (value: string, id: number) => {
        let newStyle: BlockStyle = { ...currentBlockStyle };
        const newStyleData = newStyle.blockStyleData.find(x => x.id === id);
        if (newStyleData) {
            newStyleData.attributeValue = value;
            newStyle.blockStyleData = [...newStyle.blockStyleData.filter(x => x.id !== newStyleData.id), newStyleData]
            setCurrentBlockStyle(newStyle);
        }
    }

    const handleNewStyleAttribute = () => {
        let newId = 999 + Math.random().toString().substr(2, 5);
        let newStyle = { ...currentBlockStyle };
        newStyle.blockStyleData.push({ id: Number(newId), attributeKey: '', attributeValue: '' });
        setCurrentBlockStyle(newStyle);
    }

    const handleDeleteStyleAttribute = (id: number) => {
        let newStyle = { ...currentBlockStyle };
        let style = [...newStyle.blockStyleData];
        newStyle.blockStyleData = style.filter(x => x.id !== id);
        setCurrentBlockStyle(newStyle);
    }

    const handleSelectorChange = (selector: string) => {
        let newStyle = { ...currentBlockStyle };
        newStyle.selector = selector;
        setCurrentBlockStyle(newStyle);
    }

    const handleDisplaySizeChange = (displaySize: string) => {
        let newStyle = { ...currentBlockStyle };
        newStyle.screenMode = displaySize;
        setCurrentBlockStyle(newStyle);
    }

    return (
        <StyledDialog
            fullWidth
            maxWidth="md"
            open={isOpen}
            onClose={handleOnClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{"Styles"}</DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <DialogContentText>
                    Leave name empty to use as base style.
                </DialogContentText>
                <TextField
                    autoFocus
                    id="name"
                    label="Selector name"
                    type="text"
                    value={currentBlockStyle.selector}
                    onChange={(e) => handleSelectorChange(e.target.value)}
                    fullWidth
                />
                <FormControl className={classes.formControl}>
                    <InputLabel id="size-xs">xs</InputLabel>
                    <Select
                        labelId="size-xs"
                        value={currentBlockStyle.screenMode}
                        onChange={(e) => handleDisplaySizeChange(e.target.value as string)}
                    >
                        <MenuItem value={"xs"}>xs</MenuItem>
                        <MenuItem value={"sm"}>sm</MenuItem>
                        <MenuItem value={"md"}>md</MenuItem>
                        <MenuItem value={"lg"}>lg</MenuItem>
                        <MenuItem value={"xl"}>xl</MenuItem>
                    </Select>
                </FormControl>
                <Stack direction="row" spacing={2}>
                    <Button className={classes.addButton} variant="contained" onClick={handleNewStyleAttribute}>
                        <AddIcon />
                    </Button>
                    <BlockStyleControl
                        key={currentBlockStyle.id}
                        handleAttributeKeyChange={handleAttributeKeyChange}
                        handleAttributeValueChange={handleAttributeValueChange}
                        handleNewStyleAttribute={handleNewStyleAttribute}
                        handleDeleteStyleAttribute={handleDeleteStyleAttribute}
                        blockStyle={currentBlockStyle}
                        />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={handleOnClose}>
                    Cancel
                </Button>
                <Button color="primary" onClick={handleOnSubmit}>
                    Save
                </Button>
            </DialogActions>
        </StyledDialog>
    );
}

export default BlockStyleDialog;