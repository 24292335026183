import { ApplicationState } from "..";

export const selectBlocks = (rootState: ApplicationState) => {
    return rootState.block?.blocks ?? [];
}

export const selectSelectedBlock = (rootState: ApplicationState) => {
    return rootState.block!.selectedBlock;
}

export const selectSelectedPropAssignments = (rootState: ApplicationState) => {
    return rootState.block?.selectedBlock?.dynPropAssignments ?? [];
}

export const selectBlockClipboard = (rootState: ApplicationState) => {
    return rootState.block!.blockClipboard;
}

export const selectGlobalBlockStyles = (rootState: ApplicationState) => {
    return rootState.block!.globalStyles;
}