import { TableContainer, Paper, TableHead, TableRow, TableCell, TableBody, IconButton, Table } from '@mui/material';
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { selectMailTemplates } from '../../store/selectors/ConfigSelector';
import DeleteSharpIcon from '@mui/icons-material/DeleteSharp';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import { actionCreators, MailTemplate } from '../../store/Configuration';
import MailTemplateDialog from './MailTemplateDialog';

interface Props {
    mailTemplates: MailTemplate[],
    editMailTemplate: (id: number) => void,
}

const MailTemplateTable = (props: Props) => {
    const { mailTemplates, editMailTemplate } = props;
    const dispatch = useDispatch();

    const deleteMailTemplate = (id: number) => {
        dispatch(actionCreators.deleteMailTemplate(id));
    }

    return (
        <>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell sx={{ width: '85px' }}>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {mailTemplates?.map((row) => (
                            <TableRow
                                key={row.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell>{row.name}</TableCell>
                                <TableCell>
                                    <IconButton size="small" onClick={() => editMailTemplate(row.id)}>
                                        <EditIcon />
                                    </IconButton>
                                    <IconButton size="small" onClick={() => deleteMailTemplate(row.id)}>
                                        <DeleteSharpIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

export default MailTemplateTable;