import { relative } from 'path';
import React, { useState, ReactNode, ChangeEvent, useRef, useEffect } from 'react';

interface Props {
    children: ReactNode;
    setCanvasMode: (mode: string) => void;
    setScrollHeight: (height: number) => void;
    setCanvasHeight: (height: number) => void;
}

const BlockCanvas = (props: Props) => {

    const { children, setCanvasMode, setScrollHeight,setCanvasHeight } = props;
    const [screenWidth, setScreenWidth] = useState(100);
    const [screenHeight, setScreenHeight] = useState(100);

    const canvasRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if (canvasRef.current) {
            const canvasWidth = canvasRef.current.offsetWidth;

            if (canvasWidth >= 1320) {
                setCanvasMode("xxl");
            } else if (canvasWidth >= 1200) {
                setCanvasMode("xl");
            } else if (canvasWidth >= 992) {
                setCanvasMode("lg");
            } else if (canvasWidth >= 768) {
                setCanvasMode("md");
            } else if (canvasWidth >= 576) {
                setCanvasMode("sm");
            } else {
                setCanvasMode("xs");
            }
        }
    }, [canvasRef.current?.offsetWidth]);

    useEffect(() => {
        if (canvasRef.current) {
            setScrollHeight(canvasRef.current.scrollTop);
            setCanvasHeight(canvasRef.current.offsetHeight);
        }
    }, []);


    const handleScreenWidth = (event: ChangeEvent<HTMLInputElement>) => {
        setScreenWidth(Number(event.target.value));
        console.log(screenWidth);
    }

    const handleScreenHeight = (event: ChangeEvent<HTMLInputElement>) => {
        setScreenHeight(Number(event.target.value));
        console.log(screenHeight);
    }

    return (
        <div ref={canvasRef} onScroll={() => setScrollHeight(canvasRef?.current ? canvasRef.current.scrollTop : 0)} className="block-canvas-container" style={{ width: screenWidth + '%', height: screenHeight + '%', float: 'right' }}>
            <div className="block-canvas">
                {children}
            </div>

            {/* <FormGroup className="width-range">
                <Input type="range" name="widthrange" id="widthRange" value={screenWidth} onChange={handleScreenWidth} />
            </FormGroup>
            <FormGroup className="height-range">
                <Input type="range" name="heightrange" id="heightRange" value={screenHeight} onChange={handleScreenHeight} />
            </FormGroup> */}
        </div>
    )
}

export default BlockCanvas;