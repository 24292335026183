import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function SaveTemplate(props: SvgIconProps) {
        return (
                <SvgIcon {...props}>
                        <g>
                                <path d="M12,19.7c-0.5,0-0.9-0.4-0.9-0.9v-5.9H5.2c-0.5,0-0.9-0.4-0.9-0.9c0-0.5,0.4-0.9,0.9-0.9h5.8V5.2c0-0.5,0.4-0.9,0.9-0.9
		c0.5,0,0.9,0.4,0.9,0.9v5.8h5.8c0.5,0,0.9,0.4,0.9,0.9c0,0.5-0.4,0.9-0.9,0.9h-5.8v5.9C12.9,19.3,12.5,19.7,12,19.7z"/>
                                <g>
                                        <path d="M18.7,10.5c0.8,0,1.5,0.7,1.5,1.5s-0.7,1.5-1.5,1.5h-5.2v1h7.2c0.8,0,1.5-0.7,1.5-1.5v-2
			c0-0.8-0.7-1.5-1.5-1.5h-7.2v1H18.7z"/>
                                        <path d="M5.3,13.5c-0.8,0-1.5-0.7-1.5-1.5s0.7-1.5,1.5-1.5h5.2v-1H3.2c-0.8,0-1.5,0.7-1.5,1.5v2
			c0,0.8,0.7,1.5,1.5,1.5h7.2v-1H5.3z"/>
                                        <path d="M12,20.3c-0.8,0-1.4-0.6-1.5-1.3H3.2c-0.8,0-1.5,0.7-1.5,1.5v2c0,0.8,0.7,1.5,1.5,1.5h17.5
			c0.8,0,1.5-0.7,1.5-1.5v-2c0-0.8-0.7-1.5-1.5-1.5h-7.3C13.4,19.7,12.8,20.3,12,20.3z"/>
                                        <path d="M12,3.7c0.8,0,1.4,0.6,1.5,1.3h7.3c0.8,0,1.5-0.7,1.5-1.5v-2c0-0.8-0.7-1.5-1.5-1.5H3.2
			C2.4,0,1.7,0.7,1.7,1.5v2c0,0.8,0.7,1.5,1.5,1.5h7.3C10.6,4.3,11.2,3.7,12,3.7z"/>
                                </g>
                        </g>
                </SvgIcon>
        );
}