import React, { ChangeEvent } from 'react';
import RemoveIcon from '@mui/icons-material/Remove';
import { BlockStyleData } from '../../store/Block';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { Autocomplete, AutocompleteRenderInputParams, styled, TableCell, TableRow } from '@mui/material';
import { StyleTable } from '../../Utility';

interface Props {
    handleDeleteStyleAttribute: (identifier: number) => void,
    handleAttributeValueChange: (e: ChangeEvent<HTMLInputElement>, identifier: number) => void,
    handleAttributeKeyChange: (value: string, identifier: number) => void,
    attr: BlockStyleData,
}

const PREFIX = 'BlockStyleControlRow';

const classes = {
    inputs: `${PREFIX}-inputs`,
    auto: `${PREFIX}-auto`,
    container: `${PREFIX}-container`,
    removeStyle: `${PREFIX}-removeStyle`,
    delimiter: `${PREFIX}-delimiter`,
    button: `${PREFIX}-button`,
    removeIcon: `${PREFIX}-removeIcon`,
    removeCell: `${PREFIX}-removeCell`,
};

const Root = styled(TableRow)((
    {
        theme
    }
) => ({
    [`& .${classes.inputs}`]: {
        fontSize: theme.typography.subtitle2.fontSize,
    },
    [`& .${classes.auto}`]: {
        ['& input']: {
            fontSize: theme.typography.subtitle2.fontSize,
            padding: 0,
        }
    },
    [`& .${classes.removeStyle}`]: {
        width: '3%'
    },
    [`& .${classes.delimiter}`]: {
        width: '2%',
        textAlign: 'center',
    },
    [`& .${classes.button}`]: {
        padding: 0,
    },
    [`& .${classes.removeIcon}`]: {
        fontSize: '18px',
    },
    [`& .${classes.removeCell}`]: {
        width: '12px',
    },
    [`& .${classes.container}`]: {
        '& th': {
            fontWeight: 'bold',
        },
    },
}));

const BlockStyleControlRow = (props: Props) => {

    const { handleDeleteStyleAttribute, handleAttributeKeyChange, handleAttributeValueChange, attr } = props;

    return (
        <Root className={classes.container}>
            <TableCell>
                <Autocomplete
                    className={classes.auto}
                    fullWidth
                    id={'attrKey' + attr.id.toString()}
                    freeSolo
                    autoSelect
                    options={StyleTable.map((option) => option.name)}
                    value={attr.attributeKey}
                    onChange={(event: any, newValue: string | null) => {
                        handleAttributeKeyChange(newValue ?? '', attr.id);
                    }}
                    renderInput={(params: AutocompleteRenderInputParams) => (
                        <TextField {...params} variant="standard" />
                    )}
                />
            </TableCell>
            <TableCell>
                <TextField
                    InputProps={{
                        classes: {
                            input: classes.inputs,
                        },
                    }}
                    fullWidth
                    value={attr.attributeValue}
                    variant="standard"
                    onChange={(e: ChangeEvent<HTMLInputElement>) => handleAttributeValueChange(e, attr.id)} />
            </TableCell>

            <TableCell className={classes.removeCell}>
                <IconButton edge="end" className={classes.button} aria-label="delete" color="primary" size="small" onClick={() => handleDeleteStyleAttribute(attr.id)}>
                    <RemoveIcon className={classes.removeIcon} />
                </IconButton>
            </TableCell>
        </Root>
    )
}

export default BlockStyleControlRow;