import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { DynDataEntry, DynDataSet, DynModel } from '../../store/DynData';
import useDeepEffect from '../../hooks/useDeepEffect';
import DynPropControl from './DynPropControl';
import { FormControl, InputLabel, MenuItem, Paper, Select, Stack, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DynDataSetControl from './DynDataSetControl';
import { useDispatch, useSelector } from 'react-redux';
import { selectDynData, selectDynModels } from '../../store/selectors/DynDataSelector';

const PREFIX = 'DynDataSetDialog';

const classes = {
    formControl: `${PREFIX}-formControl`,
    dialogContent: `${PREFIX}-dialogContent`,
    addButton: `${PREFIX}-addButton`
};

const StyledDialog = styled(Dialog)((
    {
        theme
    }
) => ({
    [`& .${classes.formControl}`]: {
        width: '100%',
    },
    [`& .${classes.dialogContent}`]: {
        '& > *': {
            marginBottom: theme.spacing(1)
        },
    },
    [`& .${classes.addButton}`]: {
        width: '20px',
        minWidth: '20px',
        height: 'inherit'
    }
}));

interface Props {
    dialogOpen: boolean,
    closeDialog: () => void,
    submitDialog: (model: DynDataSet) => void,
    currentDataSet: DynDataSet | undefined,
}

export default function DynDataSetDialog(props: Props) {
    const { dialogOpen, closeDialog, submitDialog, currentDataSet } = props;

    const defaultDynDataSet: DynDataSet = { id: 0, dynModel: { id: 0, dynPropSets: [], name: '' }, dynDataEntries: [] };
    const [dataSet, setDataSet] = React.useState<DynDataSet>(defaultDynDataSet);

    const dynModels = useSelector(selectDynModels);
    const dispatch = useDispatch();

    useDeepEffect(() => {
        if (currentDataSet === undefined) {
            setDataSet(defaultDynDataSet);
        } else {
            setDataSet(currentDataSet);
        }

    }, [currentDataSet]);

    const handleOnClose = () => {
        setDataSet(defaultDynDataSet);
        closeDialog();
    }

    const handleOnSubmit = () => {
        submitDialog(dataSet);
        handleOnClose();
    }

    const modelChangeHandler = (id: number) => {
        const model = dynModels.find(x => x.id === id);
        if(model)
            setDataSet({ ...dataSet, dynModel: model});
    }
    
    const entryChangeHandler = (entryId: number, tag: string, value: string) => {
        let entry = dataSet.dynDataEntries.find(x => x.id === entryId);
        if(entry){
            let data = JSON.parse(entry.data);
            data[tag] = value;
            entry.data = JSON.stringify(data);
            setDataSet({...dataSet, dynDataEntries: [...dataSet.dynDataEntries.filter(x => x.id !== entryId), entry]});
        }
    }

    const entryDeleteHandler = (entryId: number) => {
            setDataSet({...dataSet, dynDataEntries: [...dataSet.dynDataEntries.filter(x => x.id !== entryId)]});
    }

    const addEntryHandler = () => {
        let newData = {};
        dataSet.dynModel.dynPropSets.forEach((propSet) => newData = {...newData, [propSet.tag]: ""});

        let newId = 999 + Math.random().toString().substring(2, 5);
        let newEntry: DynDataEntry = {id: Number(newId), data: JSON.stringify(newData)};
        setDataSet({...dataSet, dynDataEntries: [...dataSet.dynDataEntries, newEntry]});
    }

    return (
        <StyledDialog fullWidth maxWidth="xl" open={dialogOpen} onClose={closeDialog}>
            <DialogTitle>New model</DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <FormControl className={classes.formControl}>
                    <InputLabel id="model">Model</InputLabel>
                    <Select
                        labelId="model"
                        value={currentDataSet?.dynModel.id}
                        onChange={(e) => modelChangeHandler(e.target.value as number)}
                    >
                        {
                            dynModels.map((model) => (
                                <MenuItem key={model.id} value={model.id}>{model.name}</MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
                <Stack direction="row" spacing={2}>
                    <Button className={classes.addButton} variant="contained" onClick={addEntryHandler}>
                        <AddIcon />
                    </Button>
                    <TableContainer component={Paper}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    {
                                        dataSet.dynModel.dynPropSets?.map((propSet) => (
                                            <TableCell key={propSet.id}>{propSet.tag}</TableCell>
                                        ))
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    dataSet.dynDataEntries.map((entry) => (
                                        <DynDataSetControl key={entry.id} entry={entry} model={dataSet.dynModel} changeEntry={entryChangeHandler} deleteEntry={entryDeleteHandler}/>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer >
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleOnClose}>Cancel</Button>
                <Button onClick={handleOnSubmit}>Save</Button>
            </DialogActions>
        </StyledDialog>
    );
}