import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';
import Axios from '../services/api';
import { updateObject } from '../Utility';
import SnackBarUtils from "../components/SnackBar/SnackBarOperations";
import { Satellite } from '@mui/icons-material';
import { Language } from './Language';

export interface ConfigState {
    error: string;
    loading: boolean;
    appConfig: AppConfig;
};

const unloadedState: ConfigState = {
    error: "",
    loading: false,
    appConfig: { id: 0, mailConnections: [], mailTemplates: [], languages: [] }
}

export interface AppConfig {
    id: number,
    mailConnections: MailConnection[],
    mailTemplates: MailTemplate[],
    languages: Language[],
}

export interface MailConnection {
    id: number,
    host: string,
    port: number,
    user: string,
    password: string,
}

export interface MailTemplate {
    id: number,
    name: string,
    content: string,
}

export interface ConfigAddMailConnectionSuccessAction {
    type: 'CONFIG_ADD_MAIL_CONNECTION_SUCCESS',
    mailConnection: MailConnection,
}

export interface ConfigUpdateMailConnectionSuccessAction {
    type: 'CONFIG_UPDATE_MAIL_CONNECTION_SUCCESS',
    mailConnection: MailConnection,
}

export interface ConfigDeleteMailConnectionSuccessAction {
    type: 'CONFIG_DELETE_MAIL_CONNECTION_SUCCESS',
    id: number,
}

export interface ConfigAddMailTemplateSuccessAction {
    type: 'CONFIG_ADD_MAIL_TEMPLATE_SUCCESS',
    mailTemplate: MailTemplate,
}

export interface ConfigUpdateMailTemplateSuccessAction {
    type: 'CONFIG_UPDATE_MAIL_TEMPLATE_SUCCESS',
    mailTemplate: MailTemplate,
}

export interface ConfigDeleteMailTemplateSuccessAction {
    type: 'CONFIG_DELETE_MAIL_TEMPLATE_SUCCESS',
    id: number,
}

export interface ConfigStartAction {
    type: 'CONFIG_START'
}

export interface ConfigFailAction {
    type: 'CONFIG_FAIL',
    error: string
}

export interface ConfigSuccessAction {
    type: 'CONFIG_SUCCESS',
    config: AppConfig
}

export interface ConfigAddLanguageSuccessAction {
    type: 'CONFIG_ADD_LANGUAGE_SUCCESS',
    language: Language,
}

export interface ConfigUpdateLanguageSuccessAction {
    type: 'CONFIG_UPDATE_LANGUAGE_SUCCESS',
    language: Language,
}

export interface ConfigDeleteLanguageSuccessAction {
    type: 'CONFIG_DELETE_LANGUAGE_SUCCESS',
    id: number,
}

export type KnownAction =
    | ConfigFailAction
    | ConfigSuccessAction
    | ConfigStartAction
    | ConfigAddMailConnectionSuccessAction
    | ConfigDeleteMailConnectionSuccessAction
    | ConfigUpdateMailConnectionSuccessAction
    | ConfigAddMailTemplateSuccessAction
    | ConfigDeleteMailTemplateSuccessAction
    | ConfigUpdateMailTemplateSuccessAction
    | ConfigAddLanguageSuccessAction
    | ConfigDeleteLanguageSuccessAction
    | ConfigUpdateLanguageSuccessAction;

export const actionCreators = {
    getConfig: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'CONFIG_START' });
        const appState = getState();

        if (!appState.auth)
            return;

        const url = '/config';
        Axios.get(url)
            .then(response => {
                dispatch({ type: 'CONFIG_SUCCESS', config: response.data });
            })
            .catch(error => {
                dispatch({ type: 'CONFIG_FAIL', error: error.response.data.error });
            })
    },
    updateLanguage: (language: Language): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'CONFIG_START' });
        const appState = getState();

        if (!appState.auth)
            return;

        const url = '/config/language/update';
        Axios.post(url, language)
            .then(response => {
                dispatch({ type: 'CONFIG_UPDATE_LANGUAGE_SUCCESS', language: response.data });
            })
            .catch(error => {
                dispatch({ type: 'CONFIG_FAIL', error: error.response.data.error });
            })
    },
    createLanguage: (language: Language): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'CONFIG_START' });
        const appState = getState();

        if (!appState.auth)
            return;

        const url = '/config/language';
        Axios.post(url, language)
            .then(response => {
                dispatch({ type: 'CONFIG_ADD_LANGUAGE_SUCCESS', language: response.data });
            })
            .catch(error => {
                dispatch({ type: 'CONFIG_FAIL', error: error.response.data.error });
            })
    },
    deleteLanguage: (id: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'CONFIG_START' });
        const appState = getState();

        if (!appState.auth)
            return;

        const url = `/config/language/${id}`;
        Axios.delete(url)
            .then(response => {
                dispatch({ type: 'CONFIG_DELETE_LANGUAGE_SUCCESS', id: id });
            })
            .catch(error => {
                dispatch({ type: 'CONFIG_FAIL', error: error.response.data.error });
            })
    },
    updateMailConnection: (mailConnection: MailConnection): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'CONFIG_START' });
        const appState = getState();

        if (!appState.auth)
            return;

        const url = '/config/mailconnection/update';
        Axios.post(url, mailConnection)
            .then(response => {
                dispatch({ type: 'CONFIG_UPDATE_MAIL_CONNECTION_SUCCESS', mailConnection: response.data });
            })
            .catch(error => {
                dispatch({ type: 'CONFIG_FAIL', error: error.response.data.error });
            })
    },
    createMailConnection: (mailConnection: MailConnection): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'CONFIG_START' });
        const appState = getState();

        if (!appState.auth)
            return;

        const url = '/config/mailconnection';
        Axios.post(url, mailConnection)
            .then(response => {
                dispatch({ type: 'CONFIG_ADD_MAIL_CONNECTION_SUCCESS', mailConnection: response.data });
            })
            .catch(error => {
                dispatch({ type: 'CONFIG_FAIL', error: error.response.data.error });
            })
    },
    deleteMailConnection: (id: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'CONFIG_START' });
        const appState = getState();

        if (!appState.auth)
            return;

        const url = `/config/mailconnection/${id}`;
        Axios.delete(url)
            .then(response => {
                dispatch({ type: 'CONFIG_DELETE_MAIL_CONNECTION_SUCCESS', id: id });
            })
            .catch(error => {
                dispatch({ type: 'CONFIG_FAIL', error: error.response.data.error });
            })
    },
    updateMailTemplate: (mailTemplate: MailTemplate): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'CONFIG_START' });
        const appState = getState();

        if (!appState.auth)
            return;

        const url = '/config/mailtemplate/update';
        Axios.post(url, mailTemplate)
            .then(response => {
                dispatch({ type: 'CONFIG_UPDATE_MAIL_TEMPLATE_SUCCESS', mailTemplate: response.data });
            })
            .catch(error => {
                dispatch({ type: 'CONFIG_FAIL', error: error.response.data.error });
            })
    },
    createMailTemplate: (mailTemplate: MailTemplate): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'CONFIG_START' });
        const appState = getState();

        if (!appState.auth)
            return;

        const url = '/config/mailtemplate';
        Axios.post(url, mailTemplate)
            .then(response => {
                dispatch({ type: 'CONFIG_ADD_MAIL_TEMPLATE_SUCCESS', mailTemplate: response.data });
            })
            .catch(error => {
                dispatch({ type: 'CONFIG_FAIL', error: error.response.data.error });
            })
    },
    deleteMailTemplate: (id: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'CONFIG_START' });
        const appState = getState();

        if (!appState.auth)
            return;

        const url = `/config/mailtemplate/${id}`;
        Axios.delete(url)
            .then(response => {
                dispatch({ type: 'CONFIG_DELETE_MAIL_TEMPLATE_SUCCESS', id: id });
            })
            .catch(error => {
                dispatch({ type: 'CONFIG_FAIL', error: error.response.data.error });
            })
    },
}

export const reducer: Reducer<ConfigState | undefined> = (state: ConfigState | undefined, incomingAction: KnownAction): ConfigState | undefined => {
    if (state === undefined) {
        return unloadedState;
    }
    let config = { ...state.appConfig };
    switch (incomingAction.type) {
        case 'CONFIG_SUCCESS':
            return updateObject(state, { appConfig: incomingAction.config, error: null, loading: false });
        case 'CONFIG_START':
            return updateObject(state, { error: null, loading: true });
        case 'CONFIG_FAIL':
            return updateObject(state, { error: incomingAction.error, loading: false });
        case 'CONFIG_ADD_MAIL_CONNECTION_SUCCESS':
            config.mailConnections = [...config.mailConnections, incomingAction.mailConnection];
            return updateObject(state, { appConfig: config, error: null, loading: false });
        case 'CONFIG_UPDATE_MAIL_CONNECTION_SUCCESS':
            config.mailConnections = [...config.mailConnections.filter(x => x.id !== incomingAction.mailConnection.id), incomingAction.mailConnection];
            return updateObject(state, { appConfig: config, error: null, loading: false });
        case 'CONFIG_DELETE_MAIL_CONNECTION_SUCCESS':
            return updateObject(state, { appConfig: { ...state.appConfig, mailConnections: state.appConfig.mailConnections.filter(x => x.id !== incomingAction.id) }, error: null, loading: false });
        case 'CONFIG_ADD_MAIL_TEMPLATE_SUCCESS':
            config.mailTemplates = [...config.mailTemplates, incomingAction.mailTemplate];
            return updateObject(state, { appConfig: config, error: null, loading: false });
        case 'CONFIG_UPDATE_MAIL_TEMPLATE_SUCCESS':
            config.mailTemplates = [...config.mailTemplates.filter(x => x.id !== incomingAction.mailTemplate.id), incomingAction.mailTemplate];
            return updateObject(state, { appConfig: config, error: null, loading: false });
        case 'CONFIG_DELETE_MAIL_TEMPLATE_SUCCESS':
            return updateObject(state, { appConfig: { ...state.appConfig, mailTemplates: state.appConfig.mailTemplates.filter(x => x.id !== incomingAction.id) }, error: null, loading: false });
        case 'CONFIG_ADD_LANGUAGE_SUCCESS':
            let addlanguages = [...config.languages];
            
            if(incomingAction.language.defaultLanguage)
                addlanguages.forEach(x => x.defaultLanguage = false);

            config.languages = [...addlanguages, incomingAction.language];
            return updateObject(state, { appConfig: config, error: null, loading: false });
        case 'CONFIG_UPDATE_LANGUAGE_SUCCESS':
            let languages = [...config.languages];
            
            if(incomingAction.language.defaultLanguage)
                languages.forEach(x => x.defaultLanguage = false);

            config.languages = [...languages.filter(x => x.id !== incomingAction.language.id), incomingAction.language];
            return updateObject(state, { appConfig: config, error: null, loading: false });
        case 'CONFIG_DELETE_LANGUAGE_SUCCESS':
            return updateObject(state, { appConfig: { ...state.appConfig, languages: state.appConfig.languages.filter(x => x.id !== incomingAction.id) }, error: null, loading: false });
        default:
            return state;
    }
}