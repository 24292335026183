import { ApplicationState } from "..";
import { FormBlockData } from "../Block";
import { DynProps } from "../DynData";

export const selectDynModels = (rootState: ApplicationState) => {
    return rootState.dynData?.models ?? [];
}

export const selectDynData = (rootState: ApplicationState) => {
    return rootState.dynData?.dataSets ?? [];
}

export const selectFormDynProps = (rootState: ApplicationState): DynProps[] => {
    if (!rootState.block?.selectedBlock || rootState.block.selectedBlock.type !== "input")
        return [];

    const blockIds = rootState.block.selectedBlock.locationPath.split('-');

    for (let i = 0; i < blockIds.length; i++) {
        const currentBlock = rootState.block?.blocks.find(x => x.id === Number(blockIds[i]));

        if(currentBlock?.type !== 'form')
            continue

        return (currentBlock as FormBlockData)?.dynModel?.dynPropSets ?? [];
    }

    return [];
}