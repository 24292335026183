import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function Head(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path d="M20.4,0H3.6C1.6,0,0,1.6,0,3.6v16.8c0,2,1.6,3.6,3.6,3.6h16.8c2,0,3.6-1.6,3.6-3.6V3.6C24,1.6,22.4,0,20.4,0z
	 M20.4,22.7H3.6c-1.3,0-2.3-1-2.3-2.3V5.5h21.5v14.9C22.7,21.7,21.7,22.7,20.4,22.7z"/>
        </SvgIcon>
    );
}