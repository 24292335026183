import { DialogTitle, DialogContent, DialogContentText, TextField, DialogActions, Button, FormControlLabel, Checkbox, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { Theme } from '@mui/material/styles';
import { PageData } from '../../../store/Page';
import { DynDataSet } from '../../../store/DynData';
import { useSelector } from 'react-redux';
import { selectDynData } from '../../../store/selectors/DynDataSelector';

const PREFIX = 'PageModal';

const classes = {
    root: `${PREFIX}-root`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
    {
        theme
    }
) => ({
    [`& .${classes.root}`]: {
        '& > *': {
            marginTop: theme.spacing(1),
        },
    }
}));

interface Props {
    pageData: PageData | undefined,
    togglePageModal: () => void,
    submitPageModal: (newPage: PageData) => void
}

const PageModal = (props: Props) => {


    const { togglePageModal, submitPageModal, pageData } = props;

    const [pageName, setPageName] = useState("");
    const [pageMetaTitle, setPageMetaTitle] = useState("");
    const [pageMetaDescription, setPageMetaDescription] = useState("");
    const [pageWithNavbar, setPageWithNavbar] = useState(true);
    const [pageWithFooter, setPageWithFooter] = useState(true);
    const [dynamic, setDynamic] = useState(false);
    const [dynDataSet, setDynDataSet] = useState<DynDataSet | undefined>(undefined);

    const dataSets = useSelector(selectDynData);

    useEffect(() => {
        if (pageData) {
            setPageName(pageData.name);
            setPageMetaTitle(pageData.metaTitle);
            setPageMetaDescription(pageData.metaDescription);
            setPageWithNavbar(pageData.withNavbar);
            setPageWithFooter(pageData.withFooter);
            setDynamic(pageData.dynamic);
            setDynDataSet(pageData.dynDataSet);
        }
    }, [])

    const pageNameChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        setPageName(event.target.value);
    }

    const pageMetaTitleChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        setPageMetaTitle(event.target.value);
    }

    const pageMetaDescriptionChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        setPageMetaDescription(event.target.value);
    }

    const pageSubmitHandler = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (pageData) {
            let newPageData = { ...pageData };
            newPageData.name = pageName;
            newPageData.metaDescription = pageMetaDescription;
            newPageData.metaTitle = pageMetaTitle;
            newPageData.withFooter = pageWithFooter;
            newPageData.withNavbar = pageWithNavbar;
            newPageData.dynamic = dynamic;
            newPageData.dynDataSet = dynDataSet;

            submitPageModal(newPageData);
        } else {
            let newPageData: PageData = {
                id: 0,
                name: pageName,
                metaTitle: pageMetaTitle,
                metaDescription: pageMetaDescription,
                blockData: [],
                withFooter: pageWithFooter,
                withNavbar: pageWithNavbar,
                dynamic: dynamic,
                dynDataSet: dynDataSet,
            }
            submitPageModal(newPageData);
        }
    }

    return (
        (<Root>
            <form noValidate autoComplete="off" onSubmit={pageSubmitHandler}>
                <DialogTitle>New Page</DialogTitle>
                <DialogContent className={classes.root}>
                    <TextField
                        autoFocus
                        label="Name"
                        type="text"
                        value={pageName}
                        onChange={pageNameChangeHandler}
                        fullWidth
                    />
                    <TextField
                        label="Metatitle"
                        type="text"
                        value={pageMetaTitle}
                        onChange={pageMetaTitleChangeHandler}
                        fullWidth
                    />
                    <TextField
                        label="Metadescription"
                        type="text"
                        fullWidth
                        multiline
                        rows={3}
                        value={pageMetaDescription}
                        onChange={pageMetaDescriptionChangeHandler}
                    />
                    <FormControlLabel
                        value="start"
                        control={<Checkbox color="primary" checked={pageWithNavbar} onChange={(e: ChangeEvent<HTMLInputElement>) => setPageWithNavbar(prevState => !prevState)} />}
                        label="With Navbar"
                        labelPlacement="start"
                    />
                    <FormControlLabel
                        value="start"
                        control={<Checkbox color="primary" checked={pageWithFooter} onChange={(e: ChangeEvent<HTMLInputElement>) => setPageWithFooter(prevState => !prevState)} />}
                        label="With Footer"
                        labelPlacement="start"
                    />
                    <FormControlLabel
                        value="start"
                        control={<Checkbox color="primary" checked={dynamic} onChange={(e: ChangeEvent<HTMLInputElement>) => setDynamic(prevState => !prevState)} />}
                        label="Dynamic"
                        labelPlacement="start"
                    />
                    {
                        dynamic ? (
                            <FormControl>
                                <InputLabel id="data-set">Dataset</InputLabel>
                                <Select
                                    labelId="data-set"
                                    value={dynDataSet?.id}
                                    fullWidth
                                    onChange={(e: any) => setDynDataSet(dataSets.find(x => x.id === e.target.value))}
                                >
                                    <MenuItem value={undefined}>None</MenuItem>
                                    {
                                        dataSets.map((dataSet) => (
                                            <MenuItem key={dataSet.id} value={dataSet.id}>{ dataSet.id + ' : ' + dataSet.dynModel.name }</MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                        ) : null
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={togglePageModal} color="primary">
                        Cancel
                    </Button>
                    <Button type="submit" color="primary">
                        Submit
                    </Button>
                </DialogActions>
            </form>
        </Root>)
    );
}

export default PageModal;