import React, { MutableRefObject } from 'react';
import { HeaderBlockData } from '../../../store/Block';

/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react';


interface Props {
    blockData: HeaderBlockData,
    content: string,
    classes: string,
    styles: any,
    blockClickHandler: (e: React.MouseEvent<HTMLElement>) => void,
}

const HeaderBlock = (props: Props) => {
    const { blockData, classes, blockClickHandler, styles, content } = props;
    const tag = `h${blockData.number.toString()}`;

    let displayedBlock;
    if (tag === 'h1')
        displayedBlock = (
            <h1 css={styles} className={classes} onClick={blockClickHandler}>
                {content}
            </h1>);
    else if (tag === 'h2')
        displayedBlock = (
            <h2 css={styles} className={classes} onClick={blockClickHandler}>
                {content}
            </h2>);
    else if (tag === 'h3')
        displayedBlock = (
            <h3 css={styles} className={classes} onClick={blockClickHandler}>
                {content}
            </h3>);
    else if (tag === 'h4')
        displayedBlock = (
            <h4 css={styles} className={classes} onClick={blockClickHandler}>
                {content}
            </h4>);
    else if (tag === 'h5')
        displayedBlock = (
            <h5 css={styles} className={classes} onClick={blockClickHandler}>
                {content}
            </h5>);
    else if (tag === 'h6')
        displayedBlock = (
            <h6 css={styles} className={classes} onClick={blockClickHandler}>
                {content}
            </h6>);


    return (
        <>
            {displayedBlock}
        </>
    );
}

export default HeaderBlock;