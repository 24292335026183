import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button } from '@mui/material';
import React from 'react'
import { useSelector } from 'react-redux';
import { selectMailConnections } from '../../store/selectors/ConfigSelector';
import { MailConnection } from '../../store/Configuration';

interface Props {
    open: boolean,
    handleClose: () => void,
    handleSubmit: (mailConnection: MailConnection) => void,
    mailConnection: MailConnection | undefined,
}

const MailConnectionDialog = (props: Props) => {

    const defaultMailConnection: MailConnection = { id: 0, host: '', password: '', port: 0, user: '' }
    const { open, handleClose, mailConnection, handleSubmit } = props;
    const [currentMailConnection, SetCurrentMailConnection] = React.useState(defaultMailConnection);

    React.useEffect(() => {
        if (mailConnection)
            SetCurrentMailConnection(mailConnection);
        else
            SetCurrentMailConnection(defaultMailConnection);
    }, [mailConnection])

    const handleOnSubmit = () => {
        handleSubmit(currentMailConnection);
        handleOnClose();
    }

    const handleOnClose = () => {
        SetCurrentMailConnection(defaultMailConnection);
        handleClose();
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
        >
            <DialogTitle>
                Connection
            </DialogTitle>
            <DialogContent sx={{
                        '& > :not(style)': { m: 1, width: '25ch' },
                    }}>
                <TextField label="Host" placeholder="Host" value={currentMailConnection.host} onChange={(e) => SetCurrentMailConnection({ ...currentMailConnection, host: e.target.value })} />
                <TextField label="Port" placeholder="Port" value={currentMailConnection.port} onChange={(e) => SetCurrentMailConnection({ ...currentMailConnection, port: Number(e.target.value) })} />
                <TextField label="User" placeholder="User" value={currentMailConnection.user} onChange={(e) => SetCurrentMailConnection({ ...currentMailConnection, user: e.target.value })} />
                <TextField label="Password" placeholder="Password" type="password" value={currentMailConnection.password} onChange={(e) => SetCurrentMailConnection({ ...currentMailConnection, password: e.target.value })} />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>cancel</Button>
                <Button onClick={handleOnSubmit} autoFocus>
                Submit
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default MailConnectionDialog;