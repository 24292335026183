import { Avatar, Chip, Collapse, Grid, IconButton, ListItem, List, ListItemButton, ListItemText } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useRef, useState } from 'react';
import { actionCreators, BlockData } from '../../store/Block';
import clsx from 'clsx';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import LinkIcon from '@mui/icons-material/Link';
import AttractionsIcon from '@mui/icons-material/Attractions';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import StarIcon from '@mui/icons-material/Star';
import { useDispatch } from 'react-redux';
import { getTagName } from '../../Utility';

const PREFIX = 'BlockMapBlock';

const classes = {
    nested: `${PREFIX}-nested`,
    divider: `${PREFIX}-divider`,
    levelDivider: `${PREFIX}-levelDivider`,
    mapText: `${PREFIX}-mapText`,
    openButton: `${PREFIX}-openButton`,
    collapseButton: `${PREFIX}-collapseButton`,
    gridWidth: `${PREFIX}-gridWidth`,
    avatarIcon: `${PREFIX}-avatarIcon`,
    linkIcon: `${PREFIX}-linkIcon`,
    confirmIcon: `${PREFIX}-confirmIcon`,
    attractIcon: `${PREFIX}-attractIcon`,
    starIcon: `${PREFIX}-starIcon`,
    hideButton: `${PREFIX}-hideButton`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
    { theme }
) => ({
    [`& .${classes.nested}`]: {
        paddingBottom: 0,
        paddingTop: 0,
    },

    [`& .${classes.divider}`]: {
        width: theme.spacing(1),
    },

    [`& .${classes.levelDivider}`]: {
        borderLeft: 'solid 1px lightgrey',
        height: '45px',
    },

    [`& .${classes.mapText}`]: {
        paddingLeft: theme.spacing(1),
        margin: 0,
    },

    [`& .${classes.openButton}`]: {
        transform: 'rotate(90deg)'
    },

    [`& .${classes.collapseButton}`]: {
        padding: '0'
    },

    [`& .${classes.gridWidth}`]: {
        width: '50px',
    },
    [`& .${classes.avatarIcon}`]: {
        fontSize: '1.1rem',
    },
    [`& .${classes.linkIcon}`]: {
        color: 'grey',
    },
    [`& .${classes.confirmIcon}`]: {
        color: 'blue',
    },
    [`& .${classes.attractIcon}`]: {
        color: 'orange',
    },
    [`& .${classes.starIcon}`]: {
        color: 'purple',
    },
    [`& .${classes.hideButton}`]: {
        opacity: 0,
    },
}));

interface Props {
    blocks: BlockData[];
    selectedBlock: BlockData | undefined;
    currentBlock: BlockData;
    level: number;
    firstEntry: boolean,
    lastEntry: boolean,
}

const BlockMapBlock = (props: Props) => {
    const { blocks, selectedBlock, currentBlock, level } = props;

    const blockRef: any = useRef(null);
    const [childrenOpen, setChildrenOpen] = useState(false);
    const dispatch = useDispatch();

    React.useEffect(() => {
        if (selectedBlock && (selectedBlock?.locationPath?.includes(currentBlock.id + "-") || currentBlock?.id === selectedBlock?.id))
            setChildrenOpen(true);

        if (blockRef?.current && blockRef.current !== null && currentBlock?.id === selectedBlock?.id) {
            blockRef.current.scrollIntoView({
                behavior: "smooth",
                block: 'center',
                inline: 'center'
            });
        }
    }, [selectedBlock]);

    const filteredBlocks = blocks.filter(x => x.locationPath?.split('-').length - 1 === currentBlock.locationPath?.split('-').length);
    const displayedBlocks = filteredBlocks
        .sort((a, b) => a.sortPath - b.sortPath)
        .map((block, index) => {
            return (
                <List key={block.id} component="div" disablePadding>
                    <BlockMapBlock
                        currentBlock={block}
                        blocks={blocks.filter(x => x.locationPath?.startsWith(block.locationPath + '-'))}
                        selectedBlock={selectedBlock}
                        level={level + 1}
                        firstEntry={index === 0}
                        lastEntry={filteredBlocks.length - 1 === index}
                    />
                </List>
            )
        });

    let items = [];
    for (let i = 0; i < level; i++) {
        let dividerClass = clsx(classes.divider, classes.levelDivider);

        items.push(<div key={currentBlock.id.toString() + i.toString()} className={dividerClass} />);
    }

    return (
        (<Root>
            <ListItem disablePadding>
                <ListItemButton sx={{ padding: 0 }} ref={blockRef} onClick={() => dispatch(actionCreators.selectBlock(currentBlock))} selected={selectedBlock?.id === currentBlock.id} disableGutters>

                    <IconButton
                        disabled={!filteredBlocks.length}
                        className={clsx(classes.collapseButton, {
                            [classes.openButton]: childrenOpen,
                        }, {
                            [classes.hideButton]: !filteredBlocks.length,
                        })}
                        onClick={(e) => { e.stopPropagation(); setChildrenOpen(prev => !prev); }}
                        size="small">
                        <ArrowRightIcon />
                    </IconButton>
                    {items}
                    <ListItemText sx={{ m: 0 }} secondary={`Id: ${currentBlock.id}`} primary={`<${getTagName(currentBlock)}${filteredBlocks.length ? '' : '/'}>`} />
                    <Grid container className={classes.gridWidth}>
                        {
                            currentBlock.templateReference ?
                                <Grid item xs={6}>
                                    <LinkIcon className={clsx(classes.avatarIcon, classes.linkIcon)} />
                                </Grid>
                                : null
                        }
                        {
                            currentBlock.blockEvents?.length ?
                                <Grid item xs={6}>
                                    <AttractionsIcon className={clsx(classes.avatarIcon, classes.attractIcon)} />
                                </Grid>
                                : null
                        }
                        {
                            currentBlock.blockEventTriggers?.length ?
                                <Grid item xs={6}>
                                    <ConfirmationNumberIcon className={clsx(classes.avatarIcon, classes.confirmIcon)} />
                                </Grid>
                                : null
                        }
                        {
                            currentBlock.animationName ?
                                <Grid item xs={6}>
                                    <StarIcon className={clsx(classes.avatarIcon, classes.starIcon)} />
                                </Grid>
                                : null
                        }
                    </Grid>
                </ListItemButton>
            </ListItem>
            <Collapse in={childrenOpen}>
                {displayedBlocks}
            </Collapse>
            {
                filteredBlocks.length ?

                    <ListItem disablePadding>
                        <ListItemButton sx={{ padding: 0 }} ref={blockRef} onClick={() => dispatch(actionCreators.selectBlock(currentBlock))} selected={selectedBlock?.id === currentBlock.id} disableGutters>

                            <IconButton
                                className={clsx(classes.collapseButton, {
                                    [classes.openButton]: childrenOpen,
                                })}
                                sx={{ opacity: 0 }}
                                onClick={(e) => { e.stopPropagation(); setChildrenOpen(prev => !prev); }}
                                size="small">
                                <ArrowRightIcon />
                            </IconButton>
                            {items}
                            <ListItemText sx={{ m: 0 }} primary={`</${getTagName(currentBlock)}>`} />
                        </ListItemButton>
                    </ListItem>
                    : null
            }
        </Root>)
    );
}

export default BlockMapBlock;
