import React, { ChangeEvent } from 'react';
import { styled } from '@mui/material/styles';
import { MediaFile } from '../../store/Media';
import FolderIcon from '@mui/icons-material/Folder';
import { Avatar, Checkbox, Divider, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, TableBody, TableCell, TableRow, Typography } from '@mui/material';

import clsx from 'clsx';
import DeleteSharpIcon from '@mui/icons-material/DeleteSharp';
import EditIcon from '@mui/icons-material/Edit';

const PREFIX = 'MediaFiles';

const classes = {
    mediaCardImageBox: `${PREFIX}-mediaCardImageBox`,
    mediaCardImage: `${PREFIX}-mediaCardImage`,
    menuButton: `${PREFIX}-menuButton`,
    title: `${PREFIX}-title`,
    input: `${PREFIX}-input`,
    label: `${PREFIX}-label`,
    root: `${PREFIX}-root`,
    inline: `${PREFIX}-inline`,
    buttonCell: `${PREFIX}-buttonCell`,
    selected: `${PREFIX}-selected`
};

const StyledTableBody = styled(TableBody)((
    {
        theme
    }
) => ({
    [`& .${classes.mediaCardImageBox}`]: {
        height: '64px',
        width: '64px',
        marginRight: theme.spacing(1),
    },

    [`& .${classes.buttonCell}`]: {
        width: '150px',
    },

    [`& .${classes.mediaCardImage}`]: {
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundColor: theme.palette.primary.main,
        margin: 'auto',
        height: '100%',
    },

    [`& .${classes.menuButton}`]: {
        marginRight: theme.spacing(1),
    },

    [`& .${classes.selected}`]: {
        backgroundColor: '#b1c59e',
    },
    [`& .${classes.title}`]: {
        flexGrow: 1,
    },

    [`& .${classes.input}`]: {
        display: 'none',
    },

    [`& .${classes.label}`]: {
        marginBottom: 0,
    },

    [`&.${classes.root}`]: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },

    [`& .${classes.inline}`]: {
        display: 'inline',
    }
}));

interface Props {
    files: MediaFile[],
    modifyHandler: (mediaFile: MediaFile) => void,
    fileChangeHandler: (event: ChangeEvent<HTMLInputElement>, id: number) => void,
    folderChangeHandler: (path: string) => void,
    handleSelectedFilesChanged: (id: number) => void,
    selectedFiles: number[]
}

const MediaFolders = (props: Props) => {


    const { files, modifyHandler, folderChangeHandler, handleSelectedFilesChanged, selectedFiles } = props;

    const blocks = files
        .sort((a: MediaFile, b: MediaFile) => {
            if (a.name > b.name)
                return 1;
            else if (a.name < b.name)
                return -1;
            else
                return 0;
        })

        .map((file: MediaFile) => {

            const fileSelected = selectedFiles.indexOf(file.id) > -1;
            return (
                <TableRow key={file.id} onDoubleClick={() => folderChangeHandler(file.path)}>
                    <TableCell>
                        <Checkbox checked={fileSelected} onClick={() => handleSelectedFilesChanged(file.id)} />
                    </TableCell>
                    <TableCell>
                        <Avatar>
                            <FolderIcon />
                        </Avatar>
                    </TableCell>
                    <TableCell>{file.name}</TableCell>
                    <TableCell>{file.path}</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell className={classes.buttonCell}>
                        <IconButton onClick={() => modifyHandler(file)} size="small">
                            <EditIcon />
                        </IconButton>
                    </TableCell>
                </TableRow>)
        });

    return (

        <StyledTableBody>
            {blocks}
        </StyledTableBody>
    );
}

export default MediaFolders;
