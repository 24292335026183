import React, { MutableRefObject, ReactNode } from 'react';
import { ColBlockData, RowBlockData } from '../../../store/Block';
import { Col } from 'react-grid-system';

/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react';


interface Props {
    blockData: ColBlockData,
    rowBlockData: RowBlockData,
    classes: string,
    blockClickHandler: (e: React.MouseEvent<HTMLElement>) => void,
    canvasMode: string,
    children: ReactNode,
    styles: any
}


const ColumnBlock = (props: Props) => {
    const { canvasMode, children, blockData, rowBlockData, classes, blockClickHandler, styles } = props;

    let colWidth: number = 0;
    if (rowBlockData) {
        switch (canvasMode) {
            case 'sm':
                if (rowBlockData.sm == 1 || rowBlockData.sm == 0)
                    colWidth = 12;
                else
                    colWidth = 12 / rowBlockData.sm * blockData.sm;
                break;
            case 'md':
                if (rowBlockData.md == 1 || rowBlockData.md == 0)
                    colWidth = 12;
                else
                    colWidth = 12 / rowBlockData.md * blockData.md;
                break;
            case 'lg':
                if (rowBlockData.lg == 1 || rowBlockData.lg == 0)
                    colWidth = 12;
                else
                    colWidth = 12 / rowBlockData.lg * blockData.lg;
                break;
            case 'xl':
                if (rowBlockData.xl == 1 || rowBlockData.xl == 0)
                    colWidth = 12;
                else
                    colWidth = 12 / rowBlockData.xl * blockData.xl;
                break;
            case 'xxl':
                if (rowBlockData.xl == 1 || rowBlockData.xl == 0)
                    colWidth = 12;
                else
                    colWidth = 12 / rowBlockData.xl * blockData.xl;
                break;
            default:
                if (rowBlockData.xs == 1 || rowBlockData.xs == 0)
                    colWidth = 12;
                else
                    colWidth = 12 / rowBlockData.xs * blockData.xs;
                break;
        }
    }

    let displayedBlock = (
        <Col css={styles} xs={colWidth} className={classes} onClick={blockClickHandler}>
            {children}
        </Col>
    );

    return (
        <>
            {displayedBlock}
        </>
    );
}

export default ColumnBlock;