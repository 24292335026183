import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { FormControl, InputLabel, MenuItem, Select, styled } from '@mui/material';
import { MediaFile } from '../../../store/Media';
import useDeepEffect from '../../../hooks/useDeepEffect';

const PREFIX = 'FolderDialog';

const classes = {
    formControl: `${PREFIX}-formControl`,
    dialogContent: `${PREFIX}-dialogContent`,
    addButton: `${PREFIX}-addButton`
};

const StyledDialog = styled(Dialog)((
    {
        theme
    }
) => ({
    [`& .${classes.formControl}`]: {
        width: '100%',
    },
    [`& .${classes.dialogContent}`]: {
        '& > *': {
            marginBottom: theme.spacing(1)
        },
    },
    [`& .${classes.addButton}`]: {
        width: '20px',
        minWidth: '20px',
        height: 'inherit'
    }
}));

interface Props {
    dialogOpen: boolean,
    closeDialog: () => void,
    submitDialog: (path: string) => void,
    folders: string[],
}

export default function MoveFileDialog(props: Props) {
    const { dialogOpen, closeDialog, submitDialog, folders } = props;

    const [newPath, setNewPath] = React.useState<string>("/images");

    const handleOnClose = () => {
        setNewPath("/images");
        closeDialog();
    }

    const handleOnSubmit = () => {
        submitDialog(newPath);
        handleOnClose();
    }

    return (
        <StyledDialog open={dialogOpen} fullWidth maxWidth="sm" onClose={closeDialog}>
            <DialogTitle>Folder</DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <FormControl className={classes.formControl}>
                    <InputLabel id="input-type">Inputtype</InputLabel>
                    <Select
                        labelId="input-type"
                        value={newPath}
                        onChange={(e: any) => setNewPath(e.target.value)}
                    >
                        <MenuItem value={"/images"}>/images</MenuItem>
                        {
                            folders.map((folder) => <MenuItem key={folder}value={folder}>/{folder}</MenuItem>)
                        }
                    </Select>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleOnClose}>Cancel</Button>
                <Button onClick={handleOnSubmit}>Save</Button>
            </DialogActions>
        </StyledDialog>
    );
}