import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';
import Axios from '../services/api';
import { updateObject } from '../Utility';
import SnackBarUtils from "../components/SnackBar/SnackBarOperations";


export interface MediaState {
    files: MediaFile[];
    error: string;
    loading: boolean;
};

const unloadedState: MediaState = {
    files: [],
    error: "",
    loading: false
}

export interface MediaFile {
    id: number,
    name: string,
    path: string,
    extension: string,
    size: string,
}

export interface FileStartAction {
    type: 'FILE_START'
}

export interface FileSuccessAction {
    type: 'FILE_SUCCESS',
    files: MediaFile[]
}

export interface FileFailAction {
    type: 'FILE_FAIL',
    error: string
}

export interface FileAddSuccessAction {
    type: 'FILE_ADD_SUCCESS',
    file: MediaFile[]
}

export interface FileNewUploadStartAction {
    type: 'FILE_NEW_UPLOAD_START'
}

export interface FileNewUploadSuccessAction {
    type: 'FILE_NEW_UPLOAD_SUCCESS',
    file: MediaFile
}


export interface FileUpdateSuccessAction {
    type: 'FILE_UPDATE_SUCCESS',
    file: MediaFile
}

export interface FileDeleteSuccessAction {
    type: 'FILE_DELETE_SUCCESS',
    fileId: number
}

export type KnownAction =
    | FileStartAction
    | FileSuccessAction
    | FileFailAction
    | FileUpdateSuccessAction
    | FileAddSuccessAction
    | FileDeleteSuccessAction
    | FileNewUploadStartAction
    | FileNewUploadSuccessAction;

export const actionCreators = {
    uploadNewFile: (fileName: string, formFile: File, id: number, path: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'FILE_START' });
        const appState = getState();

        if (!appState.auth)
            return;

        const formData = new FormData();
        formData.append("file", formFile);
        formData.append("fileName", fileName);

        const url = '/media/upload' + id.toString();
        Axios.put(url, {formData, filePath: path})
            .then(response => {
                SnackBarUtils.success('Datei wurde erfolgreicht hinzugefügt.');
                dispatch({ type: 'FILE_NEW_UPLOAD_SUCCESS', file: response.data });
            })
            .catch(error => {
                SnackBarUtils.error('Beim Hinzufügen der Datei ist ein Fehler aufgetreten.');
                dispatch({ type: 'FILE_FAIL', error: error.response.data.error });
            })
    },
    uploadFile: (formFiles: FileList, path: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'FILE_START' });
        const appState = getState();

        if (!appState.auth || !formFiles.length)
            return;

        const formData = new FormData();

        for(let i = 0; i < formFiles.length; i++){
            formData.append("file", formFiles[i]);
        }

        formData.append("filePath", path);

        const url = '/media/upload';
        Axios.post(url, formData)
            .then(response => {
                SnackBarUtils.success('Datei wurde erfolgreich hinzugefügt.');
                dispatch({ type: 'FILE_ADD_SUCCESS', file: response.data });
            })
            .catch(error => {
                SnackBarUtils.error('Beim Hinzufügen der Datei ist ein Fehler aufgetreten.');
                dispatch({ type: 'FILE_FAIL', error: error.response.data.error });
            })
    },
    addFolder: (media: MediaFile, path: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'FILE_START' });
        const appState = getState();

        if (!appState.auth)
            return;

        const url = '/media/folder/add';
        Axios.post(url, { folder: media, path } )
            .then(response => {
                SnackBarUtils.success('Datei wurde erfolgreich geändert.');
                dispatch({ type: 'FILE_ADD_SUCCESS', file: response.data });
            })
            .catch(error => {
                SnackBarUtils.error('Beim Ändern der Datei ist ein Fehler aufgetreten.');
                dispatch({ type: 'FILE_FAIL', error: error.response.data.error });
            })
    },
    moveFile: (media: MediaFile, path: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'FILE_START' });
        const appState = getState();

        if (!appState.auth)
            return;

        const url = '/media/folder/move';
        Axios.post(url, { file: media, filePath: path } )
            .then(response => {
                SnackBarUtils.success('Datei wurde erfolgreich geändert.');
                dispatch({ type: 'FILE_UPDATE_SUCCESS', file: response.data });
            })
            .catch(error => {
                SnackBarUtils.error('Beim Ändern der Datei ist ein Fehler aufgetreten.');
                dispatch({ type: 'FILE_FAIL', error: error.response.data.error });
            })
    },
    updateFile: (file: MediaFile, path: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'FILE_START' });
        const appState = getState();

        if (!appState.auth)
            return;

        const url = '/media/' + file.id;
        Axios.put(url, { fileName: file.name, filePath: path })
            .then(response => {
                SnackBarUtils.success('Datei wurde erfolgreich geändert.');
                dispatch({ type: 'FILE_UPDATE_SUCCESS', file: response.data });
            })
            .catch(error => {
                SnackBarUtils.error('Beim Ändern der Datei ist ein Fehler aufgetreten.');
                dispatch({ type: 'FILE_FAIL', error: error.response.data.error });
            })
    },
    getFiles: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'FILE_START' });
        const appState = getState();

        if (!appState.auth)
            return;

        const url = '/media';
        Axios.get(url)
            .then(response => {
                SnackBarUtils.success('Dateien wurden erfolgreich geladen.');
                dispatch({ type: 'FILE_SUCCESS', files: response.data });
            })
            .catch(error => {
                dispatch({ type: 'FILE_FAIL', error: error.response.data.error });
                SnackBarUtils.error('Beim Laden der Dateien ist ein Fehler aufgetreten.');
            })
    },
    deleteFile: (fileId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'FILE_START' });
        const appState = getState();

        if (!appState.auth)
            return;

        const url = '/media/' + fileId.toString();
        Axios.delete(url)
            .then(() => {
                SnackBarUtils.success('Datei wurde erfolgreich gelöscht.');
                dispatch({ type: 'FILE_DELETE_SUCCESS', fileId: fileId });
            })
            .catch(error => {
                SnackBarUtils.error('Beim Löschen der Datei ist ein Fehler aufgetreten.');
                dispatch({ type: 'FILE_FAIL', error: error.response.data.error });
            })
    }
}

export const reducer: Reducer<MediaState | undefined> = (state: MediaState | undefined, incomingAction: KnownAction): MediaState | undefined => {
    if (state === undefined) {
        return unloadedState;
    }
    switch (incomingAction.type) {
        case 'FILE_ADD_SUCCESS':
            return updateObject(state, { files: [...state.files.filter(x => incomingAction.file.indexOf(x) === -1), ...incomingAction.file], error: null, loading: false });
        case 'FILE_SUCCESS':
            return updateObject(state, { files: [...incomingAction.files] });
        case 'FILE_START':
            return updateObject(state, { error: null, loading: true });
        case 'FILE_FAIL':
            return updateObject(state, { error: incomingAction.error, loading: false });
        case 'FILE_DELETE_SUCCESS':
            return updateObject(state, { files: state.files.filter(x => x.id !== incomingAction.fileId), error: null, loading: false });
        case 'FILE_UPDATE_SUCCESS':
            return updateObject(state, { files: [...state.files.filter((x: MediaFile) => x.id !== incomingAction.file.id), { ...incomingAction.file }], error: null, loading: false });
        case 'FILE_NEW_UPLOAD_SUCCESS':
            return updateObject(state, { files: [...state.files.filter((x: MediaFile) => x.id !== incomingAction.file.id), { ...incomingAction.file }], error: null, loading: false });
        case 'FILE_NEW_UPLOAD_START':
            return updateObject(state, { error: null, loading: true });
        default:
            return state;
    }
}