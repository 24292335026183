import { DialogTitle, DialogContent, DialogActions, Button, List, ListItem, ListItemText, Checkbox, FormControlLabel, Divider, Paper, styled } from '@mui/material';
import React, { useState } from 'react';
import { TemplateData } from '../../../store/Template';

const PREFIX = 'BlockBuilderContent';

const classes = {
    root: `${PREFIX}-root`,
};

const DialogContentStyled = styled(DialogContent)((
    { theme }
) => ({
    [`&.${classes.root}`]: {
        '& > *': {
            marginTop: theme.spacing(1),
        },
    },
}));

interface Props {
    templates: TemplateData[],
    toggleTemplateListModal: () => void,
    submitTemplateListModal: (template: TemplateData, referenced: boolean) => void
}

const TemplateListModal = (props: Props) => {

    const { toggleTemplateListModal, submitTemplateListModal, templates } = props;
    const [referenced, setReferenced] = useState(true);

    return (
        <>
            <DialogTitle id="form-dialog-title">Select Template</DialogTitle>
            <DialogContentStyled className={classes.root}>

                <FormControlLabel
                    value="end"
                    control={<Checkbox color="primary" checked={referenced} onChange={(e) => setReferenced(prevState => !prevState)} />}
                    label="Referenced"
                    labelPlacement="end"
                />
                <Paper elevation={3}>
                    <List>
                        <Divider />
                        {
                            templates && templates.map((templ: TemplateData) => (
                                <React.Fragment key={templ.id}>
                                    <ListItem button onClick={() => submitTemplateListModal(templ, referenced)}>
                                        <ListItemText primary={templ.name} />
                                    </ListItem>
                                    <Divider />
                                </React.Fragment>
                            ))
                        }
                    </List>
                </Paper>
            </DialogContentStyled>
            <DialogActions>
                <Button onClick={toggleTemplateListModal} color="primary">
                    Cancel
                </Button>
            </DialogActions>
        </>
    )
}

export default TemplateListModal;