import * as React from 'react';
import useDeepEffect from '../../hooks/useDeepEffect';
import { Signal } from '../../services/events/Signal';
import { BlockEvent, EventTriggerType, EventType } from '../../store/Block';
import { DynDataEntryProp } from '../../store/DynData';

interface EventContext {
  events: Signal[],
  createEvent: (eventData: BlockEvent, callback: (triggers: string[]) => void) => void,
  triggerEvent: (name: number, sourceId: number, triggerType: EventTriggerType, height?: number) => void,
  unTriggerEvent: (name: number, sourceId: number) => void,
  unTriggerEventOnClickAway: (eventId: number, sourceId: number) => void,
  resetContext: () => void,
  registerInput: (formId: number, required: boolean, getData: () => any, addAdditionalClass: (newClass: string) => void, removeAdditionalClass: (removeClass: string) => void) => void,
  registerForm: (formId: number, addAdditionalClass: (newClass: string) => void, removeAdditionalClass: (removeClass: string) => void) => void,
  submitEvent: (formId: number) => void,
}

interface WaitingInitialTriggers {
  eventId: number,
  sourceId: number,
  triggerType: EventTriggerType
}

export const EventContext = React.createContext<EventContext>({
  events: [],
  createEvent: () => { },
  triggerEvent: () => { },
  submitEvent: () => { },
  unTriggerEventOnClickAway: () => { },
  unTriggerEvent: () => { },
  resetContext: () => { },
  registerInput: () => { },
  registerForm: () => { },
});

interface Props {
  children: React.ReactNode,
}

interface RegisteredInput {
  formId: number,
  getData: () => DynDataEntryProp,
  addAdditionalClass: (newClass: string) => void,
  removeAdditionalClass: (removeClass: string) => void,
  required: boolean,
}

interface RegisteredForm {
  formId: number,  
  addAdditionalClass: (newClass: string) => void,
  removeAdditionalClass: (removeClass: string) => void,
}

const BlockEventContext = (props: Props) => {
  const { children } = props;
  const [signals, setSignals] = React.useState<Signal[]>([]);
  const [eventsBlocked, setEventsBlocked] = React.useState<number[]>([]);
  const [clickAwayBlocked, setClickAwayBlocked] = React.useState<number[]>([]);
  const [waitingInitialTriggers, setWaitingInitialTriggers] = React.useState<WaitingInitialTriggers[]>([]);
  const [registeredInputs, setRegisteredInputs] = React.useState<RegisteredInput[]>([]);
  const [registeredForms, setRegisteredForms] = React.useState<RegisteredForm[]>([]);

  const singalRef = React.useRef(signals);
  const clickAwayRef = React.useRef(clickAwayBlocked);

  useDeepEffect(() => {
    singalRef.current = signals;
  }, [signals]);

  useDeepEffect(() => {
    clickAwayRef.current = clickAwayBlocked;
  }, [clickAwayBlocked])

  React.useEffect(() => {
    let removeWaitingTriggers: WaitingInitialTriggers[] = [];

    waitingInitialTriggers.forEach(waitingTrigger => {
      if (waitingTrigger) {
        TriggerEventHandler(waitingTrigger.eventId, waitingTrigger.sourceId, waitingTrigger.triggerType);
        removeWaitingTriggers.push(waitingTrigger);
      }
    });

    setWaitingInitialTriggers(waitingInitialTriggers.filter(x => !removeWaitingTriggers.includes(x)));
  }, [signals.length])

  const UnTriggerOnClickAway = (eventId: number, sourceId: number) => {

    const firedEvent = singalRef.current.find(x => x.BlockEvent.id === eventId);

    if (clickAwayRef.current.indexOf(eventId) > -1)
      return;

    if (firedEvent && firedEvent.isTriggered(sourceId)) {
      firedEvent.handleTriggerSources(0, undefined, true);

      const newSignal = singalRef.current.filter(x => x.BlockEvent.id !== eventId);

      newSignal.push(firedEvent);
      setSignals([...newSignal]);
      firedEvent.trigger(EventTriggerType.OnClick);
      setEventsBlocked(prev => [...prev, firedEvent.BlockEvent.id]);
      setTimeout(() => unblockEvent(firedEvent.BlockEvent.id), 500);
    }
  }

  const ResetContext = () => {
    setSignals([]);
  }

  const unblockEvent = (id: number) => {
    setEventsBlocked((prev: number[]) => prev.filter(x => x != id));
  }

  const unblockClickAway = (id: number) => {
    setClickAwayBlocked((prev: number[]) => prev.filter(x => x != id));
  }

  const CreateEventHandler = (event: BlockEvent, callback: (triggers: string[]) => void) => {
    setSignals(prev => {
      let newSignals: Signal[] = [...prev];

      if (prev.findIndex(x => x.BlockEvent.id === event.id) > -1) {
        newSignals = [...prev.filter(x => x.BlockEvent.id !== event.id)];
      }

      let newSignal = new Signal(event);
      newSignal.on(callback);
      newSignals.push(newSignal);
      return [...newSignals];
    });
  }

  const SubmitEvent = async (formId: number) => {

    let data: DynDataEntryProp[] = [];
    let success: boolean = true;
    const inputs = registeredInputs.filter(x => x.formId === formId)

    inputs.forEach(x => {
      const dataEntry = x.getData();

      if (x.required && dataEntry.value == "") {
        x.addAdditionalClass("input-error");
        success = false;
      } else {
        x.removeAdditionalClass("input-error")
      }

      data.push(dataEntry)
    });

    if (!success)
      return;

    const currentForm = registeredForms.find(x => x.formId === formId)

    const rawResponse = await fetch(`mail`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({formId, data})
    }).then(response => {
      if(response.ok) {
        currentForm?.addAdditionalClass("form-success");
        currentForm?.removeAdditionalClass("form-error");
      } else {
        currentForm?.addAdditionalClass("form-error");
        currentForm?.removeAdditionalClass("form-success");
      }
    });

  }

  const RegisterInput = (formId: number, required: boolean, getData: () => any, addAdditionalClass: (newClass: string) => void, removeAdditionalClass: (removeClass: string) => void) => {
    const newInput = { getData: getData, addAdditionalClass: addAdditionalClass, removeAdditionalClass: removeAdditionalClass, required: required, formId: formId };
    setRegisteredInputs(prev => [...prev, newInput]);
  }

  const RegisterForm = (formId: number, addAdditionalClass: (newClass: string) => void, removeAdditionalClass: (removeClass: string) => void) => {

    const newForm = { addAdditionalClass: addAdditionalClass, removeAdditionalClass: removeAdditionalClass, formId: formId };
    setRegisteredForms(prev => [...prev, newForm]);
  }
  
  const UnTriggerEventHandler = (eventId: number, sourceId: number) => {
    const firedEvent = signals.find(x => x.BlockEvent.id === eventId);

    if (firedEvent) {
      if (!firedEvent.isTriggered(sourceId))
        return;

      firedEvent?.handleTriggerSources(sourceId);


      const newSignal = signals.filter(x => x.BlockEvent.id !== eventId);

      newSignal.push(firedEvent);
      setSignals(newSignal);
      firedEvent.trigger(EventTriggerType.OnScroll);
    }
  }

  const TriggerEventHandler = (eventId: number, sourceId: number, triggerType: EventTriggerType, height?: number) => {
    if (eventsBlocked.indexOf(eventId) > -1)
      return;

    const firedEvent = signals.find(x => x.BlockEvent.id === eventId);

    if (firedEvent) {
      if (firedEvent.isTriggered(sourceId) && triggerType === EventTriggerType.OnScroll)
        return;

      firedEvent?.handleTriggerSources(sourceId, height);


      const newSignal = signals.filter(x => x.BlockEvent.id !== eventId);

      newSignal.push(firedEvent);
      setSignals(newSignal);
      firedEvent.trigger(triggerType);

      if (firedEvent.BlockEvent.unTriggerOnClickAway) {
        setClickAwayBlocked(prev => [...prev, firedEvent.BlockEvent.id]);
        setTimeout(() => unblockClickAway(firedEvent.BlockEvent.id), 200);
      }
    } else {
      setWaitingInitialTriggers([...waitingInitialTriggers, { eventId: eventId, sourceId: sourceId, triggerType: triggerType }]);
    }
  }

  return (
    <EventContext.Provider value={{
      events: signals,
      createEvent: CreateEventHandler,
      triggerEvent: TriggerEventHandler,
      unTriggerEventOnClickAway: UnTriggerOnClickAway,
      unTriggerEvent: UnTriggerEventHandler,
      resetContext: ResetContext,
      registerInput: RegisterInput,
      registerForm: RegisterForm,
      submitEvent: SubmitEvent
    }}>
      {children}
    </EventContext.Provider>
  )
}

export default BlockEventContext;