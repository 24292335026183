import React, { useContext, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { ApplicationState } from '../../store';
import BlockAppBar from './BlockAppBar';
import BlockMapDrawer from './BlockMapDrawer';
import { connect, useDispatch, useSelector } from 'react-redux';
import * as BlockStore from '../../store/Block';
import * as PageStore from '../../store/Page';
import * as TemplateStore from '../../store/Template';
import BlockMap from './BlockMap';
import BlockBuilderContent from './BlockBuilderContent';
import BlockCanvas from './BlockCanvas';
import BlockWall from './BlockWall';
import { Dialog } from '@mui/material';
import SaveAsTemplateModal from './modals/SaveAsTemplateModal';
import TemplateListModal from './modals/TemplateListModal';
import BlockEdit from './BlockEdit';
import { TemplateData } from '../../store/Template';
import ConfirmationDialog from '../UI/ConfirmationDialog';
import { selectFooter, selectFooterSelected, selectHeader, selectHeaderSelected, selectSelectedPage } from '../../store/selectors/PageSelector';
import { selectSelectedTemplate, selectTemplates } from '../../store/selectors/TemplateSelector';
import { selectBlockClipboard, selectBlocks, selectGlobalBlockStyles, selectSelectedBlock } from '../../store/selectors/BlockSelector';
import { EventContext } from '../Contexts/BlockEventContext';
import { useNavigate } from 'react-router-dom';
import { Language } from '../../store/Language';
import { selectLanguages } from '../../store/selectors/ConfigSelector';
import BlockEditGlobal from './BlockEditGlobal';
import { GetStyle } from '../../Utility';

/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react';

const PREFIX = 'BlockBuilder';

const classes = {
    editDrawerRoot: `${PREFIX}-editDrawerRoot`
};

const Root = styled('div')((
    {
        theme
    }
) => ({
    [`& .${classes.editDrawerRoot}`]: {
        display: 'flex',
        height: '100%',
    }
}));

const BlockBuilder = () => {


    const eventContext = useContext(EventContext);
    const globalStyles = useSelector(selectGlobalBlockStyles)
    const [leftDrawerOpen, setLeftDrawerOpen] = useState(true);

    const [selectedViewMode, setSelectedViewMode] = useState<string>("real");
    const [canvasMode, setCanvasMode] = useState<string>("xs");
    const [scrollHeight, setScrollHeight] = useState<number>(0);
    const [canvasHeight, setCanvasHeight] = useState<number>(0);
    const [templateListModalOpen, setTemplateListModalOpen] = useState(false);

    const [templateModalOpen, setTemplateModalOpen] = useState(false);
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [selectedEntryIndex, setSelectedEntryIndex] = useState(0);

    let history = useNavigate();
    const dispatch = useDispatch();
    const pageSelected = useSelector(selectSelectedPage);
    const templateSelected = useSelector(selectSelectedTemplate);
    const headerSelected = useSelector(selectHeaderSelected);
    const footerSelected = useSelector(selectFooterSelected)
    const headerData = useSelector(selectHeader);
    const footerData = useSelector(selectFooter);
    const blocks = useSelector(selectBlocks);
    const selectedBlock = useSelector(selectSelectedBlock);
    const blockClipboard = useSelector(selectBlockClipboard);
    const templates = useSelector(selectTemplates);

    useEffect(() => {
        if (pageSelected) {
            dispatch(BlockStore.actionCreators.getBlocks("page", pageSelected.id));
        } else if (templateSelected) {
            dispatch(BlockStore.actionCreators.getBlocks("template", templateSelected.id));
        } else if (headerSelected && headerData) {
            dispatch(BlockStore.actionCreators.getBlocks("header", 0));
        } else if (footerSelected && footerData) {
            dispatch(BlockStore.actionCreators.getBlocks("footer", 0));
        } else {
            history('/');
        }

        dispatch(TemplateStore.actionCreators.getTemplates());

        return function () {
            dispatch(BlockStore.actionCreators.selectBlock());
            dispatch(PageStore.actionCreators.selectPage(null));
            dispatch(TemplateStore.actionCreators.selectTemplate(null));
            dispatch(PageStore.actionCreators.selectHeader(false));
            dispatch(PageStore.actionCreators.selectFooter(false));
            eventContext.resetContext();
        }
    }, []);

    const handlePageCount = (nextIndex: number) => {
        setSelectedEntryIndex(prev => prev + nextIndex);
    }

    const toggleConfirmOpen = () => {
        setConfirmOpen(prevState => !prevState);
    }

    const handleLeftDrawerToggle = () => {
        setLeftDrawerOpen(prev => !prev);
    }

    const toggleNewTemplateModal = () => {
        setTemplateModalOpen(prevState => !prevState);
    };

    const deleteBlockHandler = () => {
        dispatch(BlockStore.actionCreators.deleteBlock());
    }

    const copyBlocksHandler = () => {
        dispatch(BlockStore.actionCreators.copyBlock());
    }

    const pasteBlocksHandler = () => {
        if (pageSelected) {
            dispatch(BlockStore.actionCreators.pasteBlocks("page", pageSelected.id));
        } else if (templateSelected) {
            dispatch(BlockStore.actionCreators.pasteBlocks("template", templateSelected.id));
        } else if (headerSelected && headerData) {
            dispatch(BlockStore.actionCreators.pasteBlocks("header", 0));
        } else if (footerSelected && footerData) {
            dispatch(BlockStore.actionCreators.pasteBlocks("footer", 0));
        }
    }

    const sortBlockUpHandler = () => {
        dispatch(BlockStore.actionCreators.sortBlocks(-1));
    }

    const sortBlockDownHandler = () => {
        dispatch(BlockStore.actionCreators.sortBlocks(1));
    }

    const createNewBlockHandler = (type: string) => {
        if (pageSelected) {
            dispatch(BlockStore.actionCreators.createBlock(type, "page", pageSelected.id));
        } else if (templateSelected) {
            dispatch(BlockStore.actionCreators.createBlock(type, "template", templateSelected.id));
        } else if (headerSelected && headerData) {
            dispatch(BlockStore.actionCreators.createBlock(type, "header", 0));
        } else if (footerSelected && footerData) {
            dispatch(BlockStore.actionCreators.createBlock(type, "footer", 0));
        }
    }

    const updateBlockHandler = (block: BlockStore.BlockData) => {
        dispatch(BlockStore.actionCreators.updateBlock(block));
    }

    const addBlockStyleHandler = (newBlockStyle: BlockStore.BlockStyle) => {
        dispatch(BlockStore.actionCreators.createBlockStyle(newBlockStyle));
    }

    const deleteBlockStyleHandler = (styleId: number) => {
        dispatch(BlockStore.actionCreators.deleteBlockStyle(styleId));
    }

    const updateBlockStyleHandler = (updatedBlockStyle: BlockStore.BlockStyle) => {
        dispatch(BlockStore.actionCreators.updateBlockStyle(updatedBlockStyle));
    }

    const addEventHandler = (blockEvent: BlockStore.BlockEvent) => {
        dispatch(BlockStore.actionCreators.createEvent(blockEvent));
    }

    const deleteEventHandler = (eventId: number) => {
        dispatch(BlockStore.actionCreators.deleteEvent(eventId));
    }

    const updateEventHandler = (updatedEvent: BlockStore.BlockEvent) => {
        dispatch(BlockStore.actionCreators.updateEvent(updatedEvent));
    }

    const addEventTriggerHandler = (blockEventTrigger: BlockStore.BlockEventTrigger) => {
        dispatch(BlockStore.actionCreators.createEventTrigger(blockEventTrigger));
    }

    const deleteEventTriggerHandler = (eventTriggerId: number) => {
        dispatch(BlockStore.actionCreators.deleteEventTrigger(eventTriggerId));
    }

    const updateEventTriggerHandler = (updatedEventTrigger: BlockStore.BlockEventTrigger) => {
        dispatch(BlockStore.actionCreators.updateEventTrigger(updatedEventTrigger));
    }

    const submitNewTemplateHandler = (templateName: string) => {
        toggleNewTemplateModal();
        let newTemplate: TemplateData = {
            id: 0,
            name: templateName,
        };
        dispatch(TemplateStore.actionCreators.createTemplate(newTemplate));
    }

    const submitTemplateListModal = (template: TemplateData, referenced: boolean) => {
        setTemplateListModalOpen(false);

        if (pageSelected) {
            dispatch(BlockStore.actionCreators.appendBlockTemplate(template.id, referenced, "page", pageSelected.id));
        } else if (templateSelected) {
            dispatch(BlockStore.actionCreators.appendBlockTemplate(template.id, referenced, "template", templateSelected.id));
        } else if (headerSelected && headerData) {
            dispatch(BlockStore.actionCreators.appendBlockTemplate(template.id, referenced, "header", 0));
        } else if (footerSelected && footerData) {
            dispatch(BlockStore.actionCreators.appendBlockTemplate(template.id, referenced, "footer", 0));
        }
    }

    let blockEditSection = <BlockEditGlobal
        addBlockStyleHandler={addBlockStyleHandler}
        updateBlockStyleHandler={updateBlockStyleHandler}
        deleteBlockStyleHandler={deleteBlockStyleHandler} />;

    if (selectedBlock !== undefined)
        blockEditSection = <BlockEdit selectedBlock={selectedBlock!}
            updateBlockHandler={updateBlockHandler}
            addBlockStyleHandler={addBlockStyleHandler}
            updateBlockStyleHandler={updateBlockStyleHandler}
            deleteBlockStyleHandler={deleteBlockStyleHandler}
            addEventHandler={addEventHandler}
            updateEventHandler={updateEventHandler}
            deleteEventHandler={deleteEventHandler}
            addEventTriggerHandler={addEventTriggerHandler}
            updateEventTriggerHandler={updateEventTriggerHandler}
            deleteEventTriggerHandler={deleteEventTriggerHandler}
            dynamic={pageSelected?.dynamic ?? false}
            selectedPage={pageSelected} />

    return (
        <Root className={"builder-root"}>
            <BlockMapDrawer isOpen={leftDrawerOpen}>
                <div className={classes.editDrawerRoot}>
                    <BlockMap blocks={blocks}
                        selectedBlock={selectedBlock} />
                    {blockEditSection}                       
                </div>
            </BlockMapDrawer>
            <BlockBuilderContent isLeftOpen={leftDrawerOpen} >
                <BlockCanvas setCanvasMode={(mode: string) => setCanvasMode(mode)} setScrollHeight={(height) => setScrollHeight(height)} setCanvasHeight={(height) => setCanvasHeight(height)}>
                    <div css={GetStyle(canvasMode, undefined, globalStyles)} style={{ position: 'relative', width: '100%', height: '100%' }}>
                        {
                            pageSelected && pageSelected.withNavbar && headerData.length ? <BlockWall
                                blocks={headerData}
                                selectedBlockId={selectedBlock?.id ?? 0}
                                disabled={true}
                                canvasMode={canvasMode}
                                viewMode="real"
                                scrollHeight={scrollHeight}
                                canvasHeight={canvasHeight}
                            /> : null
                        }
                        <BlockWall
                            blocks={blocks}
                            selectedBlockId={selectedBlock?.id ?? 0}
                            disabled={false}
                            viewMode={selectedViewMode}
                            canvasMode={canvasMode}
                            scrollHeight={scrollHeight}
                            canvasHeight={canvasHeight}
                            dataEntry={pageSelected?.dynDataSet?.dynDataEntries[selectedEntryIndex]}
                        />
                        {
                            pageSelected && pageSelected.withFooter && footerData.length ? <BlockWall
                                blocks={footerData}
                                selectedBlockId={selectedBlock?.id ?? 0}
                                disabled={true}
                                canvasMode={canvasMode}
                                viewMode="real"
                                scrollHeight={scrollHeight}
                                canvasHeight={canvasHeight}
                            /> : null
                        }

                    </div> 
                </BlockCanvas>

                <BlockAppBar
                    toggleLeftOpenHandler={handleLeftDrawerToggle}
                    isLeftOpen={leftDrawerOpen}
                    blocks={blocks}
                    createNewTemplate={toggleNewTemplateModal}
                    createNewBlockHandler={createNewBlockHandler}
                    deleteBlock={toggleConfirmOpen}
                    copyBlocks={copyBlocksHandler}
                    pasteBlocks={pasteBlocksHandler}
                    sortDown={sortBlockDownHandler}
                    sortUp={sortBlockUpHandler}
                    selectedViewMode={selectedViewMode}
                    selectViewMode={setSelectedViewMode}
                    selectedBlock={selectedBlock}
                    headerSelected={headerSelected}
                    toggleTemplateListOpen={() => setTemplateListModalOpen(true)}
                    clipboardEmpty={blockClipboard === ""}
                    pageDataSet={pageSelected?.dynDataSet}
                    handlePageCount={handlePageCount}
                    pageIndex={selectedEntryIndex} />
                <Dialog fullWidth maxWidth="md" open={templateModalOpen} onClose={toggleNewTemplateModal} >
                    <SaveAsTemplateModal toggleTemplateModal={toggleNewTemplateModal} submitTemplateModal={submitNewTemplateHandler} />
                </Dialog>
                <Dialog fullWidth maxWidth="md" open={templateListModalOpen} onClose={() => setTemplateListModalOpen(false)} >
                    <TemplateListModal templates={templates} submitTemplateListModal={submitTemplateListModal} toggleTemplateListModal={() => setTemplateListModalOpen(prev => !prev)} />
                </Dialog>
            </BlockBuilderContent>
            <ConfirmationDialog isOpen={confirmOpen} clickHandler={toggleConfirmOpen} submitFunction={deleteBlockHandler} />
        </Root>
    );
}

export default BlockBuilder;