import { AppBar, Button, Grid, styled, Toolbar } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actionCreators, MailConnection, MailTemplate } from '../../store/Configuration';
import LanguageTable from './LanguageTable';
import MailConnectionTable from './MailConnectionTable';
import MailTemplateTable from './MailTemplateTable';
import AddBoxSharpIcon from '@mui/icons-material/AddBoxSharp';
import MailConnectionDialog from './MailConnectionDialog';
import { selectLanguages, selectMailConnections, selectMailTemplates } from '../../store/selectors/ConfigSelector';
import MailTemplateDialog from './MailTemplateDialog';
import { Language } from '../../store/Language';
import LanguageDialog from './LanguageDialog';

const PREFIX = 'Configuration';
const classes = {
    root: `${PREFIX}-root`,
    button: `${PREFIX}-button`,
    gridroot: `${PREFIX}-gridroot`,
};

const Root = styled('div')((
    {
        theme
    }
) => ({
    [`& .${classes.root}`]: {
        marginLeft: '239px',
    },
    [`& .${classes.button}`]: {
        marginLeft: theme.spacing(1),
    },
    [`& .${classes.gridroot}`]: {
        padding: theme.spacing(1),
    },
}));

const Configuration = () => {

    const dispatch = useDispatch();
    const mailConnections = useSelector(selectMailConnections);
    const languages = useSelector(selectLanguages);
    const mailTemplates = useSelector(selectMailTemplates);
    const [currentMailConnection, SetCurrentMailConnection] = React.useState<MailConnection | undefined>(undefined);
    const [mailConnectionDialogOpen, SetMailConnectionDialogOpen] = React.useState(false);
    const [currentMailTemplate, SetCurrentMailTemplate] = React.useState<MailTemplate | undefined>(undefined);
    const [mailTemplateDialogOpen, SetMailTemplateDialogOpen] = React.useState(false);
    const [currentLanguage, setCurrentLanguage] = React.useState<Language | undefined>(undefined);
    const [languageDialogOpen, SetLanguageDialogOpen] = React.useState(false);


    React.useEffect(() => {
        dispatch(actionCreators.getConfig())
    }, [])

    const submitConnectionDialog = (con: MailConnection) => {
        if(con.id === 0)
            dispatch(actionCreators.createMailConnection(con));
        else 
            dispatch(actionCreators.updateMailConnection(con));
    }

    const closeConnectionDialog = () => {
        SetCurrentMailConnection(undefined);
        SetMailConnectionDialogOpen(false);
    }

    const editMailConnection = (id: number) => {
        const currentCon = mailConnections.find(x => x.id === id);

        if(currentCon)
        {
            SetCurrentMailConnection(currentCon);
            SetMailConnectionDialogOpen(true);
        }
    }

    const submitTemplateDialog = (template: MailTemplate) => {
        if(template.id === 0)
            dispatch(actionCreators.createMailTemplate(template));
        else 
            dispatch(actionCreators.updateMailTemplate(template));
    }

    const editMailTemplate = (id: number) => {
        const currentCon = mailTemplates.find(x => x.id === id);

        if(currentCon)
        {
            SetCurrentMailTemplate(currentCon);
            SetMailTemplateDialogOpen(true);
        }
    }

    const closeTemplateDialog = () => {
        SetCurrentMailTemplate(undefined);
        SetMailTemplateDialogOpen(false);
    }
    
    const submitLanguageDialog = (language: Language) => {
        if (language.id === 0)
            dispatch(actionCreators.createLanguage(language));
        else
            dispatch(actionCreators.updateLanguage(language));
    }

    const editLanguage = (id: number) => {
        const currentCon = languages.find(x => x.id === id);

        if (currentCon) {
            setCurrentLanguage(currentCon);
            SetLanguageDialogOpen(true);
        }
    }

    const closeLanguageDialog = () => {
        setCurrentLanguage(undefined);
        SetLanguageDialogOpen(false);
    }

    return (
        <Root>
            <div className={classes.root}>
                <AppBar position="static" color="primary">
                    <Toolbar disableGutters>
                        <Button className={classes.button} onClick={() => SetMailConnectionDialogOpen(true)} variant="contained" color="secondary" startIcon={<AddBoxSharpIcon />}>
                            Add Mail Connection
                        </Button>
                        <Button className={classes.button} onClick={() => SetMailTemplateDialogOpen(true)} variant="contained" color="secondary" startIcon={<AddBoxSharpIcon />}>
                            Add Mail Template
                        </Button>
                        <Button className={classes.button} onClick={() => SetLanguageDialogOpen(true)} variant="contained" color="secondary" startIcon={<AddBoxSharpIcon />}>
                            Add Language
                        </Button>
                    </Toolbar>
                </AppBar>
                <div className={classes.gridroot}>
                    <Grid container spacing={2} padding={2}>
                        <Grid item xs={6}>
                            <MailConnectionTable mailConnections={mailConnections} editMailConnection={editMailConnection}/>
                        </Grid>
                        <Grid item xs={6}>
                            <MailTemplateTable mailTemplates={mailTemplates} editMailTemplate={editMailTemplate}/>
                        </Grid>
                        <Grid item xs={6}>
                            <LanguageTable languages={languages} editLanguage={editLanguage} />
                        </Grid>
                    </Grid>
                </div>
            </div>
            <MailTemplateDialog handleClose={closeTemplateDialog} mailTemplate={currentMailTemplate} open={mailTemplateDialogOpen} handleSubmit={submitTemplateDialog}/>
            <MailConnectionDialog handleClose={closeConnectionDialog} mailConnection={currentMailConnection} open={mailConnectionDialogOpen} handleSubmit={submitConnectionDialog}/>
            <LanguageDialog handleClose={closeLanguageDialog} language={currentLanguage} open={languageDialogOpen} handleSubmit={submitLanguageDialog} />
        </Root>
    )
}

export default Configuration;