import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { DynModel } from '../../store/DynData';
import useDeepEffect from '../../hooks/useDeepEffect';
import DynPropControl from './DynPropControl';
import { Stack, styled } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

const PREFIX = 'DynModelDialog';

const classes = {
    formControl: `${PREFIX}-formControl`,
    dialogContent: `${PREFIX}-dialogContent`,
    addButton: `${PREFIX}-addButton`
};

const StyledDialog = styled(Dialog)((
    {
        theme
    }
) => ({
    [`& .${classes.formControl}`]: {
        width: '100%',
    },
    [`& .${classes.dialogContent}`]: {
        '& > *': {
            marginBottom: theme.spacing(1)
        },
    },
    [`& .${classes.addButton}`]: {
        width: '20px',
        minWidth: '20px',
        height: 'inherit'
    }
}));

interface Props {
    dialogOpen: boolean,
    closeDialog: () => void,
    submitDialog: (model: DynModel) => void,
    currentModel: DynModel | undefined
}

export default function DynModelDialog(props: Props) {
    const { dialogOpen, closeDialog, submitDialog, currentModel } = props;

    const defaultDynModel: DynModel = { id: 0, dynPropSets: [], name: '' };
    const [model, setModel] = React.useState<DynModel>(defaultDynModel);

    useDeepEffect(() => {
        if (currentModel === undefined) {
            setModel(defaultDynModel);
        } else {
            setModel(currentModel);
        }

    }, [currentModel]);

    const handleOnClose = () => {
        setModel(defaultDynModel);
        closeDialog();
    }

    const handleOnSubmit = () => {
        submitDialog(model);
        handleOnClose();
    }   

    const handlePropTagChange = (value: string, id: number) => {
        let newModel: DynModel = { ...model };
        const newDynProps = newModel.dynPropSets.find(x => x.id === id);
        if (newDynProps) {
            newDynProps.tag = value;
            newModel.dynPropSets = [...newModel.dynPropSets.filter(x => x.id !== newDynProps.id), newDynProps]
            setModel(newModel);
        }
    }

    const handlePropTypeChange = (value: string, id: number) => {
        let newModel: DynModel = { ...model };
        const newDynProps = newModel.dynPropSets.find(x => x.id === id);
        if (newDynProps) {
            newDynProps.type = value;
            newModel.dynPropSets = [...newModel.dynPropSets.filter(x => x.id !== newDynProps.id), newDynProps]
            setModel(newModel);
        }
    }

    const handleNewProps = () => {
        let newId = 999 + Math.random().toString().substr(2, 5);
        let newModel = { ...model };
        newModel.dynPropSets.push({ id: Number(newId), type: 'text', tag: '' });
        setModel(newModel);
    }

    const handleDeleteProps = (id: number) => {
        let newStyle = { ...model };
        let style = [...newStyle.dynPropSets];
        newStyle.dynPropSets = style.filter(x => x.id !== id);
        setModel(newStyle);
    }

    return (
        <StyledDialog open={dialogOpen} onClose={closeDialog}>
            <DialogTitle>New model</DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <TextField
                    autoFocus
                    label="Name"
                    type="text"
                    value={model.name}
                    onChange={(e) => setModel({...model, name: e.target.value}) }
                    fullWidth
                />
                <Stack direction="row" spacing={2}>
                    <Button className={classes.addButton} variant="contained" onClick={handleNewProps}>
                        <AddIcon />
                    </Button>
                    <DynPropControl model={model} handleDeleteProps={handleDeleteProps} handlePropTagChange={handlePropTagChange} handlePropTypeChange={handlePropTypeChange}/>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleOnClose}>Cancel</Button>
                <Button onClick={handleOnSubmit}>Save</Button>
            </DialogActions>
        </StyledDialog>
    );
}