import { BlockData, BlockStyle, BlockStyleData, MediaBlockData } from "./store/Block";

export const updateObject = (oldObject: any, updatedProps: any) => {
   return {
      ...oldObject,
      ...updatedProps
   }
};

interface styleObj {
   styleValue: string | styleObj,
   [key: string]: string | styleObj
}

export const GetStyles = (blockStyle: BlockStyle[] | undefined): styleObj => {
   let styles: styleObj = {} as styleObj;
   if (blockStyle) {
      blockStyle
         .forEach((style: BlockStyle) => {
            if (style && style.blockStyleData?.length) {


               let styleContainer: styleObj = {} as styleObj;
               if (style.selector !== '') {
                  styles[style.selector] = styleContainer;
               }
               else {
                  styleContainer = styles;
               }

               const blockStyleData = style.blockStyleData.sort((a: BlockStyleData, b: BlockStyleData) => a.id - b.id);
               for (let i: number = 0; i < blockStyleData.length; i++) {
                  const styleEntry = StyleTable.find(x => x.name === blockStyleData[i].attributeKey);

                  if (styleEntry) {
                     if (styleEntry.reactName) {
                        styleContainer[styleEntry.reactName] = blockStyleData[i].attributeValue;
                     }
                  }
               }
            }
         });
   }
   return styles;
}

export const GetStyle = (canvasMode: string, block: BlockData | undefined, mainBlockStyles?: BlockStyle[]): any => {
   let styles = {};

   const blockStyles =
      mainBlockStyles !== undefined ?
         mainBlockStyles
         : block ?
            block.templateReference != null ?
               [...getReferencedBlockStyles(block.templateReference), ...block.blockStyles]
               : block.blockStyles
            : [];



   if (canvasMode === 'xs') {
      let xsStyle = blockStyles?.filter(x => x.screenMode === 'xs');
      styles = GetStyles(xsStyle);
   } else if (canvasMode === 'sm') {
      let xsStyle = blockStyles?.filter(x => x.screenMode === 'xs');
      styles = GetStyles(xsStyle);

      let smStyle = blockStyles?.filter(x => x.screenMode === 'sm');
      styles = combineStyles(styles, GetStyles(smStyle));
   } else if (canvasMode === 'md') {
      let xsStyle = blockStyles?.filter(x => x.screenMode === 'xs');
      styles = GetStyles(xsStyle);

      let smStyle = blockStyles?.filter(x => x.screenMode === 'sm');
      styles = combineStyles(styles, GetStyles(smStyle));

      let mdStyle = blockStyles?.filter(x => x.screenMode === 'md');
      styles = combineStyles(styles, GetStyles(mdStyle));
   } else if (canvasMode === 'lg') {
      let xsStyle = blockStyles?.filter(x => x.screenMode === 'xs');
      styles = GetStyles(xsStyle);
      let smStyle = blockStyles?.filter(x => x.screenMode === 'sm');
      styles = combineStyles(styles, GetStyles(smStyle));

      let mdStyle = blockStyles?.filter(x => x.screenMode === 'md');
      styles = combineStyles(styles, GetStyles(mdStyle));

      let lgStyle = blockStyles?.filter(x => x.screenMode === 'lg');
      styles = combineStyles(styles, GetStyles(lgStyle));
   } else if (canvasMode === 'xl' || canvasMode === 'xxl') {
      let xsStyle = blockStyles?.filter(x => x.screenMode === 'xs');
      styles = GetStyles(xsStyle);

      let smStyle = blockStyles?.filter(x => x.screenMode === 'sm');
      styles = combineStyles(styles, GetStyles(smStyle));

      let mdStyle = blockStyles?.filter(x => x.screenMode === 'md');
      styles = combineStyles(styles, GetStyles(mdStyle));

      let lgStyle = blockStyles?.filter(x => x.screenMode === 'lg');
      styles = combineStyles(styles, GetStyles(lgStyle));

      let xlStyle = blockStyles?.filter(x => x.screenMode === 'xl');
      styles = combineStyles(styles, GetStyles(xlStyle));
   }

   return styles;
}

const combineStyles = (firstObj: object, secondObj: object): object => {
   let newObj = {...firstObj, ...secondObj};
   
   for (const [key, value] of Object.entries(newObj)) {
      if(Object.prototype.toString.call(value) === "[object Object]" && secondObj.hasOwnProperty(key) && firstObj.hasOwnProperty(key)){
         let newValue = {
            [key]: {...(firstObj[key as keyof typeof firstObj] as object), ...(secondObj[key as keyof typeof secondObj] as object) }
         }

         newObj = {...newObj, ...newValue};
      }
   }

   return newObj;
}

export const getTagName = (block: BlockData) => {

   switch (block.type) {
      case 'base':
         return 'div';
      case 'media':
         const mediaBlock = block as MediaBlockData;

         if (mediaBlock.mediaFile?.path.endsWith('.mp4'))
            return 'video';

         return 'img';
      case 'text':
         return 'text';
      case 'link':
         return 'link';
      case 'header':
         return 'header';
      case 'container':
         return 'container';
      case 'row':
         return 'row';
      case 'col':
         return 'col';
      case 'slider':
         return 'slider';
      case 'section':
         return 'section';
      case 'iframe':
         return 'iframe';
      case 'beforeafter':
         return 'beforeafter';
      case 'countup':
         return 'countup';
      case 'custom':
         return 'custom';
      case 'input':
         return 'input';
      case 'form':
         return 'form';

   }
}

export const getReferencedBlockStyles = (blockData: BlockData): BlockStyle[] => {
   
   if(blockData.templateReference !== null){
      const referenceStyles = getReferencedBlockStyles(blockData.templateReference);
      return [...referenceStyles, ...blockData.blockStyles];
   }

   if(blockData?.blockStyles)
      return [...blockData.blockStyles];

   return [];
}

export const AnimationNames = [
   "bounce",
   "flash",
   "pulse",
   "rubberBand",
   "shakeX",
   "shakeY",
   "headShake",
   "swing",
   "tada",
   "wobble",
   "jello",
   "heartBeat",
   "backInDown",
   "backInLeft",
   "backInRight",
   "backInUp",
   "backOutDown",
   "backOutLeft",
   "backOutRight",
   "backOutUp",
   "bounceIn",
   "bounceInDown",
   "bounceInLeft",
   "bonceInRight",
   "bounceInUp",
   "bounceOut",
   "bounceOutDown",
   "bounceOutLeft",
   "bounceOutRight",
   "bounceOutUp",
   "fadeIn",
   "fadeInDown",
   "fadeInDownBig",
   "fadeInLeft",
   "fadeInLeftBig",
   "fadeInRight",
   "fadeInRightBig",
   "fadeInUp",
   "fadeInUpBig",
   "fadeInTopLeft",
   "fadeInTopRight",
   "fadeInBottomLeft",
   "fadeInBottomRight",
   "fadeOut",
   "fadeOutDown",
   "fadeOutDownBig",
   "fadeOutLeft",
   "fadeOutLeftBig",
   "fadeOutRight",
   "fadeOutRightBig",
   "fadeOutUp",
   "fadeOutUpBig",
   "fadeOutTopLeft",
   "fadeOutTopRight",
   "fadeOutBottomRight",
   "fadeOutBottomLeft",
   "flip",
   "flipInX",
   "flipInY",
   "flipOutX",
   "flipOutY",
   "lightSpeedInRight",
   "lightSpeedInLeft",
   "leftSpeedOutRight",
   "leftSpeedOutLeft",
   "rotateIn",
   "rotateInDownLeft",
   "rotateInDownRight",
   "rotateInUpLeft",
   "rotateInUpRight",
   "rotateOut",
   "rotateOutDownLeft",
   "rotateOutDownRight",
   "rotateOutUpLeft",
   "rotateOutUpRight",
   "hinge",
   "jackInTheBox",
   "rollIn",
   "rollOut",
   "zoomIn",
   "zoomInDown",
   "zoomInLeft",
   "zoomInRight",
   "zoomInUp",
   "zoomOut",
   "zoomOutDown",
   "zoomOutLeft",
   "zoomOutRight",
   "zoomOutUp",
   "slideInDown",
   "slideInLeft",
   "slideInRight",
   "slideInUp",
   "slideOutDown",
   "slideOutLeft",
   "slideOutRight",
   "slideOutUp",
]

export const DESKTOP = 'DESKTOP'
export const TABLET = 'TABLET'
export const PHONE = 'PHONE'

export const StyleTable = [
   {
      "name": "align-content",
      "reactName": "alignContent",
      "description": "Specifies the alignment between the lines inside a flexible container when the items do not use all available space",
      "inherited": false,
   },
   {
      "name": "align-items",
      "reactName": "alignItems",
      "description": "Specifies the alignment for items inside a flexible container",
      "inherited": false,
   },
   {
      "name": "align-self",
      "reactName": "alignSelf",
      "description": "Specifies the alignment for selected items inside a flexible container",
      "inherited": false,
   },
   {
      "name": "all",
      "reactName": "all",
      "description": "Resets all properties (except unicode-bidi and direction)",
      "inherited": false,
   },
   {
      "name": "animation",
      "reactName": "animation",
      "description": "A shorthand property for all the animation-* properties",
      "inherited": false,
   },
   {
      "name": "animation-delay",
      "reactName": "animationDelay",
      "description": "Specifies a delay for the start of an animation",
      "inherited": false,
   },
   {
      "name": "animation-direction",
      "reactName": "animationDirection",
      "description": "Specifies whether an animation should be played forwards, backwards or in alternate cycles",
      "inherited": false,
   },
   {
      "name": "animation-duration",
      "reactName": "animationDuration",
      "description": "Specifies how long an animation should take to complete one cycle",
      "inherited": false,
   },
   {
      "name": "animation-fill-mode",
      "reactName": "animationFillMode",
      "description": "Specifies a style for the element when the animation is not playing (before it starts, after it ends, or both)",
      "inherited": false,
   },
   {
      "name": "animation-iteration-count",
      "reactName": "animationIterationCount",
      "description": "Specifies the number of times an animation should be played",
      "inherited": false,
   },
   {
      "name": "animation-name",
      "reactName": "animationName",
      "description": "Specifies a name for the @keyframes animation",
      "inherited": false,
   },
   {
      "name": "animation-play-state",
      "reactName": "animationPlayState",
      "description": "Specifies whether the animation is running or paused",
      "inherited": false,
   },
   {
      "name": "animation-timing-function",
      "reactName": "animationTimingFunction",
      "description": "Specifies the speed curve of an animation",
      "inherited": false,
   },
   {
      "name": "backface-visibility",
      "reactName": "backfaceVisibility",
      "description": "Defines whether or not the back face of an element should be visible when facing the user",
      "inherited": false,
   },
   {
      "name": "background",
      "reactName": "background",
      "description": "A shorthand property for all the background-* properties",
      "inherited": false,
   },
   {
      "name": "background-attachment",
      "reactName": "backgroundAttachment",
      "description": "Sets whether a background image scrolls with the rest of the page, or is fixed",
      "inherited": false,
   },
   {
      "name": "background-blend-mode",
      "reactName": "backgroundBlendMode",
      "description": "Specifies the blending mode of each background layer (color/image)",
      "inherited": false,
   },
   {
      "name": "background-clip",
      "reactName": "backgroundClip",
      "description": "Defines how far the background (color or image) should extend within an element",
      "inherited": false,
   },
   {
      "name": "background-color",
      "reactName": "backgroundColor",
      "description": "Specifies the background color of an element",
      "inherited": false,
   },
   {
      "name": "background-image",
      "reactName": "backgroundImage",
      "description": "Specifies one or more background images for an element",
      "inherited": false,
   },
   {
      "name": "background-origin",
      "reactName": "backgroundOrigin",
      "description": "Specifies the origin position of a background image",
      "inherited": false,
   },
   {
      "name": "background-position",
      "reactName": "backgroundPosition",
      "description": "Specifies the position of a background image",
      "inherited": false,
   },
   {
      "name": "background-repeat",
      "reactName": "backgroundRepeat",
      "description": "Sets if/how a background image will be repeated",
      "inherited": false,
   },
   {
      "name": "background-size",
      "reactName": "backgroundSize",
      "description": "Specifies the size of the background images",
      "inherited": false,
   },
   {
      "name": "border",
      "reactName": "border",
      "description": "A shorthand property for border-width, border-style and border-color",
      "inherited": false,
   },
   {
      "name": "border-bottom",
      "reactName": "borderBottom",
      "description": "A shorthand property for border-bottom-width, border-bottom-style and border-bottom-color",
      "inherited": false,
   },
   {
      "name": "border-bottom-color",
      "reactName": "borderBottomColor",
      "description": "Sets the color of the bottom border",
      "inherited": false,
   },
   {
      "name": "border-bottom-left-radius",
      "reactName": "borderBottomLeftRadius",
      "description": "Defines the radius of the border of the bottom-left corner",
      "inherited": false,
   },
   {
      "name": "border-bottom-right-radius",
      "reactName": "borderBottomRightRadius",
      "description": "Defines the radius of the border of the bottom-right corner",
      "inherited": false,
   },
   {
      "name": "border-bottom-style",
      "reactName": "borderBottomStyle",
      "description": "Sets the style of the bottom border",
      "inherited": false,
   },
   {
      "name": "border-bottom-width",
      "reactName": "borderBottomWidth",
      "description": "Sets the width of the bottom border",
      "inherited": false,
   },
   {
      "name": "border-collapse",
      "reactName": "borderCollapse",
      "description": "Sets whether table borders should collapse into a single border or be separated",
      "inherited": true,
   },
   {
      "name": "border-color",
      "reactName": "borderColor",
      "description": "Sets the color of the four borders",
      "inherited": false,
   },
   {
      "name": "border-image",
      "reactName": "borderImage",
      "description": "A shorthand property for all the border-image-* properties",
      "inherited": false,
   },
   {
      "name": "border-image-outset",
      "reactName": "borderImageOutset",
      "description": "Specifies the amount by which the border image area extends beyond the border box",
      "inherited": false,
   },
   {
      "name": "border-image-repeat",
      "reactName": "borderImageRepeat",
      "description": "Specifies whether the border image should be repeated, rounded or stretched",
      "inherited": false,
   },
   {
      "name": "border-image-slice",
      "reactName": "borderImageSlice",
      "description": "Specifies how to slice the border image",
      "inherited": false,
   },
   {
      "name": "border-image-source",
      "reactName": "borderImageSource",
      "description": "Specifies the path to the image to be used as a border",
      "inherited": false,
   },
   {
      "name": "border-image-width",
      "reactName": "borderImageWidth",
      "description": "Specifies the width of the border image",
      "inherited": false,
   },
   {
      "name": "border-left",
      "reactName": "borderLeft",
      "description": "A shorthand property for all the border-left-* properties",
      "inherited": false,
   },
   {
      "name": "border-left-color",
      "reactName": "borderLeftColor",
      "description": "Sets the color of the left border",
      "inherited": false,
   },
   {
      "name": "border-left-style",
      "reactName": "borderLeftStyle",
      "description": "Sets the style of the left border",
      "inherited": false,
   },
   {
      "name": "border-left-width",
      "reactName": "borderLeftWidth",
      "description": "Sets the width of the left border",
      "inherited": false,
   },
   {
      "name": "border-radius",
      "reactName": "borderRadius",
      "description": "A shorthand property for the four border-*-radius properties",
      "inherited": false,
   },
   {
      "name": "border-right",
      "reactName": "borderRight",
      "description": "A shorthand property for all the border-right-* properties",
      "inherited": false,
   },
   {
      "name": "border-right-color",
      "reactName": "borderRightColor",
      "description": "Sets the color of the right border",
      "inherited": false,
   },
   {
      "name": "border-right-style",
      "reactName": "borderRightStyle",
      "description": "Sets the style of the right border",
      "inherited": false,
   },
   {
      "name": "border-right-width",
      "reactName": "borderRightWidth",
      "description": "Sets the width of the right border",
      "inherited": false,
   },
   {
      "name": "border-spacing",
      "reactName": "borderSpacing",
      "description": "Sets the distance between the borders of adjacent cells",
      "inherited": true,
   },
   {
      "name": "border-style",
      "reactName": "borderStyle",
      "description": "Sets the style of the four borders",
      "inherited": false,
   },
   {
      "name": "border-top",
      "reactName": "borderTop",
      "description": "A shorthand property for border-top-width, border-top-style and border-top-color",
      "inherited": false,
   },
   {
      "name": "border-top-color",
      "reactName": "borderTopColor",
      "description": "Sets the color of the top border",
      "inherited": false,
   },
   {
      "name": "border-top-left-radius",
      "reactName": "borderTopLeftRadius",
      "description": "Defines the radius of the border of the top-left corner",
      "inherited": false,
   },
   {
      "name": "border-top-right-radius",
      "reactName": "borderTopRightRadius",
      "description": "Defines the radius of the border of the top-right corner",
      "inherited": false,
   },
   {
      "name": "border-top-style",
      "reactName": "borderTopStyle",
      "description": "Sets the style of the top border",
      "inherited": false,
   },
   {
      "name": "border-top-width",
      "reactName": "borderTopWidth",
      "description": "Sets the width of the top border",
      "inherited": false,
   },
   {
      "name": "border-width",
      "reactName": "borderWidth",
      "description": "Sets the width of the four borders",
      "inherited": false,
   },
   {
      "name": "bottom",
      "reactName": "bottom",
      "description": "Sets the elements position, from the bottom of its parent element",
      "inherited": false,
   },
   {
      "name": "box-decoration-break",
      "reactName": "boxDecorationBreak",
      "description": "Sets the behavior of the background and border of an element at page-break, or, for in-line elements, at line-break.",
      "inherited": false,
   },
   {
      "name": "box-shadow",
      "reactName": "boxShadow",
      "description": "Attaches one or more shadows to an element",
      "inherited": false,
   },
   {
      "name": "box-sizing",
      "reactName": "boxSizing",
      "description": "Defines how the width and height of an element are calculated: should they include padding and borders, or not",
      "inherited": false,
   },
   {
      "name": "break-after",
      "reactName": "breakAfter",
      "description": "Specifies whether or not a page-, column-, or region-break should occur after the specified element",
      "inherited": false,
   },
   {
      "name": "break-before",
      "reactName": "breakBefore",
      "description": "Specifies whether or not a page-, column-, or region-break should occur before the specified element",
      "inherited": false,
   },
   {
      "name": "break-inside",
      "reactName": "breakInside",
      "description": "Specifies whether or not a page-, column-, or region-break should occur inside the specified element",
      "inherited": false,
   },
   {
      "name": "caption-side",
      "reactName": "captionSide",
      "description": "Specifies the placement of a table caption",
      "inherited": true,
   },
   {
      "name": "caret-color",
      "reactName": "caretColor",
      "description": "Specifies the color of the cursor (caret) in inputs, textareas, or any element that is editable",
      "inherited": false,
   },
   {
      "name": "@charset",
      "reactName": "@charset",
      "description": "Specifies the character encoding used in the style sheet",
      "inherited": false,
   },
   {
      "name": "clear",
      "reactName": "clear",
      "description": "Specifies on which sides of an element floating elements are not allowed to float",
      "inherited": false,
   },
   {
      "name": "clip",
      "reactName": "clip",
      "description": "Clips an absolutely positioned element",
      "inherited": false,
   },
   {
      "name": "color",
      "reactName": "color",
      "description": "Sets the color of text",
      "inherited": true,
   },
   {
      "name": "column-count",
      "reactName": "columnCount",
      "description": "Specifies the number of columns an element should be divided into",
      "inherited": false,
   },
   {
      "name": "column-fill",
      "reactName": "columnFill",
      "description": "Specifies how to fill columns, balanced or not",
      "inherited": false,
   },
   {
      "name": "column-gap",
      "reactName": "columnGap",
      "description": "Specifies the gap between the columns",
      "inherited": false,
   },
   {
      "name": "column-rule",
      "reactName": "columnRule",
      "description": "A shorthand property for all the column-rule-* properties",
      "inherited": false,
   },
   {
      "name": "column-rule-color",
      "reactName": "columnRuleColor",
      "description": "Specifies the color of the rule between columns",
      "inherited": false,
   },
   {
      "name": "column-rule-style",
      "reactName": "columnRuleStyle",
      "description": "Specifies the style of the rule between columns",
      "inherited": false,
   },
   {
      "name": "column-rule-width",
      "reactName": "columnRuleWidth",
      "description": "Specifies the width of the rule between columns",
      "inherited": false,
   },
   {
      "name": "column-span",
      "reactName": "columnSpan",
      "description": "Specifies how many columns an element should span across",
      "inherited": false,
   },
   {
      "name": "column-width",
      "reactName": "columnWidth",
      "description": "Specifies the column width",
      "inherited": false,
   },
   {
      "name": "columns",
      "reactName": "columns",
      "description": "A shorthand property for column-width and column-count",
      "inherited": false,
   },
   {
      "name": "content",
      "reactName": "content",
      "description": "Used with the :before and :after pseudo-elements, to insert generated content",
      "inherited": false,
   },
   {
      "name": "counter-increment",
      "reactName": "counterIncrement",
      "description": "Increases or decreases the value of one or more CSS counters",
      "inherited": false,
   },
   {
      "name": "counter-reset",
      "reactName": "counterReset",
      "description": "Creates or resets one or more CSS counters",
      "inherited": false,
   },
   {
      "name": "cursor",
      "reactName": "cursor",
      "description": "Specifies the mouse cursor to be displayed when pointing over an element",
      "inherited": true,
   },
   {
      "name": "direction",
      "reactName": "direction",
      "description": "Specifies the text direction/writing direction",
      "inherited": true,
   },
   {
      "name": "display",
      "reactName": "display",
      "description": "Specifies how a certain HTML element should be displayed",
      "inherited": false,
   },
   {
      "name": "empty-cells",
      "reactName": "emptyCells",
      "description": "Specifies whether or not to display borders and background on empty cells in a table",
      "inherited": true,
   },
   {
      "name": "filter",
      "reactName": "filter",
      "description": "Defines effects (e.g. blurring or color shifting) on an element before the element is displayed",
      "inherited": false,
   },
   {
      "name": "flex",
      "reactName": "flex",
      "description": "A shorthand property for the flex-grow, flex-shrink, and the flex-basis properties",
      "inherited": false,
   },
   {
      "name": "flex-basis",
      "reactName": "flexBasis",
      "description": "Specifies the initial length of a flexible item",
      "inherited": false,
   },
   {
      "name": "flex-direction",
      "reactName": "flexDirection",
      "description": "Specifies the direction of the flexible items",
      "inherited": false,
   },
   {
      "name": "flex-flow",
      "reactName": "flexFlow",
      "description": "A shorthand property for the flex-direction and the flex-wrap properties",
      "inherited": false,
   },
   {
      "name": "flex-grow",
      "reactName": "flexGrow",
      "description": "Specifies how much the item will grow relative to the rest",
      "inherited": false,
   },
   {
      "name": "flex-shrink",
      "reactName": "flexShrink",
      "description": "Specifies how the item will shrink relative to the rest",
      "inherited": false,
   },
   {
      "name": "flex-wrap",
      "reactName": "flexWrap",
      "description": "Specifies whether the flexible items should wrap or not",
      "inherited": false,
   },
   {
      "name": "float",
      "reactName": "float",
      "description": "Specifies whether or not a box should float",
      "inherited": false,
   },
   {
      "name": "font",
      "reactName": "font",
      "description": "A shorthand property for the font-style, font-variant, font-weight, font-size/line-height, and the font-family properties",
      "inherited": true,
   },
   {
      "name": "@font-face",
      "reactName": "@fontFace",
      "description": "A rule that allows websites to download and use fonts other than the 'web-safe' fonts",
      "inherited": false,
   },
   {
      "name": "font-family",
      "reactName": "fontFamily",
      "description": "Specifies the font family for text",
      "inherited": true,
   },
   {
      "name": "font-feature-settings",
      "reactName": "fontFeatureSettings",
      "description": "Allows control over advanced typographic features in OpenType fonts",
      "inherited": false,
   },
   {
      "name": "@font-feature-values",
      "reactName": "@fontFeatureValues",
      "description": "Allows authors to use a common name in font-variant-alternate for feature activated differently in OpenType",
      "inherited": false,
   },
   {
      "name": "font-kerning",
      "reactName": "fontKerning",
      "description": "Controls the usage of the kerning information (how letters are spaced)",
      "inherited": false,
   },
   {
      "name": "font-language-override",
      "reactName": "fontLanguageOverride",
      "description": "Controls the usage of language-specific glyphs in a typeface",
      "inherited": false,
   },
   {
      "name": "font-size",
      "reactName": "fontSize",
      "description": "Specifies the font size of text",
      "inherited": true,
   },
   {
      "name": "font-size-adjust",
      "reactName": "fontSizeAdjust",
      "description": "Preserves the readability of text when font fallback occurs",
      "inherited": true,
   },
   {
      "name": "font-stretch",
      "reactName": "fontStretch",
      "description": "Selects a normal, condensed, or expanded face from a font family",
      "inherited": true,
   },
   {
      "name": "font-style",
      "reactName": "fontStyle",
      "description": "Specifies the font style for text",
      "inherited": true,
   },
   {
      "name": "font-synthesis",
      "reactName": "fontSynthesis",
      "description": "Controls which missing typefaces (bold or italic) may be synthesized by the browser",
      "inherited": false,
   },
   {
      "name": "font-variant",
      "reactName": "fontVariant",
      "description": "Specifies whether or not a text should be displayed in a small-caps font",
      "inherited": true,
   },
   {
      "name": "font-variant-alternates",
      "reactName": "fontVariantAlternates",
      "description": "Controls the usage of alternate glyphs associated to alternative names defined in @font-feature-values",
      "inherited": false,
   },
   {
      "name": "font-variant-caps",
      "reactName": "fontVariantCaps",
      "description": "Controls the usage of alternate glyphs for capital letters",
      "inherited": false,
   },
   {
      "name": "font-variant-east-asian",
      "reactName": "fontVariantEastAsian",
      "description": "Controls the usage of alternate glyphs for East Asian scripts (e.g Japanese and Chinese)",
      "inherited": false,
   },
   {
      "name": "font-variant-ligatures",
      "reactName": "fontVariantLigatures",
      "description": "Controls which ligatures and contextual forms are used in textual content of the elements it applies to",
      "inherited": false,
   },
   {
      "name": "font-variant-numeric",
      "reactName": "fontVariantNumeric",
      "description": "Controls the usage of alternate glyphs for numbers, fractions, and ordinal markers",
      "inherited": false,
   },
   {
      "name": "font-variant-position",
      "reactName": "fontVariantPosition",
      "description": "Controls the usage of alternate glyphs of smaller size positioned as superscript or subscript regarding the baseline of the font",
      "inherited": false,
   },
   {
      "name": "font-weight",
      "reactName": "fontWeight",
      "description": "Specifies the weight of a font",
      "inherited": true,
   },
   {
      "name": "grid",
      "reactName": "grid",
      "description": "A shorthand property for the grid-template-rows, grid-template-columns, grid-template-areas, grid-auto-rows, grid-auto-columns, and the grid-auto-flow properties",
      "inherited": false,
   },
   {
      "name": "grid-area",
      "reactName": "gridArea",
      "description": "Either specifies a name for the grid item, or this property is a shorthand property for the grid-row-start, grid-column-start, grid-row-end, and grid-column-end properties",
      "inherited": false,
   },
   {
      "name": "grid-auto-columns",
      "reactName": "gridAutoColumns",
      "description": "Specifies a default column size",
      "inherited": false,
   },
   {
      "name": "grid-auto-flow",
      "reactName": "gridAutoFlow",
      "description": "Specifies how auto-placed items are inserted in the grid",
      "inherited": false,
   },
   {
      "name": "grid-auto-rows",
      "reactName": "gridAutoRows",
      "description": "Specifies a default row size",
      "inherited": false,
   },
   {
      "name": "grid-column",
      "reactName": "gridColumn",
      "description": "A shorthand property for the grid-column-start and the grid-column-end properties",
      "inherited": false,
   },
   {
      "name": "grid-column-end",
      "reactName": "gridColumnEnd",
      "description": "Specifies where to end the grid item",
      "inherited": false,
   },
   {
      "name": "grid-column-gap",
      "reactName": "gridColumnGap",
      "description": "Specifies the size of the gap between columns",
      "inherited": false,
   },
   {
      "name": "grid-column-start",
      "reactName": "gridColumnStart",
      "description": "Specifies where to start the grid item",
      "inherited": false,
   },
   {
      "name": "grid-gap",
      "reactName": "gridGap",
      "description": "A shorthand property for the grid-row-gap and grid-column-gap properties",
      "inherited": false,
   },
   {
      "name": "grid-row",
      "reactName": "gridRow",
      "description": "A shorthand property for the grid-row-start and the grid-row-end properties",
      "inherited": false,
   },
   {
      "name": "grid-row-end",
      "reactName": "gridRowEnd",
      "description": "Specifies where to end the grid item",
      "inherited": false,
   },
   {
      "name": "grid-row-gap",
      "reactName": "gridRowGap",
      "description": "Specifies the size of the gap between rows",
      "inherited": false,
   },
   {
      "name": "grid-row-start",
      "reactName": "gridRowStart",
      "description": "Specifies where to start the grid item",
      "inherited": false,
   },
   {
      "name": "grid-template",
      "reactName": "gridTemplate",
      "description": "A shorthand property for the grid-template-rows, grid-template-columns and grid-areas properties",
      "inherited": false,
   },
   {
      "name": "grid-template-areas",
      "reactName": "gridTemplateAreas",
      "description": "Specifies how to display columns and rows, using named grid items",
      "inherited": false,
   },
   {
      "name": "grid-template-columns",
      "reactName": "gridTemplateColumns",
      "description": "Specifies the size of the columns, and how many columns in a grid layout",
      "inherited": false,
   },
   {
      "name": "grid-template-rows",
      "reactName": "gridTemplateRows",
      "description": "Specifies the size of the rows in a grid layout",
      "inherited": false,
   },
   {
      "name": "hanging-punctuation",
      "reactName": "hangingPunctuation",
      "description": "Specifies whether a punctuation character may be placed outside the line box",
      "inherited": false,
   },
   {
      "name": "height",
      "reactName": "height",
      "description": "Sets the height of an element",
      "inherited": false,
   },
   {
      "name": "hyphens",
      "reactName": "hyphens",
      "description": "Sets how to split words to improve the layout of paragraphs",
      "inherited": false,
   },
   {
      "name": "image-rendering",
      "reactName": "imageRendering",
      "description": "Gives a hint to the browser about what aspects of an image are most important to preserve when the image is scaled",
      "inherited": false,
   },
   {
      "name": "@import",
      "reactName": "@import",
      "description": "Allows you to import a style sheet into another style sheet",
      "inherited": false,
   },
   {
      "name": "isolation",
      "reactName": "isolation",
      "description": "Defines whether an element must create a new stacking content",
      "inherited": false,
   },
   {
      "name": "justify-content",
      "reactName": "justifyContent",
      "description": "Specifies the alignment between the items inside a flexible container when the items do not use all available space",
      "inherited": false,
   },
   {
      "name": "@keyframes",
      "reactName": "@keyframes",
      "description": "Specifies the animation code",
      "inherited": false,
   },
   {
      "name": "left",
      "reactName": "left",
      "description": "Specifies the left position of a positioned element",
      "inherited": false,
   },
   {
      "name": "letter-spacing",
      "reactName": "letterSpacing",
      "description": "Increases or decreases the space between characters in a text",
      "inherited": true,
   },
   {
      "name": "line-break",
      "reactName": "lineBreak",
      "description": "Specifies how/if to break lines",
      "inherited": false,
   },
   {
      "name": "line-height",
      "reactName": "lineHeight",
      "description": "Sets the line height",
      "inherited": true,
   },
   {
      "name": "list-style",
      "reactName": "listStyle",
      "description": "Sets all the properties for a list in one declaration",
      "inherited": true,
   },
   {
      "name": "list-style-image",
      "reactName": "listStyleImage",
      "description": "Specifies an image as the list-item marker",
      "inherited": true,
   },
   {
      "name": "list-style-position",
      "reactName": "listStylePosition",
      "description": "Specifies the position of the list-item markers (bullet points)",
      "inherited": true,
   },
   {
      "name": "list-style-type",
      "reactName": "listStyleType",
      "description": "Specifies the type of list-item marker",
      "inherited": true,
   },
   {
      "name": "margin",
      "reactName": "margin",
      "description": "Sets all the margin properties in one declaration",
      "inherited": false,
   },
   {
      "name": "margin-bottom",
      "reactName": "marginBottom",
      "description": "Sets the bottom margin of an element",
      "inherited": false,
   },
   {
      "name": "margin-left",
      "reactName": "marginLeft",
      "description": "Sets the left margin of an element",
      "inherited": false,
   },
   {
      "name": "margin-right",
      "reactName": "marginRight",
      "description": "Sets the right margin of an element",
      "inherited": false,
   },
   {
      "name": "margin-top",
      "reactName": "marginTop",
      "description": "Sets the top margin of an element",
      "inherited": false,
   },
   {
      "name": "mask",
      "reactName": "mask",
      "description": "Hides an element by masking or clipping the image at specific places",
      "inherited": false,
   },
   {
      "name": "mask-type",
      "reactName": "maskType",
      "description": "Specifies whether a mask element is used as a luminance or an alpha mask",
      "inherited": false,
   },
   {
      "name": "max-height",
      "reactName": "maxHeight",
      "description": "Sets the maximum height of an element",
      "inherited": false,
   },
   {
      "name": "max-width",
      "reactName": "maxWidth",
      "description": "Sets the maximum width of an element",
      "inherited": false,
   },
   {
      "name": "@media",
      "reactName": "@media",
      "description": "Sets the style rules for different media types/devices/sizes",
      "inherited": false,
   },
   {
      "name": "min-height",
      "reactName": "minHeight",
      "description": "Sets the minimum height of an element",
      "inherited": false,
   },
   {
      "name": "min-width",
      "reactName": "minWidth",
      "description": "Sets the minimum width of an element",
      "inherited": false,
   },
   {
      "name": "mix-blend-mode",
      "reactName": "mixBlendMode",
      "description": "Specifies how an element's content should blend with its direct parent background",
      "inherited": false,
   },
   {
      "name": "object-fit",
      "reactName": "objectFit",
      "description": "Specifies how the contents of a replaced element should be fitted to the box established by its used height and width",
      "inherited": false,
   },
   {
      "name": "object-position",
      "reactName": "objectPosition",
      "description": "Specifies the alignment of the replaced element inside its box",
      "inherited": false,
   },
   {
      "name": "opacity",
      "reactName": "opacity",
      "description": "Sets the opacity level for an element",
      "inherited": false,
   },
   {
      "name": "order",
      "reactName": "order",
      "description": "Sets the order of the flexible item, relative to the rest",
      "inherited": false,
   },
   {
      "name": "orphans",
      "reactName": "orphans",
      "description": "Sets the minimum number of lines that must be left at the bottom of a page when a page break occurs inside an element",
      "inherited": true,
   },
   {
      "name": "outline",
      "reactName": "outline",
      "description": "A shorthand property for the outline-width, outline-style, and the outline-color properties",
      "inherited": false,
   },
   {
      "name": "outline-color",
      "reactName": "outlineColor",
      "description": "Sets the color of an outline",
      "inherited": false,
   },
   {
      "name": "outline-offset",
      "reactName": "outlineOffset",
      "description": "Offsets an outline, and draws it beyond the border edge",
      "inherited": false,
   },
   {
      "name": "outline-style",
      "reactName": "outlineStyle",
      "description": "Sets the style of an outline",
      "inherited": false,
   },
   {
      "name": "outline-width",
      "reactName": "outlineWidth",
      "description": "Sets the width of an outline",
      "inherited": false,
   },
   {
      "name": "overflow",
      "reactName": "overflow",
      "description": "Specifies what happens if content overflows an element's box",
      "inherited": false,
   },
   {
      "name": "overflow-wrap",
      "reactName": "overflowWrap",
      "description": "Specifies whether or not the browser may break lines within words in order to prevent overflow (when a string is too long to fit its containing box)",
      "inherited": false,
   },
   {
      "name": "overflow-x",
      "reactName": "overflowX",
      "description": "Specifies whether or not to clip the left/right edges of the content, if it overflows the element's content area",
      "inherited": false,
   },
   {
      "name": "overflow-y",
      "reactName": "overflowY",
      "description": "Specifies whether or not to clip the top/bottom edges of the content, if it overflows the element's content area",
      "inherited": false,
   },
   {
      "name": "padding",
      "reactName": "padding",
      "description": "A shorthand property for all the padding-* properties",
      "inherited": false,
   },
   {
      "name": "padding-bottom",
      "reactName": "paddingBottom",
      "description": "Sets the bottom padding of an element",
      "inherited": false,
   },
   {
      "name": "padding-left",
      "reactName": "paddingLeft",
      "description": "Sets the left padding of an element",
      "inherited": false,
   },
   {
      "name": "padding-right",
      "reactName": "paddingRight",
      "description": "Sets the right padding of an element",
      "inherited": false,
   },
   {
      "name": "padding-top",
      "reactName": "paddingTop",
      "description": "Sets the top padding of an element",
      "inherited": false,
   },
   {
      "name": "page-break-after",
      "reactName": "pageBreakAfter",
      "description": "Sets the page-break behavior after an element",
      "inherited": false,
   },
   {
      "name": "page-break-before",
      "reactName": "pageBreakBefore",
      "description": "Sets the page-break behavior before an element",
      "inherited": false,
   },
   {
      "name": "page-break-inside",
      "reactName": "pageBreakInside",
      "description": "Sets the page-break behavior inside an element",
      "inherited": false,
   },
   {
      "name": "perspective",
      "reactName": "perspective",
      "description": "Gives a 3D-positioned element some perspective",
      "inherited": false,
   },
   {
      "name": "perspective-origin",
      "reactName": "perspectiveOrigin",
      "description": "Defines at which position the user is looking at the 3D-positioned element",
      "inherited": false,
   },
   {
      "name": "pointer-events",
      "reactName": "pointerEvents",
      "description": "Defines whether or not an element reacts to pointer events",
      "inherited": false,
   },
   {
      "name": "position",
      "reactName": "position",
      "description": "Specifies the type of positioning method used for an element (static, relative, absolute or fixed)",
      "inherited": false,
   },
   {
      "name": "quotes",
      "reactName": "quotes",
      "description": "Sets the type of quotation marks for embedded quotations",
      "inherited": true,
   },
   {
      "name": "resize",
      "reactName": "resize",
      "description": "Defines if (and how) an element is resizable by the user",
      "inherited": false,
   },
   {
      "name": "right",
      "reactName": "right",
      "description": "Specifies the right position of a positioned element",
      "inherited": false,
   },
   {
      "name": "scroll-behavior",
      "reactName": "scrollBehavior",
      "description": "Specifies whether to smoothly animate the scroll position in a scrollable box, instead of a straight jump",
      "inherited": false,
   },
   {
      "name": "tab-size",
      "reactName": "tabSize",
      "description": "Specifies the width of a tab character",
      "inherited": true,
   },
   {
      "name": "table-layout",
      "reactName": "tableLayout",
      "description": "Defines the algorithm used to lay out table cells, rows, and columns",
      "inherited": false,
   },
   {
      "name": "text-align",
      "reactName": "textAlign",
      "description": "Specifies the horizontal alignment of text",
      "inherited": true,
   },
   {
      "name": "text-align-last",
      "reactName": "textAlignLast",
      "description": "Describes how the last line of a block or a line right before a forced line break is aligned when text-align is 'justify'",
      "inherited": true,
   },
   {
      "name": "text-combine-upright",
      "reactName": "textCombineUpright",
      "description": "Specifies the combination of multiple characters into the space of a single character",
      "inherited": false,
   },
   {
      "name": "text-decoration",
      "reactName": "textDecoration",
      "description": "Specifies the decoration added to text",
      "inherited": false,
   },
   {
      "name": "text-decoration-color",
      "reactName": "textDecorationColor",
      "description": "Specifies the color of the text-decoration",
      "inherited": true,
   },
   {
      "name": "text-decoration-line",
      "reactName": "textDecorationLine",
      "description": "Specifies the type of line in a text-decoration",
      "inherited": false,
   },
   {
      "name": "text-decoration-style",
      "reactName": "textDecorationStyle",
      "description": "Specifies the style of the line in a text decoration",
      "inherited": false,
   },
   {
      "name": "text-indent",
      "reactName": "textIndent",
      "description": "Specifies the indentation of the first line in a text-block",
      "inherited": true,
   },
   {
      "name": "text-justify",
      "reactName": "textJustify",
      "description": "Specifies the justification method used when text-align is 'justify'",
      "inherited": true,
   },
   {
      "name": "text-orientation",
      "reactName": "textOrientation",
      "description": "Defines the orientation of the text in a line",
      "inherited": false,
   },
   {
      "name": "text-overflow",
      "reactName": "textOverflow",
      "description": "Specifies what should happen when text overflows the containing element",
      "inherited": false,
   },
   {
      "name": "text-shadow",
      "reactName": "textShadow",
      "description": "Adds shadow to text",
      "inherited": true,
   },
   {
      "name": "text-transform",
      "reactName": "textTransform",
      "description": "Controls the capitalization of text",
      "inherited": true,
   },
   {
      "name": "text-underline-position",
      "reactName": "textUnderlinePosition",
      "description": "Specifies the position of the underline which is set using the text-decoration property",
      "inherited": false,
   },
   {
      "name": "top",
      "reactName": "top",
      "description": "Specifies the top position of a positioned element",
      "inherited": false,
   },
   {
      "name": "transform",
      "reactName": "transform",
      "description": "Applies a 2D or 3D transformation to an element",
      "inherited": false,
   },
   {
      "name": "transform-origin",
      "reactName": "transformOrigin",
      "description": "Allows you to change the position on transformed elements",
      "inherited": false,
   },
   {
      "name": "transform-style",
      "reactName": "transformStyle",
      "description": "Specifies how nested elements are rendered in 3D space",
      "inherited": false,
   },
   {
      "name": "transition",
      "reactName": "transition",
      "description": "A shorthand property for all the transition-* properties",
      "inherited": false,
   },
   {
      "name": "transition-delay",
      "reactName": "transitionDelay",
      "description": "Specifies when the transition effect will start",
      "inherited": false,
   },
   {
      "name": "transition-duration",
      "reactName": "transitionDuration",
      "description": "Specifies how many seconds or milliseconds a transition effect takes to complete",
      "inherited": false,
   },
   {
      "name": "transition-property",
      "reactName": "transitionProperty",
      "description": "Specifies the name of the CSS property the transition effect is for",
      "inherited": false,
   },
   {
      "name": "transition-timing-function",
      "reactName": "transitionTimingFunction",
      "description": "Specifies the speed curve of the transition effect",
      "inherited": false,
   },
   {
      "name": "unicode-bidi",
      "reactName": "unicodeBidi",
      "description": "	Used together with the direction property to set or return whether the text should be overridden to support multiple languages in the same document",
      "inherited": false,
   },
   {
      "name": "user-select",
      "reactName": "userSelect",
      "description": "Sets the elements position, from the bottom of its parent element",
      "inherited": false,
   },
   {
      "name": "vertical-align",
      "reactName": "verticalAlign",
      "description": "Sets the vertical alignment of an element",
      "inherited": false,
   },
   {
      "name": "visibility",
      "reactName": "visibility",
      "description": "Specifies whether or not an element is visible",
      "inherited": true,
   },
   {
      "name": "white-space",
      "reactName": "whiteSpace",
      "description": "Specifies how white-space inside an element is handled",
      "inherited": true,
   },
   {
      "name": "widows",
      "reactName": "widows",
      "description": "Sets the minimum number of lines that must be left at the top of a page when a page break occurs inside an element",
      "inherited": true,
   },
   {
      "name": "width",
      "reactName": "width",
      "description": "Sets the width of an element",
      "inherited": false,
   },
   {
      "name": "word-break",
      "reactName": "wordBreak",
      "description": "Specifies how words should break when reaching the end of a line",
      "inherited": true,
   },
   {
      "name": "word-spacing",
      "reactName": "wordSpacing",
      "description": "Increases or decreases the space between words in a text",
      "inherited": true,
   },
   {
      "name": "word-wrap",
      "reactName": "wordWrap",
      "description": "Allows long, unbreakable words to be broken and wrap to the next line",
      "inherited": true,
   },
   {
      "name": "writing-mode",
      "reactName": "writingMode",
      "description": "Specifies whether lines of text are laid out horizontally or vertically",
      "inherited": false,
   },
   {
      "name": "z-index",
      "reactName": "zIndex",
      "description": "Sets the stack order of a positioned element",
      "inherited": false,
   }
]

const newStyleData: BlockStyle[] = [
   { id: 0, blockStyleData: [], screenMode: 'xs', selector: '' },
   { id: 0, blockStyleData: [], screenMode: 'xs', selector: ':hover' },
   { id: 0, blockStyleData: [], screenMode: 'sm', selector: '' },
   { id: 0, blockStyleData: [], screenMode: 'sm', selector: ':hover' },
   { id: 0, blockStyleData: [], screenMode: 'md', selector: '' },
   { id: 0, blockStyleData: [], screenMode: 'md', selector: ':hover' },
   { id: 0, blockStyleData: [], screenMode: 'lg', selector: '' },
   { id: 0, blockStyleData: [], screenMode: 'lg', selector: ':hover' },
   { id: 0, blockStyleData: [], screenMode: 'xl', selector: '' },
   { id: 0, blockStyleData: [], screenMode: 'xl', selector: ':hover' },
];
