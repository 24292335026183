
/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react';

import React, { ReactNode, ReactNodeArray, useContext, useEffect, useRef, useState } from 'react';
import * as BlockStore from '../../store/Block';
import { GetStyle } from '../../Utility';
import HeaderBlock from './BlockTypes/HeaderBlock';
import ContainerBlock from './BlockTypes/ContainerBlock';
import ColumnBlock from './BlockTypes/ColumnBlock';
import RowBlock from './BlockTypes/RowBlock';
import SliderBlock from './BlockTypes/SliderBlock';
import BeforeAfterBlock from './BlockTypes/BeforeAfterBlock';
import CountUpBlock from './BlockTypes/CountUpBlock';
import { MediaFile } from '../../store/Media';
import useDeepEffect from '../../hooks/useDeepEffect';
import ContactReinklang from './BlockTypes/ContactReinklang';
import ReactBeforeSliderComponent from 'react-before-after-slider-component';
import { useDispatch } from 'react-redux';
import { DynDataEntry, DynDataEntryProp } from '../../store/DynData';
import { EventContext } from '../Contexts/BlockEventContext';
import { TranslationContext } from '../Contexts/TranslationContext';
import { TwitterTimelineEmbed } from 'react-twitter-embed';

interface Props {
    currentBlock: BlockStore.BlockData,
    previousBlock: BlockStore.BlockData | undefined,
    blocks: BlockStore.BlockData[],
    selectedBlockId: number,
    scrollHeight: number,
    canvasHeight: number,
    disabled: boolean,
    canvasMode: string,
    viewMode: string,
    dataEntry?: DynDataEntry,
    currentFormId?: number,
}


const Block = (props: Props) => {

    const {
        currentBlock,
        blocks,
        selectedBlockId,
        disabled,
        canvasMode,
        previousBlock,
        scrollHeight,
        canvasHeight,
        viewMode,
        dataEntry,
        currentFormId,
    } = props;

    const [blockClasses, setBlockClasses] = useState(["block"]);
    const [isCounting, setIsCounting] = useState<boolean>(false);
    const [triggered, setTriggered] = useState<string[]>([]);
    const [inputValue, setInputValue] = useState<string>('');
    const [additionalClasses, setAdditionalClasses] = useState<string[]>([]);
    const [scrollTest, setScrollTest] = useState<number>(0);

    const blockRef: any = useRef(null);
    const eventContext = useContext(EventContext);
    const translationContext = useContext(TranslationContext)
    const triggerRef = useRef(triggered);
    const eventRef = useRef(currentBlock.blockEvents);
    const inputRef = useRef(inputValue);

    const dispatch = useDispatch();

    useEffect(() => {
        triggerRef.current = triggered;
    }, [triggered]);

    useEffect(() => {
        inputRef.current = inputValue;
    }, [inputValue]);

    useDeepEffect(() => {
        currentBlock.blockEventTriggers
            .filter(x => x.eventTriggerType, BlockStore.EventTriggerType.OnScroll)
            .forEach((eventTrigger) => {
                const newScrollheight = currentBlock.overwriteWindowScroll ? scrollTest : scrollHeight;

                const scrollHeightToCheck = eventTrigger.zeroBased ? newScrollheight > eventTrigger?.scrollHeight : eventTrigger?.scrollHeight + newScrollheight + canvasHeight > blockRef?.current?.offsetTop;
                const scrollheight = eventTrigger.zeroBased ? eventTrigger?.scrollHeight : blockRef?.current?.offsetTop;

                if (eventTrigger.blockEvent) {
                    if (scrollHeightToCheck) {
                        console.log("trigger");
                        eventContext.triggerEvent(eventTrigger.blockEvent!.id, currentBlock.id, eventTrigger.eventTriggerType, scrollheight);
                    } else {
                        console.log("untrigger");
                        eventContext.unTriggerEvent(eventTrigger.blockEvent!.id, currentBlock.id);
                    }
                }
            })
    }, [scrollHeight, currentBlock.blockEventTriggers, scrollTest])

    useEffect(() => {
        let newClasses = ["block", `c${currentBlock.id}`];
        if (!disabled) {
            if (viewMode === 'development')
                newClasses.push("dev-view");

            if (selectedBlockId === currentBlock?.id)
                newClasses.push("selected");

        }

        if (currentBlock.type === 'countup' && blockRef.current) {
            if (blockRef.current.offsetTop <= scrollHeight + canvasHeight && !isCounting) {
                setIsCounting(true);
            }
        }

        newClasses.push(triggered.join(" "));
        newClasses.push(additionalClasses.join(" "));

        setBlockClasses(newClasses);
    }, [disabled, setBlockClasses, viewMode, selectedBlockId, scrollHeight, canvasHeight, triggered.length, additionalClasses.length])

    useEffect(() => {
        if (currentBlock.type === 'countup' && blockRef.current) {
            if (blockRef.current.offsetTop <= scrollHeight + canvasHeight && !isCounting) {
                setIsCounting(true);
            }
        }

        const initialTriggers = currentBlock.blockEventTriggers.filter(x => x.initiallyTriggered);
        if (initialTriggers.length) {
            initialTriggers
                .forEach(x => eventContext.triggerEvent(x.blockEvent!.id, currentBlock.id, x.eventTriggerType));
        }

        if (currentBlock.blockEvents.filter(x => x.unTriggerOnClickAway).length) {
            console.log("added Event");
            document.body.addEventListener('click', clickAwayEventHandler, { capture: true });
        }

        if (currentBlock.overwriteWindowScroll)
            blockRef.current.addEventListener('scroll', handleScroll)

        return () => {
            if (currentBlock.blockEvents.filter(x => x.unTriggerOnClickAway).length) {
                document.body.removeEventListener('click', clickAwayEventHandler);
            }
            if (currentBlock.overwriteWindowScroll)
                blockRef.current.removeEventListener('scroll', handleScroll);
        }
    }, [])

    useDeepEffect(() => {
        if (currentBlock.blockEvents.length) {
            currentBlock.blockEvents.forEach(x => eventContext.createEvent(x, triggerHandler));
        }

        eventRef.current = currentBlock.blockEvents;


        if (currentBlock.type === 'form') {
            eventContext.registerForm(currentBlock.id, addAdditionalClass, removeAdditionalClass);
        } else if (currentBlock.type === 'input' && currentFormId) {
            eventContext.registerInput(currentFormId, (currentBlock as BlockStore.InputBlockData).required, getData, addAdditionalClass, removeAdditionalClass);
        }
    }, [currentBlock.blockEvents])

    const handleScroll = () => {
        setScrollTest(blockRef.current.scrollTop);
    }

    const triggerHandler = (triggers: string[]) => {
        setTriggered(triggers);
    }

    const getData = (): DynDataEntryProp => {
        const inputBlock = currentBlock as BlockStore.InputBlockData;
        return { value: inputRef.current, tag: inputBlock.dynProp.tag }
    }

    const addAdditionalClass = (newClass: string) => {
        setAdditionalClasses(prev => [...prev, newClass]);
    }

    const removeAdditionalClass = (removeClass: string) => {
        setAdditionalClasses(prev => [...prev.filter(x => x != removeClass)]);
    }

    const clickAwayEventHandler = React.useCallback((e: MouseEvent) => {
        if (eventRef.current.filter(x => x.unTriggerOnClickAway).length && triggerRef.current.length && blockRef.current && !blockRef.current.contains(e.target)) {
            eventRef.current.forEach(blockEvent => {
                eventContext.unTriggerEventOnClickAway(blockEvent.id, currentBlock.id);
            });
        }
    }, [triggered]);

    const blockClickHandler = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();

        if (!disabled)
            dispatch(BlockStore.actionCreators.selectBlock(currentBlock));

        if (currentBlock.blockEventTriggers.length) {
            currentBlock.blockEventTriggers
                .filter(x => x.eventTriggerType === BlockStore.EventTriggerType.OnClick)
                .forEach(x => eventContext.triggerEvent(x.blockEvent!.id, currentBlock.id, x.eventTriggerType));

            if (currentFormId !== undefined)
                currentBlock.blockEventTriggers
                    .filter(x => x.eventTriggerType === BlockStore.EventTriggerType.OnSubmit)
                    .forEach(x => eventContext.submitEvent(currentFormId));
        }
    }

    const displayedBlocks: ReactNode | ReactNodeArray = blocks.length ? blocks
        .filter(x => x.locationPath?.split('-').length - 1 === currentBlock.locationPath?.split('-').length)
        .sort((a, b) => a.sortPath - b.sortPath)
        .map((block, index) => {

            return (
                <Block
                    key={block.id}
                    currentBlock={block}
                    blocks={blocks.filter(x => x.locationPath?.startsWith(block.locationPath + '-'))}
                    selectedBlockId={selectedBlockId}
                    disabled={disabled}
                    previousBlock={currentBlock}
                    canvasMode={canvasMode}
                    viewMode={viewMode}
                    scrollHeight={currentBlock.overwriteWindowScroll ? scrollTest : scrollHeight}
                    canvasHeight={canvasHeight}
                    dataEntry={dataEntry}
                    currentFormId={currentBlock.type === 'form' ? currentBlock.id : currentFormId}
                />
            )
        }) : null;

    const data = dataEntry ? JSON.parse(dataEntry.data) : {};
    let displayedBlock;
    switch (currentBlock.type) {
        case 'base':
            displayedBlock = (
                <div
                    className={blockClasses.join(" ")}
                    css={GetStyle(canvasMode, currentBlock)}
                    onClick={blockClickHandler}
                    ref={blockRef}>
                    {displayedBlocks}
                </div>
            );
            break;
        case 'input':
            const inputBlockData = currentBlock as BlockStore.InputBlockData;
            if (inputBlockData.inputType === BlockStore.InputType.textarea) {

                displayedBlock = (
                    <textarea placeholder={inputBlockData.placeHolder} value={inputValue} onChange={(e) => setInputValue(e.target.value)} css={GetStyle(canvasMode, currentBlock)} className={blockClasses.join(" ")}></textarea>
                );
            } else {
                displayedBlock = (
                    <input type={BlockStore.InputType[inputBlockData.inputType]} placeholder={inputBlockData.placeHolder} value={inputValue} onChange={(e) => setInputValue(e.target.value)} css={GetStyle(canvasMode, currentBlock)} className={blockClasses.join(" ")} />
                );
            }
            break;
        case 'form':
            const formBlockData = currentBlock as BlockStore.FormBlockData;
            displayedBlock = (
                <form name={formBlockData.name} css={GetStyle(canvasMode, currentBlock)} className={blockClasses.join(" ")}>
                    {displayedBlocks}
                </form>
            );
            break;
        case 'header':
            const headerBlockData = currentBlock as BlockStore.HeaderBlockData;
            let headerBlockDataContent = translationContext.translate(headerBlockData?.content ?? '');
            const headerContentAssignment = currentBlock.dynPropAssignments.find(x => x.blockProp === "content");

            if (headerContentAssignment) {
                headerBlockDataContent = headerBlockDataContent.replace(`%%${headerContentAssignment.dynProp.tag}%%`, data[headerContentAssignment.dynProp.tag]);
            }

            displayedBlock = (
                <HeaderBlock content={headerBlockDataContent} styles={GetStyle(canvasMode, currentBlock)} blockData={currentBlock as BlockStore.HeaderBlockData} classes={blockClasses.join(" ")} blockClickHandler={blockClickHandler} />
            );
            break;
        case 'text':
            const textBlockData = currentBlock as BlockStore.TextBlockData;
            let textBlockDataContent = translationContext.translate(textBlockData?.content ?? '');
            const textContentAssignment = currentBlock.dynPropAssignments.find(x => x.blockProp === "content");

            if (textContentAssignment) {
                textBlockDataContent = textBlockDataContent.replace(`%%${textContentAssignment.dynProp.tag}%%`, data[textContentAssignment.dynProp.tag]);
            }

            displayedBlock = (
                <p css={GetStyle(canvasMode, currentBlock)} className={blockClasses.join(" ")} onClick={blockClickHandler}>
                    {textBlockDataContent}
                </p>
            );
            break;
        case 'media':
            const mediaBlock = currentBlock as BlockStore.MediaBlockData
            displayedBlock = (
                mediaBlock.mediaFile?.extension !== '.mp4' ?
                    <img css={GetStyle(canvasMode, currentBlock)} className={blockClasses.join(" ")} src={mediaBlock.mediaFile?.path} onClick={blockClickHandler}>

                    </img>
                    : <video css={GetStyle(canvasMode, currentBlock)} muted autoPlay loop className={blockClasses.join(" ")} src={mediaBlock.mediaFile?.path} onClick={blockClickHandler}>

                    </video>
            );
            break;
        case 'link':

            const linkBlockData = currentBlock as BlockStore.LinkBlockData;
            let linkBlockDataContent = translationContext.translate(linkBlockData?.content ?? '');
            const linkContentAssignment = currentBlock.dynPropAssignments.find(x => x.blockProp === "content");

            if (linkContentAssignment) {
                linkBlockDataContent = linkBlockDataContent.replace(`%%${linkContentAssignment.dynProp.tag}%%`, data[linkContentAssignment.dynProp.tag]);
            }

            displayedBlock = (
                <a css={GetStyle(canvasMode, currentBlock)} className={blockClasses.join(" ")} onClick={blockClickHandler}>
                    {linkBlockDataContent}
                    {displayedBlocks}
                </a>
            );
            break;
        case 'container':
            displayedBlock = (
                <ContainerBlock styles={GetStyle(canvasMode, currentBlock)} blockData={currentBlock as BlockStore.ContainerBlockData} classes={blockClasses.join(" ")} blockClickHandler={blockClickHandler} canvasMode={canvasMode}>
                    {displayedBlocks}
                </ContainerBlock>
            );
            break;
        case 'row':
            displayedBlock = (
                <RowBlock block={currentBlock as BlockStore.RowBlockData} styles={GetStyle(canvasMode, currentBlock)} classes={blockClasses.join(" ")} blockClickHandler={blockClickHandler} >
                    {displayedBlocks}
                </RowBlock>
            );
            break;
        case 'col':
            displayedBlock = (
                <ColumnBlock styles={GetStyle(canvasMode, currentBlock)} blockData={currentBlock as BlockStore.ColBlockData} rowBlockData={previousBlock as BlockStore.RowBlockData} classes={blockClasses.join(" ")} blockClickHandler={blockClickHandler} canvasMode={canvasMode}>
                    {displayedBlocks}
                </ColumnBlock>
            );
            break;
        case 'slider':
            let items = blocks.length ? blocks
                .filter(x => x.locationPath?.split('-').length - 1 === currentBlock.locationPath?.split('-').length)
                .sort((a, b) => a.sortPath - b.sortPath) : [] as BlockStore.BlockData[];

            let size;
            const sliderBlock = currentBlock as BlockStore.SliderBlockData;
            switch (canvasMode) {
                case 'sm':
                    size = sliderBlock.sm;
                    break;
                case 'md':
                    size = sliderBlock.md;
                    break;
                case 'lg':
                    size = sliderBlock.lg;
                    break;
                case 'xl':
                    size = sliderBlock.xl;
                    break;
                case 'xxl':
                    size = sliderBlock.xl;
                    break;
                default:
                    size = sliderBlock.xs;
                    break;
            }

            displayedBlock = (
                <SliderBlock
                    blockData={sliderBlock}
                    size={size}
                    items={items}
                    classes={blockClasses.join(" ")}
                    blockClickHandler={blockClickHandler}
                    selectedBlockId={selectedBlockId}
                    disabled={disabled}
                    previousBlock={previousBlock}
                    currentBlock={currentBlock}
                    canvasMode={canvasMode}>
                    {displayedBlocks}
                </SliderBlock>
            );
            break;
        case 'section':
            displayedBlock = (
                <section css={GetStyle(canvasMode, currentBlock)} className={blockClasses.join(" ")} ref={blockRef} onClick={(e) => blockClickHandler(e)}>
                    {displayedBlocks}
                </section>
            );
            break;
        case 'iframe':
            const iframeBlock = currentBlock as BlockStore.IframeBlockData
            displayedBlock = (
                <iframe css={GetStyle(canvasMode, currentBlock)} className={blockClasses.join(" ")} src={iframeBlock.link} title={iframeBlock.title} ref={blockRef} onClick={(e) => blockClickHandler(e)}>

                </iframe>
            );
            break;
        case 'beforeafter':
            const beforeAfterBlock = currentBlock as BlockStore.BeforeAfterBlockData
            if (beforeAfterBlock.firstImgSource && beforeAfterBlock.secondImgSource) {
                const FIRST_IMAGE = {
                    imageUrl: beforeAfterBlock.firstImgSource?.path
                };
                const SECOND_IMAGE = {
                    imageUrl: beforeAfterBlock.secondImgSource?.path
                };
                displayedBlock = (
                    <div css={GetStyle(canvasMode, currentBlock)} ref={blockRef}>
                        <ReactBeforeSliderComponent
                            firstImage={FIRST_IMAGE}
                            secondImage={SECOND_IMAGE}
                        />
                    </div>
                )
            }

            break;
        case 'rating':
            const ratingBlock = currentBlock as BlockStore.RatingBlockData
            let mapper = new Array(ratingBlock.amount);
            let rating = ratingBlock.rating;

            for (let i = 0; i < ratingBlock.amount; i++) {
                if (rating >= 1)
                    mapper[i] = 1;
                else if (rating == 0.5)
                    mapper[i] = 0.5;
                else
                    mapper[i] = 0;

                rating = rating - 1;
            }

            const stars = mapper.map((rating: number) => {
                switch (rating) {
                    case 1:
                        return <img className="ratingstar" src="./images/fullstar.svg" style={{ display: 'inline', height: '20px' }} />
                    case 0.5:
                        return <img className="ratingstar" src="./images/halfstar.svg" style={{ display: 'inline', height: '20px' }} />
                    default:
                        return <img className="ratingstar" src="./images/emptystar.svg" style={{ display: 'inline', height: '20px' }} />

                }
            })

            displayedBlock = (
                <div css={GetStyle(canvasMode, currentBlock)} className={blockClasses.join(" ")} onClick={(e) => blockClickHandler(e)}>
                    {stars}
                </div>
            );
            break;
        case 'countup':
            displayedBlock = (
                <div className={blockClasses.join(" ")} ref={blockRef}>
                    <CountUpBlock countUpBlock={currentBlock as BlockStore.CountUpBlockData} isCounting={isCounting} />
                </div>
            );
            break;
        case 'custom':
            const customBlock = currentBlock as BlockStore.CustomBlockData
            switch (customBlock.name) {
                case 'Twitter':
                    displayedBlock = (
                        <TwitterTimelineEmbed
                            sourceType="profile"
                            screenName="TTC_eSport"
                            noHeader
                            noFooter
                            tweetLimit={3}
                        />
                    );
                    break;
                case 'TwitchOnline':
                    displayedBlock = (
                        <div className={blockClasses.join(" ")} css={GetStyle(canvasMode, currentBlock)}>
                            {displayedBlocks}
                        </div>
                    );
                    break;
                default:
                    displayedBlock = (
                        <div />
                    );
            }
            break;
        case 'imagecollector':
            displayedBlock = (
                <div className={blockClasses.join(" ")} ref={blockRef}>
                    {displayedBlocks}
                </div>
            );
            break;
        default:
            displayedBlock = (
                <div />
            );
    }

    return (
        <>
            {displayedBlock}
        </>
    );
}

export default Block;