import React, { ChangeEvent, FormEvent, useContext, useEffect, useState } from 'react';
import { BeforeAfterBlockData, BlockData, ColBlockData, CountUpBlockData, CustomBlockData, FormBlockData, HeaderBlockData, IframeBlockData, InputBlockData, InputType, LinkBlockData, MediaBlockData, RatingBlockData, RowBlockData, SliderBlockData, TextBlockData } from '../../../store/Block';
import { MediaFile } from '../../../store/Media';
import { AnimationNames } from '../../../Utility';
import SelectMediaFiles from '../../DocumentData/Modals/SelectMediaFiles';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Dialog, DialogTitle, DialogContent, FormControl, FormControlLabel, Checkbox, InputLabel, Select, MenuItem, TextField, DialogActions, Paper, Stack, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, styled, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectPageDynProps } from '../../../store/selectors/PageSelector';
import { DynModel, DynPropAssignment, DynProps } from '../../../store/DynData';
import PropAssignmentControl from '../PropAssignmentControl';
import AddIcon from '@mui/icons-material/Add';
import { EventContext } from '../../Contexts/BlockEventContext';
import { selectDynModels, selectFormDynProps } from '../../../store/selectors/DynDataSelector';
import { selectMailConnections, selectMailTemplates } from '../../../store/selectors/ConfigSelector';
import { MailConnection, MailTemplate } from '../../../store/Configuration';

const PREFIX = 'BlockPropsDialog';

const classes = {
    inputRoot: `${PREFIX}-inputRoot`,
    formControl: `${PREFIX}-formControl`,
    mediaSelection: `${PREFIX}-mediaSelection`,
    mediaSelectionText: `${PREFIX}-mediaSelectionText`
};

const StyledDialog = styled(Dialog)((

    { theme }
) => ({
    [`& .${classes.inputRoot}`]: {
        '& > *': {
            marginBottom: theme.spacing(1),
            marginTop: theme.spacing(1),
        },
    },
    [`& .${classes.formControl}`]: {
        marginBottom: 0,
        marginTop: 0,
    },
    [`& .${classes.mediaSelection}`]: {
        width: '300px',
        height: '168px',
        border: '1px dashed grey',
        position: 'relative',
        borderRadius: '4px',
        cursor: 'pointer',
        '&:hover': {
            border: '1px solid black',
        }
    },
    [`& .${classes.mediaSelectionText}`]: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        fontWeight: 'bold'
    },
}));

interface Props {
    block: BlockData,
    updateBlockHandler: (block: BlockData) => void;
    dialogOpenHandler: () => void;
    isOpen: boolean;
    dynamic: boolean;
}

const BlockPropsDialog = (props: Props) => {

    const { block, updateBlockHandler, isOpen, dialogOpenHandler, dynamic } = props;

    const [dynProp, setDynProp] = useState<DynProps>();
    const [dynModel, setDynModel] = useState<DynModel>();
    const [show, setShow] = useState(false);
    const [mediaFile, setMediaFile] = useState<MediaFile | null>(null);
    const [secondMediaFile, setSecondMediaFile] = useState<MediaFile | null>(null);
    const [anchor, setAnchor] = useState("");
    const [headerNumber, setHeaderNumber] = useState(0);
    const [blockContent, setBlockContent] = useState("");
    const [mediaAltText, setMediaAltText] = useState("");
    const [animationName, setAnimationName] = useState("");
    const [animateOnLoad, setAnimateOnLoad] = useState(false);
    const [collectImages, setCollectImages] = useState(false);
    const [modalTriggerZone, setModalTriggerZone] = useState(false);
    const [link, setLink] = useState("");
    const [title, setTitle] = useState("");
    const [sizeXs, setSizeXs] = useState(1);
    const [sizeSm, setSizeSm] = useState(1);
    const [sizeMd, setSizeMd] = useState(1);
    const [sizeLg, setSizeLg] = useState(1);
    const [sizeXl, setSizeXl] = useState(1);
    const [triggerModal, setTriggerModal] = useState(false);
    const [countOnLoad, setCountOnLoad] = useState(false);
    const [slidesToSlide, setSlidesToSlide] = useState(0);
    const [useImageCollector, setUseImageCollector] = useState(false);
    const [draggable, setDraggable] = useState(false);
    const [swipeable, setSwipeable] = useState(false);
    const [arrows, setArrows] = useState(false);
    const [infinite, setInfinite] = useState(false);
    const [autoPlay, setAutoPlay] = useState(false);
    const [autoPlaySpeed, setAutoPlaySpeed] = useState(0);
    const [showDots, setShowDots] = useState(false);
    const [renderDotsOutside, setRenderDotsOutside] = useState(false);
    const [centerMode, setCenterMode] = useState(false);
    const [mediaType, setMediaType] = useState<string>("first");
    const [mediaLayout, setMediaLayout] = useState<string>("responsive");
    const [mediaPreload, setMediaPreload] = useState(false);
    const [rating, setRating] = useState<number>(0);
    const [amount, setAmount] = useState<number>(0);
    const [navbarScrollHeight, setNavbarScrollHeight] = useState<number>(10000);
    const [customizing, setCustomizing] = useState<string>("None");
    const [textparam, setTextParam] = useState<string>("");
    const [formName, setFormName] = useState<string>("");
    const [mailSubject, setMailSubject] = useState<string>("");
    const [countUp, setCountUp] = useState<number>(0);
    const [duration, setDuration] = useState<number>(3);
    const [blank, setBlank] = useState(false);
    const [mediaModalOpen, setMediaModalOpen] = useState(false);
    const [noGutters, setNoGutters] = useState(false);
    const [inputType, setInputType] = useState<InputType>(InputType.text);
    const [inputPlaceholder, setInputPlaceholder] = useState<string>("");
    const [inputRequired, setInputRequired] = useState<boolean>(false);
    const [mailConnection, setMailConnection] = useState<MailConnection | undefined>(undefined);
    const [mailTemplate, setMailTemplate] = useState<MailTemplate | undefined>(undefined);
    const [recipient, setRecipient] = useState<string>("");
    const [overwriteWindowScroll, setOverwriteWindowScroll] = useState<boolean>(false);

    const dynProps = useSelector(selectFormDynProps);
    const dynModels = useSelector(selectDynModels);
    const mailConnections = useSelector(selectMailConnections);
    const mailTemplates = useSelector(selectMailTemplates);

    const handleXsChange = (value: number) => {
        setSizeXs(value);
    };

    const handleSmChange = (value: number) => {
        setSizeSm(value);
    };

    const handleMdChange = (value: number) => {
        setSizeMd(value);
    };

    const handleLgChange = (value: number) => {
        setSizeLg(value);
    };

    const handleXlChange = (value: number) => {
        setSizeXl(value);
    };

    const handleHeaderNumberChange = (value: number) => {
        setHeaderNumber(value);
    };

    const handleDynPropChange = (value: number) => {
        const newProp = dynProps.find(x => x.id === value);

        if (newProp)
            setDynProp({ ...newProp });
    }

    const handleMailConnectionChange = (value: number) => {
        const newCon = mailConnections.find(x => x.id === value);

        if (newCon)
            setMailConnection({ ...newCon });
    }

    const handleMailTemplateChange = (value: number) => {
        const newTemplate = mailTemplates.find(x => x.id === value);

        if (newTemplate)
            setMailTemplate({ ...newTemplate });
    }
    const handleDynModelChange = (value: number) => {
        const newModel = dynModels.find(x => x.id === value);

        if (newModel)
            setDynModel({ ...newModel });
    }

    const handleSetMediaFile = (file: MediaFile) => {

        switch (mediaType) {
            case 'first':
                setMediaFile(file);
                break;
            case 'second':
                setSecondMediaFile(file);
                break;
        }
        setMediaModalOpen(false);
    }

    const openMediaModalhandler = (newType: string) => {
        setMediaType(newType);
        setMediaModalOpen(true);
    }

    const eventContext = useContext(EventContext);

    useEffect(() => {

        setAnimationName(block.animationName);
        setAnimateOnLoad(block.animateOnLoad);
        setShow(block.onScroll);
        setNavbarScrollHeight(block.scrollYPosition);
        setOverwriteWindowScroll(block.overwriteWindowScroll)

        if (block.type == 'media') {
            setMediaFile((block as MediaBlockData).mediaFile);
            setMediaLayout((block as MediaBlockData).layout);
            setMediaPreload((block as MediaBlockData).preload);
            setMediaAltText((block as MediaBlockData).altText);
        }
        else if (block.type == 'form') {
            setFormName((block as FormBlockData).name);
            setDynModel((block as FormBlockData).dynModel);
            setMailConnection((block as FormBlockData).mailConnection);
            setMailTemplate((block as FormBlockData).mailTemplate);
            setRecipient((block as FormBlockData).recipient);
            setMailSubject((block as FormBlockData).subject);
        }
        else if (block.type == 'input') {
            setInputType((block as InputBlockData).inputType);
            setDynProp((block as InputBlockData).dynProp);
            setInputPlaceholder((block as InputBlockData).placeHolder);
            setInputRequired((block as InputBlockData).required);
        }
        else if (block.type == 'header') {
            setHeaderNumber((block as HeaderBlockData).number);
            setBlockContent((block as HeaderBlockData).content);
        }
        else if (block.type == 'link') {
            setBlockContent((block as LinkBlockData).content);
            setAnchor((block as LinkBlockData).anchor);
            setLink((block as LinkBlockData).link);
            setBlank((block as LinkBlockData).blank);
        }
        else if (block.type == 'text') {
            setBlockContent((block as TextBlockData).content);
        } else if (block.type == 'row') {
            setSizeXs((block as RowBlockData).xs);
            setSizeSm((block as RowBlockData).sm);
            setSizeMd((block as RowBlockData).md);
            setSizeLg((block as RowBlockData).lg);
            setSizeXl((block as RowBlockData).xl);
            setNoGutters((block as RowBlockData).noGutters);
        } else if (block.type == 'col') {
            setSizeXs((block as ColBlockData).xs);
            setSizeSm((block as ColBlockData).sm);
            setSizeMd((block as ColBlockData).md);
            setSizeLg((block as ColBlockData).lg);
            setSizeXl((block as ColBlockData).xl);
        } else if (block.type == 'slider') {
            setSizeXs((block as SliderBlockData).xs);
            setSizeSm((block as SliderBlockData).sm);
            setSizeMd((block as SliderBlockData).md);
            setSizeLg((block as SliderBlockData).lg);
            setSizeXl((block as SliderBlockData).xl);
            setSlidesToSlide((block as SliderBlockData).slidesToSlide);
            setDraggable((block as SliderBlockData).draggable);
            setSwipeable((block as SliderBlockData).swipeable);
            setArrows((block as SliderBlockData).arrows);
            setInfinite((block as SliderBlockData).infinite);
            setAutoPlay((block as SliderBlockData).autoPlay);
            setAutoPlaySpeed((block as SliderBlockData).autoPlaySpeed);
            setShowDots((block as SliderBlockData).showDots);
            setRenderDotsOutside((block as SliderBlockData).renderDotsOutside);
            setCenterMode((block as SliderBlockData).centerMode);
            setUseImageCollector((block as SliderBlockData).useImageCollector);

        } else if (block.type == 'iframe') {
            setLink((block as IframeBlockData).link);
            setTitle((block as IframeBlockData).title);

        } else if (block.type == 'beforeafter') {
            setMediaFile((block as BeforeAfterBlockData).firstImgSource);
            setSecondMediaFile((block as BeforeAfterBlockData).secondImgSource);
        } else if (block.type == 'rating') {
            setAmount((block as RatingBlockData).amount);
            setRating((block as RatingBlockData).rating);
        } else if (block.type == 'custom') {
            setCustomizing((block as CustomBlockData).name);
            setTextParam((block as CustomBlockData).textParam);
        } else if (block.type == 'countup') {
            setCountUp((block as CountUpBlockData).countTo);
            setDuration((block as CountUpBlockData).duration);
            setCountOnLoad((block as CountUpBlockData).countOnLoad);
        }
    }, [block, setHeaderNumber, setMediaFile])

    const handleSubmit = () => {
        let updatedBlock = { ...block };
        if (block.type == 'media') {
            (updatedBlock as MediaBlockData).mediaFile = mediaFile;
            (updatedBlock as MediaBlockData).layout = mediaLayout;
            (updatedBlock as MediaBlockData).preload = mediaPreload;
            (updatedBlock as MediaBlockData).altText = mediaAltText;
        }
        else if (block.type == 'input') {
            (updatedBlock as InputBlockData).inputType = inputType;
            (updatedBlock as InputBlockData).placeHolder = inputPlaceholder;
            (updatedBlock as InputBlockData).required = inputRequired;

            if (dynProp)
                (updatedBlock as InputBlockData).dynProp = dynProp;
        }
        else if (block.type == 'form') {
            (updatedBlock as FormBlockData).name = formName;
            (updatedBlock as FormBlockData).recipient = recipient;
            (updatedBlock as FormBlockData).subject = mailSubject;

            if (dynModel)
                (updatedBlock as FormBlockData).dynModel = dynModel;

            if (mailConnection)
                (updatedBlock as FormBlockData).mailConnection = mailConnection;

            if (mailTemplate)
                (updatedBlock as FormBlockData).mailTemplate = mailTemplate;

        }
        else if (block.type == 'header') {
            (updatedBlock as HeaderBlockData).number = headerNumber;
            (updatedBlock as HeaderBlockData).content = blockContent;
        }
        else if (block.type == 'link') {
            (updatedBlock as LinkBlockData).content = blockContent;
            (updatedBlock as LinkBlockData).link = link;
            (updatedBlock as LinkBlockData).blank = blank;
            (updatedBlock as LinkBlockData).anchor = anchor;
        }
        else if (block.type == 'text') {
            (updatedBlock as TextBlockData).content = blockContent;
        }
        else if (block.type == 'row') {
            (updatedBlock as RowBlockData).xs = sizeXs;
            (updatedBlock as RowBlockData).sm = sizeSm;
            (updatedBlock as RowBlockData).md = sizeMd;
            (updatedBlock as RowBlockData).lg = sizeLg;
            (updatedBlock as RowBlockData).xl = sizeXl;
            (updatedBlock as RowBlockData).noGutters = noGutters;
        }
        else if (block.type == 'col') {
            (updatedBlock as RowBlockData).xs = sizeXs;
            (updatedBlock as RowBlockData).sm = sizeSm;
            (updatedBlock as RowBlockData).md = sizeMd;
            (updatedBlock as RowBlockData).lg = sizeLg;
            (updatedBlock as RowBlockData).xl = sizeXl;
        }
        else if (block.type == 'slider') {
            (updatedBlock as SliderBlockData).xs = sizeXs;
            (updatedBlock as SliderBlockData).sm = sizeSm;
            (updatedBlock as SliderBlockData).md = sizeMd;
            (updatedBlock as SliderBlockData).lg = sizeLg;
            (updatedBlock as SliderBlockData).xl = sizeXl;
            (updatedBlock as SliderBlockData).slidesToSlide = slidesToSlide;
            (updatedBlock as SliderBlockData).draggable = draggable;
            (updatedBlock as SliderBlockData).swipeable = swipeable;
            (updatedBlock as SliderBlockData).arrows = arrows;
            (updatedBlock as SliderBlockData).infinite = infinite;
            (updatedBlock as SliderBlockData).autoPlay = autoPlay;
            (updatedBlock as SliderBlockData).autoPlaySpeed = autoPlaySpeed;
            (updatedBlock as SliderBlockData).showDots = showDots;
            (updatedBlock as SliderBlockData).renderDotsOutside = renderDotsOutside;
            (updatedBlock as SliderBlockData).centerMode = centerMode;
            (updatedBlock as SliderBlockData).useImageCollector = useImageCollector;
        }
        else if (block.type == 'iframe') {
            (updatedBlock as IframeBlockData).link = link;
            (updatedBlock as IframeBlockData).title = title;
        } else if (block.type == 'beforeafter') {
            (updatedBlock as BeforeAfterBlockData).firstImgSource = mediaFile;
            (updatedBlock as BeforeAfterBlockData).secondImgSource = secondMediaFile;
        } else if (block.type == 'rating') {
            (updatedBlock as RatingBlockData).amount = amount;
            (updatedBlock as RatingBlockData).rating = rating;
        } else if (block.type == 'custom') {
            (updatedBlock as CustomBlockData).name = customizing;
            (updatedBlock as CustomBlockData).textParam = textparam;
        } else if (block.type == 'countup') {
            (updatedBlock as CountUpBlockData).countTo = countUp;
            (updatedBlock as CountUpBlockData).duration = duration;
            (updatedBlock as CountUpBlockData).countOnLoad = countOnLoad;
        }

        updatedBlock.animationName = animationName;
        updatedBlock.animateOnLoad = animateOnLoad;
        updatedBlock.onScroll = show;
        updatedBlock.scrollYPosition = navbarScrollHeight;
        updatedBlock.overwriteWindowScroll = overwriteWindowScroll;

        updateBlockHandler(updatedBlock);
        dialogOpenHandler();
    }

    return (
        <StyledDialog
            open={isOpen}
            onClose={dialogOpenHandler}
            fullWidth
            maxWidth="sm"
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">Properties</DialogTitle>
            <DialogContent className={classes.inputRoot}>
                <FormControl >
                    <FormControlLabel
                        value="end"
                        control={<Checkbox icon={<CheckBoxOutlineBlankIcon fontSize="small" />} checkedIcon={<CheckBoxIcon fontSize="small" />} color="primary" checked={show} onChange={(e: ChangeEvent<HTMLInputElement>) => setShow(prevState => !prevState)} />}
                        label="Show/Hide on scroll"
                        labelPlacement="end"
                    />
                </FormControl>
                <FormControl >
                    <FormControlLabel
                        value="end"
                        control={<Checkbox icon={<CheckBoxOutlineBlankIcon fontSize="small" />} checkedIcon={<CheckBoxIcon fontSize="small" />} color="primary" checked={overwriteWindowScroll} onChange={(e: ChangeEvent<HTMLInputElement>) => setOverwriteWindowScroll(prevState => !prevState)} />}
                        label="Overwrite windowscroll"
                        labelPlacement="end"
                    />
                </FormControl>
                <FormControl fullWidth >
                    <InputLabel id="animation-name">Animationsname</InputLabel>
                    <Select
                        labelId="animation-name"
                        value={animationName}
                        label="Animationsname"
                        onChange={(e: any) => setAnimationName(e.target.value)}
                    >
                        <MenuItem value=""></MenuItem>
                        {
                            AnimationNames.map((name: string) => <MenuItem key={name} value={name}>{name}</MenuItem>)
                        }
                    </Select>
                </FormControl>
                {
                    block.type === 'input' ? (
                        <>
                            <FormControl fullWidth >
                                <InputLabel id="input-type">Inputtype</InputLabel>
                                <Select
                                    labelId="input-type"
                                    value={inputType}
                                    label="Inputtype"
                                    onChange={(e: any) => setInputType(e.target.value)}
                                >
                                    <MenuItem key="text" value={InputType.text}>Text</MenuItem>
                                    <MenuItem key="textarea" value={InputType.textarea}>Textarea</MenuItem>
                                    <MenuItem key="checkbox" value={InputType.checkbox}>Checkbox</MenuItem>
                                    <MenuItem key="color" value={InputType.color}>Color</MenuItem>
                                    <MenuItem key="date" value={InputType.date}>Date</MenuItem>
                                    <MenuItem key="email" value={InputType.email}>Email</MenuItem>
                                    <MenuItem key="month" value={InputType.month}>Month</MenuItem>
                                    <MenuItem key="number" value={InputType.number}>Number</MenuItem>
                                    <MenuItem key="password" value={InputType.password}>Password</MenuItem>
                                    <MenuItem key="range" value={InputType.range}>Range</MenuItem>
                                    <MenuItem key="search" value={InputType.search}>Search</MenuItem>
                                    <MenuItem key="tel" value={InputType.tel}>Tel</MenuItem>
                                    <MenuItem key="time" value={InputType.time}>Time</MenuItem>
                                    <MenuItem key="week" value={InputType.week}>Week</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl fullWidth >
                                <InputLabel id="dyn-prop">Dyn Prop</InputLabel>
                                <Select
                                    labelId="dyn-prop"
                                    value={dynProp?.id}
                                    label="Dyn Prop"
                                    onChange={(e) => handleDynPropChange(e.target.value as number)}
                                >
                                    {
                                        dynProps.map(prop => (
                                            <MenuItem key={prop.id} value={prop.id}>{prop.tag}</MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                            <TextField
                                label="Placeholder"
                                placeholder="Placeholder"
                                fullWidth
                                value={inputPlaceholder}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => setInputPlaceholder(e.target.value)}
                                margin="normal"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                            <FormControlLabel
                                value="end"
                                control={<Checkbox icon={<CheckBoxOutlineBlankIcon fontSize="small" />} checkedIcon={<CheckBoxIcon fontSize="small" />} color="primary" checked={inputRequired} onChange={(e: ChangeEvent<HTMLInputElement>) => setInputRequired(prevState => !prevState)} />}
                                label="Required"
                                labelPlacement="end"
                            />
                        </>
                    ) : null
                }
                {
                    animationName && animationName !== "" && <FormControlLabel
                        value="end"
                        control={<Checkbox icon={<CheckBoxOutlineBlankIcon fontSize="small" />} checkedIcon={<CheckBoxIcon fontSize="small" />} color="primary" checked={animateOnLoad} onChange={(e: ChangeEvent<HTMLInputElement>) => setAnimateOnLoad(prevState => !prevState)} />}
                        label="Animate on load"
                        labelPlacement="end"
                    />
                }
                {
                    block.type === 'countup' ? (
                        <FormControlLabel
                            value="end"
                            control={<Checkbox icon={<CheckBoxOutlineBlankIcon fontSize="small" />} checkedIcon={<CheckBoxIcon fontSize="small" />} color="primary" checked={countOnLoad} onChange={(e: ChangeEvent<HTMLInputElement>) => setCountOnLoad(prevState => !prevState)} />}
                            label="Count on load"
                            labelPlacement="end"
                        />
                    ) : null
                }
                {
                    block.type === 'link' ? (
                        <FormControlLabel
                            value="end"
                            control={<Checkbox icon={<CheckBoxOutlineBlankIcon fontSize="small" />} checkedIcon={<CheckBoxIcon fontSize="small" />} color="primary" checked={blank} onChange={(e: ChangeEvent<HTMLInputElement>) => setBlank(prevState => !prevState)} />}
                            label="_blank"
                            labelPlacement="end"
                        />
                    ) : null
                }

                {block.type === 'custom' ?
                    <>
                        <FormControl fullWidth >
                            <InputLabel id="customizing-name">xs</InputLabel>
                            <Select
                                labelId="customizing-name"
                                value={customizing}
                                label="xs"
                                onChange={(e: any) => setCustomizing(e.target.value)}
                            >
                                <MenuItem value="None">None</MenuItem>
                                <MenuItem value="Twitter">Twitter timeline</MenuItem>
                                <MenuItem value="TwitchOnline">TwitchOnline</MenuItem>
                            </Select>
                        </FormControl>
                        <TextField label="Param1" placeholder="Custom param" value={textparam} onChange={(e: ChangeEvent<HTMLInputElement>) => setTextParam(e.target.value)} />
                    </>
                    : null}
                {block.type === 'countup' ?
                    <>
                        <TextField
                            label="Count to"
                            placeholder="Count to"
                            fullWidth
                            type="number"
                            value={countUp}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => setCountUp(Number(e.target.value))}
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        <TextField
                            label="Duration"
                            placeholder="Duration"
                            type="number"
                            fullWidth
                            value={duration}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => setDuration(Number(e.target.value))}
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </>
                    : null}
                {block.type === 'row' ?
                    <FormControlLabel
                        value="end"
                        control={<Checkbox icon={<CheckBoxOutlineBlankIcon fontSize="small" />} checkedIcon={<CheckBoxIcon fontSize="small" />} color="primary" checked={noGutters} onChange={(e: ChangeEvent<HTMLInputElement>) => setNoGutters(prevState => !prevState)} />}
                        label="No Gutters"
                        labelPlacement="end"
                    />
                    : null}
                {block.type === 'col' || block.type === 'row' ?
                    <>
                        <FormControl fullWidth >
                            <InputLabel id="size-xs">xs</InputLabel>
                            <Select
                                labelId="size-xs"
                                value={sizeXs}
                                label="xs"
                                onChange={(e) => handleXsChange(e.target.value as number)}
                            >
                                <MenuItem value={1}>1</MenuItem>
                                <MenuItem value={2}>2</MenuItem>
                                <MenuItem value={3}>3</MenuItem>
                                <MenuItem value={4}>4</MenuItem>
                                <MenuItem value={6}>6</MenuItem>
                                <MenuItem value={12}>12</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl fullWidth >
                            <InputLabel id="size-sm">sm</InputLabel>
                            <Select
                                labelId="size-sm"
                                value={sizeSm}
                                label="sm"
                                onChange={(e) => handleSmChange(e.target.value as number)}
                            >
                                <MenuItem value={1}>1</MenuItem>
                                <MenuItem value={2}>2</MenuItem>
                                <MenuItem value={3}>3</MenuItem>
                                <MenuItem value={4}>4</MenuItem>
                                <MenuItem value={6}>6</MenuItem>
                                <MenuItem value={12}>12</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl fullWidth >
                            <InputLabel id="size-md">md</InputLabel>
                            <Select
                                labelId="size-md"
                                value={sizeMd}
                                label="md"
                                onChange={(e) => handleMdChange(e.target.value as number)}
                            >
                                <MenuItem value={1}>1</MenuItem>
                                <MenuItem value={2}>2</MenuItem>
                                <MenuItem value={3}>3</MenuItem>
                                <MenuItem value={4}>4</MenuItem>
                                <MenuItem value={6}>6</MenuItem>
                                <MenuItem value={12}>12</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl fullWidth >
                            <InputLabel id="size-lg">lg</InputLabel>
                            <Select
                                labelId="size-lg"
                                value={sizeLg}
                                label="lg"
                                onChange={(e) => handleLgChange(e.target.value as number)}
                            >
                                <MenuItem value={1}>1</MenuItem>
                                <MenuItem value={2}>2</MenuItem>
                                <MenuItem value={3}>3</MenuItem>
                                <MenuItem value={4}>4</MenuItem>
                                <MenuItem value={6}>6</MenuItem>
                                <MenuItem value={12}>12</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl fullWidth >
                            <InputLabel id="size-xl">xl</InputLabel>
                            <Select
                                labelId="size-xl"
                                value={sizeXl}
                                label="xl"
                                onChange={(e) => handleXlChange(e.target.value as number)}
                            >
                                <MenuItem value={1}>1</MenuItem>
                                <MenuItem value={2}>2</MenuItem>
                                <MenuItem value={3}>3</MenuItem>
                                <MenuItem value={4}>4</MenuItem>
                                <MenuItem value={6}>6</MenuItem>
                                <MenuItem value={12}>12</MenuItem>
                            </Select>
                        </FormControl>
                    </> : null}
                {block.type === 'header' ?

                    <FormControl fullWidth >
                        <InputLabel id="header-number">Nummer</InputLabel>
                        <Select
                            labelId="header-number"
                            value={headerNumber}
                            label="Nummer"
                            onChange={(e) => handleHeaderNumberChange(e.target.value as number)}
                        >
                            <MenuItem value={1}>1</MenuItem>
                            <MenuItem value={2}>2</MenuItem>
                            <MenuItem value={3}>3</MenuItem>
                            <MenuItem value={4}>4</MenuItem>
                            <MenuItem value={5}>5</MenuItem>
                            <MenuItem value={6}>6</MenuItem>
                        </Select>
                    </FormControl> : null}
                {block.type === 'form' ?
                    <>
                        <TextField
                            label="Name"
                            placeholder="Name"
                            fullWidth
                            margin="normal"
                            value={formName}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => setFormName(e.target.value)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        <TextField
                            label="Subject"
                            placeholder="Subject"
                            fullWidth
                            margin="normal"
                            value={mailSubject}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => setMailSubject(e.target.value)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        <TextField
                            label="Recipient"
                            placeholder="Recipient"
                            fullWidth
                            margin="normal"
                            value={recipient}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => setRecipient(e.target.value)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        <FormControl fullWidth >
                            <InputLabel id="dyn-model">Model</InputLabel>
                            <Select
                                labelId="dyn-model"
                                value={dynModel?.id}
                                label="Model"
                                onChange={(e) => handleDynModelChange(e.target.value as number)}
                            >
                                {
                                    dynModels.map(model => (
                                        <MenuItem key={model.id} value={model.id}>{model.name}</MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                        <FormControl fullWidth >
                            <InputLabel id="mail-connection">MailConnection</InputLabel>
                            <Select
                                labelId="mail-connection"
                                value={mailConnection?.id}
                                label="MailConnection"
                                onChange={(e) => handleMailConnectionChange(e.target.value as number)}
                            >
                                {
                                    mailConnections.map(con => (
                                        <MenuItem key={con.id} value={con.id}>{`${con.host}:${con.port}`}</MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                        <FormControl fullWidth >
                            <InputLabel id="mail-template">MailTemplate</InputLabel>
                            <Select
                                labelId="mail-template"
                                value={mailTemplate?.id}
                                label="MailTemplate"
                                onChange={(e) => handleMailTemplateChange(e.target.value as number)}
                            >
                                {
                                    mailTemplates.map(template => (
                                        <MenuItem key={template.id} value={template.id}>{template.name}</MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </>
                    : null}
                {block.type === 'iframe' ?
                    <TextField
                        label="Title"
                        placeholder="Title"
                        fullWidth
                        margin="normal"
                        value={title}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => setTitle(e.target.value)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    : null}
                {block.type === 'link' || block.type === 'iframe' ?
                    <TextField
                        label="Link"
                        placeholder="Link"
                        fullWidth
                        margin="normal"
                        value={link}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => setLink(e.target.value)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    : null}
                {block.type === 'link' ?
                    <TextField
                        label="Anchor"
                        placeholder="Anchor"
                        fullWidth
                        margin="normal"
                        value={anchor}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => setAnchor(e.target.value)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    : null}
                {block.type === 'header' || block.type === 'link' || block.type === 'text' ?
                    <TextField
                        label="Content"
                        placeholder="Content"
                        fullWidth
                        multiline
                        rows={4}
                        maxRows={4}
                        value={blockContent}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => setBlockContent(e.target.value)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    : null}
                {block.type === 'media' || block.type === 'beforeafter' ?
                    <>
                        <Typography variant="subtitle1" component="div">
                            { mediaFile ? mediaFile.name + mediaFile.extension : null }
                        </Typography>
                        <div className={classes.mediaSelection} onClick={() => openMediaModalhandler("first")}>
                            {
                                mediaFile ?
                                    mediaFile.extension === ".mp4" ?
                                        <video muted autoPlay className="media-card-video">
                                            <source src={mediaFile.path} type="video/mp4" />
                                        </video>
                                        : <div style={{ backgroundImage: `url(${mediaFile.path})`, width: '100%', height: '100%', backgroundSize: 'cover' }} />
                                    : null
                            }

                            {
                                mediaFile ? null : (
                                <Typography variant="subtitle1" component="div" className={classes.mediaSelectionText}>
                                    {'Select media'}
                                </Typography>)
                            }
                        </div>

                        <FormControl fullWidth >
                            <InputLabel id="media-layout">Layout</InputLabel>
                            <Select
                                labelId="media-layout"
                                value={mediaLayout}
                                label="Layout"
                                onChange={(e: any) => setMediaLayout(e.target.value as string)}
                            >
                                <MenuItem value={"responsive"}>responsive</MenuItem>
                                <MenuItem value={"intrinsic"}>intrinsic</MenuItem>
                                <MenuItem value={"fixed"}>fixed</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControlLabel
                            value="end"
                            control={<Checkbox icon={<CheckBoxOutlineBlankIcon fontSize="small" />} checkedIcon={<CheckBoxIcon fontSize="small" />} color="primary" checked={mediaPreload} onChange={(e: ChangeEvent<HTMLInputElement>) => setMediaPreload(prevState => !prevState)} />}
                            label="Preload"
                            labelPlacement="end"
                        />
                        <TextField label="AltText" InputLabelProps={{ shrink: true }} fullWidth margin="normal" placeholder="Alternative text" value={mediaAltText} onChange={(e: ChangeEvent<HTMLInputElement>) => setMediaAltText(e.target.value)} />

                    </> : null}
                {block.type === 'beforeafter' ?
                    <div className="media-selection" onClick={() => openMediaModalhandler("second")}>
                        {
                            secondMediaFile ?
                                secondMediaFile.extension === ".mp4" ?
                                    <video muted autoPlay className="media-card-video">
                                        <source src={secondMediaFile.path} type="video/mp4" />
                                    </video>
                                    : <div>
                                        <div style={{ backgroundImage: `url(${secondMediaFile.path})` }} />
                                    </div>
                                : 'Select Media'
                        }
                    </div> : null
                }
                {block.type === 'rating' ?
                    <>
                        <TextField label="Amount" placeholder="Amount of stars" fullWidth margin="normal" InputLabelProps={{ shrink: true }} value={amount} type="number" onChange={(e: ChangeEvent<HTMLInputElement>) => setAmount(Number(e.target.value))} />
                        <TextField label="Rating" placeholder="Rating" fullWidth margin="normal" InputLabelProps={{ shrink: true }} value={rating} type="number" onChange={(e: ChangeEvent<HTMLInputElement>) => setRating(Number(e.target.value))} />
                    </> : null
                }
                {block.type === 'slider' ?
                    <>
                        <TextField
                            label="Anchor"
                            placeholder="Anchor"
                            fullWidth
                            margin="normal"
                            value={anchor}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => setAnchor(e.target.value)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        <TextField label="Xs" placeholder="number of items" InputLabelProps={{ shrink: true }} fullWidth value={sizeXs} type="number" onChange={(e: ChangeEvent<HTMLInputElement>) => setSizeXs(Number(e.target.value))} />
                        <TextField label="Sm" placeholder="number of items" InputLabelProps={{ shrink: true }} fullWidth value={sizeSm} type="number" onChange={(e: ChangeEvent<HTMLInputElement>) => setSizeSm(Number(e.target.value))} />
                        <TextField label="Md" placeholder="number of items" InputLabelProps={{ shrink: true }} fullWidth value={sizeMd} type="number" onChange={(e: ChangeEvent<HTMLInputElement>) => setSizeMd(Number(e.target.value))} />
                        <TextField label="Lg" placeholder="number of items" InputLabelProps={{ shrink: true }} fullWidth value={sizeLg} type="number" onChange={(e: ChangeEvent<HTMLInputElement>) => setSizeLg(Number(e.target.value))} />
                        <TextField label="Xl" placeholder="number of items" InputLabelProps={{ shrink: true }} fullWidth value={sizeXl} type="number" onChange={(e: ChangeEvent<HTMLInputElement>) => setSizeXl(Number(e.target.value))} />
                        <TextField label="Slides to slide" placeholder="Slides to slide" InputLabelProps={{ shrink: true }} fullWidth value={slidesToSlide} type="number" onChange={(e: ChangeEvent<HTMLInputElement>) => setSlidesToSlide(Number(e.target.value))} />
                        <FormControlLabel
                            value="end"
                            control={<Checkbox icon={<CheckBoxOutlineBlankIcon fontSize="small" />} checkedIcon={<CheckBoxIcon fontSize="small" />} color="primary" checked={useImageCollector} onChange={(e: ChangeEvent<HTMLInputElement>) => setUseImageCollector(prevState => !prevState)} />}
                            label="Use image collector"
                            labelPlacement="end"
                        />
                        <FormControlLabel
                            value="end"
                            control={<Checkbox icon={<CheckBoxOutlineBlankIcon fontSize="small" />} checkedIcon={<CheckBoxIcon fontSize="small" />} color="primary" checked={draggable} onChange={(e: ChangeEvent<HTMLInputElement>) => setDraggable(prevState => !prevState)} />}
                            label="Draggable"
                            labelPlacement="end"
                        />
                        <FormControlLabel
                            value="end"
                            control={<Checkbox icon={<CheckBoxOutlineBlankIcon fontSize="small" />} checkedIcon={<CheckBoxIcon fontSize="small" />} color="primary" checked={swipeable} onChange={(e: ChangeEvent<HTMLInputElement>) => setSwipeable(prevState => !prevState)} />}
                            label="Swipeable"
                            labelPlacement="end"
                        />
                        <FormControlLabel
                            value="end"
                            control={<Checkbox icon={<CheckBoxOutlineBlankIcon fontSize="small" />} checkedIcon={<CheckBoxIcon fontSize="small" />} color="primary" checked={arrows} onChange={(e: ChangeEvent<HTMLInputElement>) => setArrows(prevState => !prevState)} />}
                            label="Arrows"
                            labelPlacement="end"
                        />
                        <FormControlLabel
                            value="end"
                            control={<Checkbox icon={<CheckBoxOutlineBlankIcon fontSize="small" />} checkedIcon={<CheckBoxIcon fontSize="small" />} color="primary" checked={infinite} onChange={(e: ChangeEvent<HTMLInputElement>) => setInfinite(prevState => !prevState)} />}
                            label="Infinite"
                            labelPlacement="end"
                        />
                        <FormControlLabel
                            value="end"
                            control={<Checkbox icon={<CheckBoxOutlineBlankIcon fontSize="small" />} checkedIcon={<CheckBoxIcon fontSize="small" />} color="primary" checked={autoPlay} onChange={(e: ChangeEvent<HTMLInputElement>) => setAutoPlay(prevState => !prevState)} />}
                            label="Autoplay"
                            labelPlacement="end"
                        />
                        <FormControlLabel
                            value="end"
                            control={<Checkbox icon={<CheckBoxOutlineBlankIcon fontSize="small" />} checkedIcon={<CheckBoxIcon fontSize="small" />} color="primary" checked={showDots} onChange={(e: ChangeEvent<HTMLInputElement>) => setShowDots(prevState => !prevState)} />}
                            label="Show Dots"
                            labelPlacement="end"
                        />
                        <FormControlLabel
                            value="end"
                            control={<Checkbox icon={<CheckBoxOutlineBlankIcon fontSize="small" />} checkedIcon={<CheckBoxIcon fontSize="small" />} color="primary" checked={renderDotsOutside} onChange={(e: ChangeEvent<HTMLInputElement>) => setRenderDotsOutside(prevState => !prevState)} />}
                            label="Render Dots outside"
                            labelPlacement="end"
                        />
                        <FormControlLabel
                            value="end"
                            control={<Checkbox icon={<CheckBoxOutlineBlankIcon fontSize="small" />} checkedIcon={<CheckBoxIcon fontSize="small" />} color="primary" checked={centerMode} onChange={(e: ChangeEvent<HTMLInputElement>) => setCenterMode(prevState => !prevState)} />}
                            label="Center Mode"
                            labelPlacement="end"
                        />
                        <TextField label="Autoplay Speed" placeholder="Autoplay Speed" value={autoPlaySpeed} type="number" onChange={(e: ChangeEvent<HTMLInputElement>) => setAutoPlaySpeed(Number(e.target.value))} />
                    </>
                    : null}
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={dialogOpenHandler}>
                    Cancel
                </Button>
                <Button color="primary" onClick={handleSubmit}>
                    Save
                </Button>
            </DialogActions>
            <Dialog fullWidth maxWidth="md" open={mediaModalOpen} onClose={() => setMediaModalOpen(false)} >
                <SelectMediaFiles setMediaFile={handleSetMediaFile} />
            </Dialog>
        </StyledDialog>
    );
}

export default BlockPropsDialog;