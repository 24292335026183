import React, { ChangeEvent } from 'react';
import RemoveIcon from '@mui/icons-material/Remove';
import { BlockStyleData } from '../../store/Block';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { MenuItem, Select, SelectChangeEvent, styled, TableCell, TableRow } from '@mui/material';
import { DynProps } from '../../store/DynData';

interface Props {
    handleDeleteProps: (identifier: number) => void,
    handlePropTypeChange: (value: string, identifier: number) => void,
    handlePropTagChange: (attrKey: string, identifier: number) => void,
    attr: DynProps,
}

const PREFIX = 'DynPropControlRow';

const classes = {
    inputs: `${PREFIX}-inputs`,
    container: `${PREFIX}-container`,
    removeStyle: `${PREFIX}-removeStyle`,
    delimiter: `${PREFIX}-delimiter`,
    button: `${PREFIX}-button`,
    removeIcon: `${PREFIX}-removeIcon`,
    removeCell: `${PREFIX}-removeCell`,
};

const Root = styled(TableRow)((
    {
        theme
    }
) => ({
    [`& .${classes.inputs}`]: {
        fontSize: theme.typography.subtitle2.fontSize,
        padding: 0,
    },
    [`& .${classes.removeStyle}`]: {
        width: '3%'
    },
    [`& .${classes.delimiter}`]: {
        width: '2%',
        textAlign: 'center',
    },
    [`& .${classes.button}`]: {
        padding: 0,
    },
    [`& .${classes.removeIcon}`]: {
        fontSize: '18px',
    },
    [`& .${classes.removeCell}`]: {
        width: '12px',
    },
    [`& .${classes.container}`]: {
        '& th': {
            fontWeight: 'bold',
        },
    },
}));

const DynPropControlRow = (props: Props) => {

    const { handleDeleteProps, handlePropTagChange, handlePropTypeChange, attr } = props;

    return (
        <Root className={classes.container}>
            <TableCell>
                <TextField
                    InputProps={{
                        classes: {
                            input: classes.inputs,
                        },
                    }}
                    value={attr.tag}
                    variant="standard"
                    fullWidth
                    onChange={(e: ChangeEvent<HTMLInputElement>) => handlePropTagChange(e.target.value, attr.id)}
                />
            </TableCell>
            <TableCell>
                <Select
                    value={attr.type}
                    onChange={(e: SelectChangeEvent) => handlePropTypeChange(e.target.value, attr.id)}
                >
                    <MenuItem value={"text"}>text</MenuItem>
                    <MenuItem value={"media"}>media</MenuItem>
                </Select>
            </TableCell>

            <TableCell className={classes.removeCell}>
                <IconButton edge="end" className={classes.button} aria-label="delete" color="primary" size="small" onClick={() => handleDeleteProps(attr.id)}>
                    <RemoveIcon className={classes.removeIcon} />
                </IconButton>
            </TableCell>
        </Root>
    )
}

export default DynPropControlRow;