
import { DialogTitle, DialogContent, TextField, DialogActions, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { ChangeEvent, FormEvent, useState } from 'react';

const PREFIX = 'SaveAsTemplateModal';

const classes = {
    root: `${PREFIX}-root`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
    {
        theme
    }
) => ({
    [`& .${classes.root}`]: {
        '& > *': {
            marginTop: theme.spacing(1),
        },
    }
}));

interface Props {
    toggleTemplateModal: () => void,
    submitTemplateModal: (name: string) => void
}

const SaveAsTemplateModal = (props: Props) => {


    const { toggleTemplateModal, submitTemplateModal } = props;

    const [templateName, setTemplateName] = useState("");

    const templateNameChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        setTemplateName(event.target.value);
    }
    const templateSubmitHandler = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        submitTemplateModal(templateName);
    }

    return (
        (<Root>
            <form noValidate autoComplete="off" onSubmit={templateSubmitHandler}>
                  <DialogTitle>New Template</DialogTitle>
                  <DialogContent className={classes.root}>
                      <TextField
                          autoFocus
                          label="Name"
                          type="text"
                          value={templateName} 
                          onChange={templateNameChangeHandler}
                          fullWidth
                      />
                  </DialogContent>
                  <DialogActions>
                      <Button onClick={toggleTemplateModal} color="primary">
                          Cancel
                      </Button>
                      <Button type="submit" color="primary">
                          Submit
                      </Button>
                  </DialogActions>
              </form>
        </Root>)
    );
}

export default SaveAsTemplateModal;