import React, { useState, useContext } from 'react';
import { BlockData, BlockStyle, BlockEvent, BlockEventTrigger, EventType, EventTriggerType, actionCreators } from '../../store/Block';
import { Box, Collapse, Divider, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, Paper, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { getReferencedBlockStyles } from '../../Utility';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import BlockPropsDialog from './BlockEditDialogs/BlockPropsDialog';
import EventTriggerDialog from './BlockEditDialogs/EventTriggerDialog';
import EditSharpIcon from '@mui/icons-material/EditSharp';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import BlockStyleDialog from './BlockEditDialogs/BlockStyleDialog';
import EventDialog from './BlockEditDialogs/EventDialog';
import { PageData } from '../../store/Page';
import { DynPropAssignment } from '../../store/DynData';
import { useDispatch, useSelector } from 'react-redux';
import PropAssignmentDialog from './BlockEditDialogs/PropAssignmentDialog';
import { EventContext } from '../Contexts/BlockEventContext';
import { selectGlobalBlockStyles } from '../../store/selectors/BlockSelector';

interface Props {
    addBlockStyleHandler: (newBlockStyle: BlockStyle) => void;
    deleteBlockStyleHandler: (styleId: number) => void;
    updateBlockStyleHandler: (updatedBlockStyle: BlockStyle) => void;
}

const PREFIX = 'BlockEdit';

const classes = {
    editRoot: `${PREFIX}-editRoot`,
    listroot: `${PREFIX}-listroot`,
    tabwidth: `${PREFIX}-tabwidth`,
    propsInputs: `${PREFIX}-propsInputs`,
    input: `${PREFIX}-input`,
    tabContent: `${PREFIX}-tabContent`,
    formControl: `${PREFIX}-formControl`,
    selectEmpty: `${PREFIX}-selectEmpty`,
    button: `${PREFIX}-button`,
    contentInput: `${PREFIX}-contentInput`,
    mediaCardImageBox: `${PREFIX}-mediaCardImageBox`,
    mediaCardImage: `${PREFIX}-mediaCardImage`,
    eventPaper: `${PREFIX}-eventPaper`,
    buttonCol: `${PREFIX}-buttonCol`,
    styleRow: `${PREFIX}-styleRow`,
    section: `${PREFIX}-section`,
};

const Root = styled('div')((
    {
        theme
    }
) => ({
    [`&.${classes.editRoot}`]: {
        flex: '1',
        '& th': {
            fontWeight: 'bold',
        },
        maxHeight: 'calc(100vh - 65px)',
        overflowY: 'scroll',
    },
    [`& .${classes.listroot}`]: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    [`& .${classes.tabwidth}`]: {
        minWidth: 0
    },
    [`& .${classes.propsInputs}`]: {
        '& > *': {
            margin: theme.spacing(1, 0),
        },
    },
    [`& .${classes.input}`]: {
        fontSize: 'small',
    },
    [`& .${classes.tabContent}`]: {
        height: 'calc(100vh - 65px)',
        maxHeight: 'calc(100vh - 65px)',
        overflowY: 'scroll'
    },
    [`& .${classes.formControl}`]: {
        width: '100%',
    },
    [`& .${classes.selectEmpty}`]: {
        marginTop: theme.spacing(2),
    },
    [`& .${classes.button}`]: {
        margin: theme.spacing(1)
    },
    [`& .${classes.contentInput}`]: {
        width: '100%',
        height: '200px',
    },
    [`& .${classes.mediaCardImageBox}`]: {
        height: '264px',
        width: '100%',
    },
    [`& .${classes.mediaCardImage}`]: {
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundColor: theme.palette.primary.main,
        margin: 'auto',
        height: '264px',
    },
    [`& .${classes.eventPaper}`]: {
        margin: theme.spacing(1, 0)
    },
    [`& .${classes.buttonCol}`]: {
        width: '5%'
    },
    [`& .${classes.styleRow}`]: {
        marginBottom: theme.spacing(1)
    },
    [`& .${classes.section}`]: {
        '& span': {
            fontWeight: 'bold',
        },
        textTransform: 'uppercase'
    },
}));

const BlockEditGlobal = (props: Props) => {
    const {
        addBlockStyleHandler,
        updateBlockStyleHandler,
        deleteBlockStyleHandler,
    } = props;

    const [BlockStyleDialogOpen, setBlockStyleDialogOpen] = useState(false);

    const [SelectedBlockStyle, setSelectedBlockStyle] = useState<BlockStyle | undefined>();

    const blockStyles = useSelector(selectGlobalBlockStyles);

    const submitBlockStyleDialogHandler = (blockStyle: BlockStyle) => {
        if (blockStyle.id === 0) {
            addBlockStyleHandler(blockStyle);
        }
        else {
            updateBlockStyleHandler(blockStyle);
        }
    }

    const closeBlockStyleDialogHandler = () => {
        setSelectedBlockStyle(undefined);
        setBlockStyleDialogOpen(prev => !prev);
    }

    const openBlockStyleDialogHandler = (id?: number) => {
        if (id) {
            const blockStyle = blockStyles.find(x => x.id === id);
            if (blockStyle !== undefined) {
                setSelectedBlockStyle(blockStyle);
            }
        }

        setBlockStyleDialogOpen(prev => !prev);
    }

    const sortStyles = (n1: BlockStyle, n2: BlockStyle) => {
        if (n1.selector > n2.selector)
            return 1;

        if (n1.selector < n2.selector)
            return -1;

        return 0;
    }

    return (
        <Root className={classes.editRoot}>
            <form noValidate autoComplete="off">
                <List>
                    <ListItem className={classes.section}>
                        <ListItemText primary={`style`} />
                        <ListItemSecondaryAction>
                            <IconButton
                                edge="end"
                                onClick={() => openBlockStyleDialogHandler()}
                                size="large">
                                <AddCircleIcon />
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                    <Divider />
                    {
                        blockStyles?.length ?
                            <>
                                <Box margin={1}>
                                    <List style={{ width: '100%' }} dense>
                                        {
                                            blockStyles
                                                .sort(sortStyles)
                                                .map((style) => (
                                                    <Paper elevation={3} key={style.id}>
                                                        <Divider />
                                                        <ListItem>
                                                            <ListItemText primary={style.selector + "-" + style.id.toString()} secondary={style.screenMode} />
                                                            <ListItemSecondaryAction>
                                                                <IconButton edge="end" size="small" onClick={() => openBlockStyleDialogHandler(style.id)}>
                                                                    <EditSharpIcon />
                                                                </IconButton>
                                                                <IconButton
                                                                    edge="end"
                                                                    onClick={() => deleteBlockStyleHandler(style.id)}
                                                                    size="large">
                                                                    <RemoveCircleIcon />
                                                                </IconButton>
                                                            </ListItemSecondaryAction>
                                                        </ListItem>
                                                        {
                                                            style.blockStyleData.length ?
                                                                <TableContainer className={classes.styleRow} elevation={3} component={Paper}>
                                                                    <Table size="small" aria-label="a dense table">
                                                                        <TableHead>
                                                                            <TableRow>
                                                                                <TableCell>Attribute</TableCell>
                                                                                <TableCell>Value</TableCell>
                                                                            </TableRow>
                                                                        </TableHead>
                                                                        <TableBody>
                                                                            {style.blockStyleData.map((row) => (
                                                                                <TableRow key={row.id}>
                                                                                    <TableCell>
                                                                                        {row.attributeKey}
                                                                                    </TableCell>
                                                                                    <TableCell>
                                                                                        {row.attributeValue}
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                            ))}
                                                                        </TableBody>
                                                                    </Table>
                                                                </TableContainer>
                                                                : null
                                                        }
                                                    </Paper>
                                                ))
                                        }
                                    </List>
                                </Box>
                            </>
                            : null
                    }
                </List>
            </form>
            <BlockStyleDialog blockStyle={SelectedBlockStyle} dialogOpenHandler={closeBlockStyleDialogHandler} isOpen={BlockStyleDialogOpen} submitHandler={submitBlockStyleDialogHandler} />
        </Root>
    );
}

export default BlockEditGlobal;