import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function Footer(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path d="M20.4,0H3.6C1.6,0,0,1.6,0,3.6v16.8c0,2,1.6,3.6,3.6,3.6h16.8c2,0,3.6-1.6,3.6-3.6V3.6C24,1.6,22.4,0,20.4,0z
	 M3.6,1.3h16.8c1.3,0,2.3,1,2.3,2.3v14.9H1.3V3.6C1.3,2.3,2.3,1.3,3.6,1.3z"/>
        </SvgIcon>
    );
}