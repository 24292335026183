import React, { ChangeEvent } from 'react';
import RemoveIcon from '@mui/icons-material/Remove';
import { BlockStyleData } from '../../store/Block';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { MenuItem, Select, SelectChangeEvent, styled, TableCell, TableRow } from '@mui/material';
import { DynDataEntry, DynModel, DynProps } from '../../store/DynData';

interface Props {
    entry: DynDataEntry,
    model: DynModel,
    changeEntry: (entryId: number, tag: string, value: string) => void,
    deleteEntry: (entryId: number) => void,
}

const PREFIX = 'DynPropControlRow';

const classes = {
    inputs: `${PREFIX}-inputs`,
    container: `${PREFIX}-container`,
    removeStyle: `${PREFIX}-removeStyle`,
    delimiter: `${PREFIX}-delimiter`,
    button: `${PREFIX}-button`,
    removeIcon: `${PREFIX}-removeIcon`,
    removeCell: `${PREFIX}-removeCell`,
};

const Root = styled(TableRow)((
    {
        theme
    }
) => ({
    [`& .${classes.inputs}`]: {
        fontSize: theme.typography.subtitle2.fontSize,
        padding: 0,
    },
    [`& .${classes.removeStyle}`]: {
        width: '3%'
    },
    [`& .${classes.delimiter}`]: {
        width: '2%',
        textAlign: 'center',
    },
    [`& .${classes.button}`]: {
        padding: 0,
    },
    [`& .${classes.removeIcon}`]: {
        fontSize: '18px',
    },
    [`& .${classes.removeCell}`]: {
        width: '12px',
    },
    [`& .${classes.container}`]: {
        '& th': {
            fontWeight: 'bold',
        },
    },
}));

const DynDataSetControl = (props: Props) => {

    const { entry, model, changeEntry, deleteEntry } = props;

    const dataObject = JSON.parse(entry.data);

    return (
        <Root className={classes.container}>
            {
                model.dynPropSets?.map((prop) => (            
                    <TableCell key={prop.id}>
                        <TextField
                            size="small"
                            type="text"
                            value={dataObject[prop.tag]}
                            onChange={(e) => changeEntry(entry.id, prop.tag, e.target.value)}
                            fullWidth
                            hiddenLabel
                            margin="dense"
                            variant="standard"
                            multiline
                        />
                    </TableCell>
                ))
            }
            <TableCell className={classes.removeCell}>
                <IconButton edge="end" className={classes.button} aria-label="delete" color="primary" size="small" onClick={() => deleteEntry(entry.id)}>
                    <RemoveIcon className={classes.removeIcon} />
                </IconButton>
            </TableCell>
        </Root>
    )
}

export default DynDataSetControl;