import { AppBar, Button, IconButton, Paper, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Toolbar } from '@mui/material';
import React, { useEffect, useState } from 'react';
import AddBoxSharpIcon from '@mui/icons-material/AddBoxSharp';
import DeleteSharpIcon from '@mui/icons-material/DeleteSharp';
import EditIcon from '@mui/icons-material/Edit';
import { actionCreators, DynDataState, DynModel } from '../../store/DynData';
import { connect, useDispatch, useSelector } from 'react-redux';
import DynModelDialog from './DynModelDialog';
import ConfirmationDialog from '../UI/ConfirmationDialog';
import { selectDynModels } from '../../store/selectors/DynDataSelector';

const DynModelList = () => {

    const[dialogOpen, setDialogOpen] = useState<boolean>(false);
    const[selectedDynModel, setSelectedDynModel] = useState<DynModel | undefined>();
    const [confirmOpen, setConfirmOpen] = useState(false);

    const dispatch = useDispatch();
    const dynModels = useSelector(selectDynModels)
    const PREFIX = 'DynModelList';

    const classes = {
        root: `${PREFIX}-root`,
        gridroot: `${PREFIX}-gridroot`,
        expandOpen: `${PREFIX}-expandOpen`,
        button: `${PREFIX}-button`,
        extendedIcon: `${PREFIX}-extendedIcon`,
        buttonCell: `${PREFIX}-buttonCell`,
        tableContainer: `${PREFIX}-tableContainer`
    };

    const Root = styled('div')((
        {
            theme
        }
    ) => ({
        [`& .${classes.root}`]: {
            marginLeft: '239px',
        },
        [`& .${classes.gridroot}`]: {
            padding: theme.spacing(1),
        },
        [`& .${classes.expandOpen}`]: {
            transform: 'rotate(180deg)',
        },
        [`& .${classes.button}`]: {
            marginLeft: theme.spacing(1),
        },
        [`& .${classes.extendedIcon}`]: {
            marginRight: theme.spacing(1),
        },
        [`& .${classes.buttonCell}`]: {
            width: '150px',
        },
        [`& .${classes.tableContainer}`]: {
            maxHeight: 'calc(100vh - 80px)',
            '& th': {
                fontWeight: 'bold',
            }
        }
    }));

    useEffect(() => {
        dispatch(actionCreators.getModels());
    }, [])

    const toggleModelDialog = () => setDialogOpen(prevState => {
        if (prevState) {
            setSelectedDynModel(undefined);
        }

        return !prevState
    });


    const closeDialogHandler = () => {
        setSelectedDynModel(undefined);
        setDialogOpen(prev => !prev);
    }
    const handleConfirmOpen = (model: DynModel) => {
        setSelectedDynModel(model);
        toggleConfirmOpen();
    }

    const deleteDataSetHandler = () => {
        if(selectedDynModel)
            dispatch(actionCreators.deleteModel(selectedDynModel));
    }

    const toggleConfirmOpen = () => {
        setConfirmOpen(prevState => {
            if (prevState) {
                setSelectedDynModel(undefined);
            }

            return !prevState
        });
    }

    const handleDataSetDialogSubmit = (dataSet: DynModel) => {
        if(dataSet.id === 0){
            dispatch(actionCreators.createModel(dataSet));
        } else {
            dispatch(actionCreators.updateModel(dataSet));
        }
        setSelectedDynModel(undefined);
    }

    const toggleEditDynModelModal = (selectedModel: DynModel) => {
        setSelectedDynModel(selectedModel);
        toggleModelDialog();
    };

    const models = dynModels ? dynModels.sort((n1: DynModel, n2: DynModel) => {
        if (n1.name > n2.name)
            return 1;

        if (n1.name < n2.name)
            return -1;
        return 0;
    }).map((model: DynModel) => (
        <TableRow key={model.id}>
            <TableCell>{model.name}</TableCell>
            <TableCell className={classes.buttonCell}>
                <IconButton size="small" onClick={() => toggleEditDynModelModal(model)}>
                    <EditIcon />
                </IconButton>
                <IconButton size="small" onClick={() => handleConfirmOpen(model)}>
                    <DeleteSharpIcon />
                </IconButton>
            </TableCell>
        </TableRow>
    )) : null;

    return (
        <Root>
            <div className={classes.root}>
                <AppBar position="static" color="primary">
                    <Toolbar disableGutters>
                        <Button className={classes.button} onClick={toggleModelDialog} variant="contained" color="secondary" startIcon={<AddBoxSharpIcon />}>
                            Add Model
                        </Button>
                    </Toolbar>
                </AppBar>
                <div className={classes.gridroot}>
                    <TableContainer className={classes.tableContainer} component={Paper}>
                        <Table stickyHeader sx={{ minWidth: 700 }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {models}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>
            <DynModelDialog dialogOpen={dialogOpen} closeDialog={closeDialogHandler} submitDialog={handleDataSetDialogSubmit} currentModel={selectedDynModel}/>
            <ConfirmationDialog isOpen={confirmOpen} clickHandler={toggleConfirmOpen} submitFunction={deleteDataSetHandler} />
        </Root>
    );
}

export default DynModelList;