import { Reducer } from "redux";
import { updateObject } from "../Utility";
import Axios from '../services/api';
import { ApplicationState, AppThunkAction } from '.';
import SnackBarUtils from "../components/SnackBar/SnackBarOperations";

export interface LanguageState {
    languages: Language[],
    translationTags: TranslationTag[],
    loading: boolean,
};

const unloadedState: LanguageState = {
    languages: [],
    translationTags: [],
    loading: false,
};

export interface Language {
    id: number,
    name: string,
    abbreviation: string,
    defaultLanguage: boolean,
}

export interface TranslationTag {
    id: number,
    name: string,
    translations: Translation[],
}

export interface Translation {
    id: number,
    text: string,
    language: Language,
}

export interface LanguageStartAction {
    type: 'LANGUAGE_START',
}

export interface LanguageFailAction {
    type: 'LANGUAGE_FAIL',
    error: string
}

export interface LanguageSuccessAction {
    type: 'LANGUAGE_SUCCESS',
    languages: Language[],
}

export interface LanguageTranslationTagsSuccessAction {
    type: 'LANGUAGE_TRANSLATION_TAGS_SUCCESS',
    translationTags: TranslationTag[],
}

export interface LanguageTranslationTagAddSuccessAction {
    type: 'LANGUAGE_ADD_TRANSLATION_TAG_SUCCESS',
    translationTag: TranslationTag,
}

export interface LanguageTranslationTagUpdateSuccessAction {
    type: 'LANGUAGE_UPDATE_TRANSLATION_TAG_SUCCESS',
    translationTag: TranslationTag,
}

export interface LanguageTranslationTagDeleteSuccessAction {
    type: 'LANGUAGE_DELETE_TRANSLATION_TAG_SUCCESS',
    id: number,
}

export type KnownAction =
    | LanguageTranslationTagsSuccessAction
    | LanguageSuccessAction
    | LanguageFailAction
    | LanguageStartAction
    | LanguageTranslationTagAddSuccessAction
    | LanguageTranslationTagUpdateSuccessAction
    | LanguageTranslationTagDeleteSuccessAction;

export const actionCreators = {
    getTranslationTags: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'LANGUAGE_START' });
        const appState = getState();

        if (!appState.auth)
            return;

        const url = '/language';
        Axios.get(url)
            .then(response => {
                dispatch({ type: 'LANGUAGE_TRANSLATION_TAGS_SUCCESS', translationTags: response.data });
            })
            .catch(error => {
                dispatch({ type: 'LANGUAGE_FAIL', error: error.response.data.error });
            })
    },
    createTranslationTag: (translationTag: TranslationTag): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'LANGUAGE_START' });
        const appState = getState();

        if (!appState.auth)
            return;

        const url = '/language';
        Axios.post(url, translationTag)
            .then(response => {
                dispatch({ type: 'LANGUAGE_ADD_TRANSLATION_TAG_SUCCESS', translationTag: response.data });
            })
            .catch(error => {
                dispatch({ type: 'LANGUAGE_FAIL', error: error.response.data.error });
            })
    },
    updateTranslationTag: (translationTag: TranslationTag): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'LANGUAGE_START' });
        const appState = getState();

        if (!appState.auth)
            return;

        const url = '/language/update';
        Axios.post(url, translationTag)
            .then(response => {
                dispatch({ type: 'LANGUAGE_UPDATE_TRANSLATION_TAG_SUCCESS', translationTag: response.data });
            })
            .catch(error => {
                dispatch({ type: 'LANGUAGE_FAIL', error: error.response.data.error });
            })
    },
    deleteTranslationTag: (id: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'LANGUAGE_START' });
        const appState = getState();

        if (!appState.auth)
            return;

        const url = '/language/' + id;
        Axios.delete(url)
            .then(response => {
                dispatch({ type: 'LANGUAGE_DELETE_TRANSLATION_TAG_SUCCESS', id: id });
            })
            .catch(error => {
                dispatch({ type: 'LANGUAGE_FAIL', error: error.response.data.error });
            })
    }
};

export const reducer: Reducer<LanguageState | undefined> = (state: LanguageState | undefined, incomingAction: KnownAction): LanguageState | undefined => {
    if (state === undefined) {
        return unloadedState;
    }
    switch (incomingAction.type) {
        case 'LANGUAGE_START':
            return updateObject(state, { loading: true });
        case 'LANGUAGE_FAIL':
            return updateObject(state, { loading: false });
        case 'LANGUAGE_SUCCESS':
            return updateObject(state, { languages: incomingAction.languages, loading: false });
        case 'LANGUAGE_TRANSLATION_TAGS_SUCCESS':
            return updateObject(state, { translationTags: incomingAction.translationTags, loading: false });
        case 'LANGUAGE_ADD_TRANSLATION_TAG_SUCCESS':
            return updateObject(state, { translationTags: [...state.translationTags, incomingAction.translationTag], loading: false });
        case 'LANGUAGE_UPDATE_TRANSLATION_TAG_SUCCESS':
            return updateObject(state, { translationTags: [...state.translationTags.filter(x => x.id !== incomingAction.translationTag.id), incomingAction.translationTag], loading: false });
        case 'LANGUAGE_DELETE_TRANSLATION_TAG_SUCCESS':
            return updateObject(state, { translationTags: [...state.translationTags.filter(x => x.id !== incomingAction.id)], loading: false });
        default:
            return state;
    }
}