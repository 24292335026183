import { Reducer } from "redux";
import { updateObject } from "../Utility";
import { BlockData } from "./Block";
import Axios from '../services/api';
import { AppThunkAction } from '.';
import SnackBarUtils from "../components/SnackBar/SnackBarOperations";
import { DynDataSet } from "./DynData";

export interface PageData {
    id: number,
    metaTitle: string,
    metaDescription: string,
    name: string,
    blockData: BlockData[],
    withNavbar: boolean,
    withFooter: boolean,
    dynamic: boolean,
    dynDataSet?: DynDataSet,
}

export interface FooterData {
    id: number,
    blockData: BlockData[],
}

export interface HeaderData {
    id: number,
    blockData: BlockData[],
}

export interface PageState {
    pages: PageData[],
    footer: BlockData[],
    header: BlockData[],
    selectedPage: PageData | null,
    footerSelected: boolean,
    headerSelected: boolean,
    error: string,
    loading: boolean,
    headerLoading: boolean,
    footerLoading: boolean,
};

export interface PageSuccessAction {
    type: 'PAGE_SUCCESS',
    pages: PageData[]
}

export interface PageAddSuccessAction {
    type: 'PAGE_ADD_SUCCESS',
    page: PageData
}

export interface PageDeleteSuccessAction {
    type: 'PAGE_DELETE_SUCCESS',
    page: PageData
}

export interface PageUpdateSuccessAction {
    type: 'PAGE_UPDATE_SUCCESS',
    page: PageData
}

export interface PageSelectAction {
    type: 'PAGE_SELECT',
    page: PageData | null
}

export interface PageFailAction {
    type: 'PAGE_FAIL',
    error: string
}

export interface PageStartAction {
    type: 'PAGE_START'
}

export interface HeaderStartAction {
    type: 'HEADER_START'
}

export interface FooterStartAction {
    type: 'FOOTER_START'
}

export interface HeaderSuccessAction {
    type: 'HEADER_SUCCESS',
    headerData: BlockData[],
}

export interface FooterSuccessAction {
    type: 'FOOTER_SUCCESS',
    footerData: BlockData[]
}

export interface FooterSelectAction {
    type: 'FOOTER_SELECT',
    selected: boolean,
}

export interface HeaderSelectAction {
    type: 'HEADER_SELECT'
    selected: boolean,
}

export type KnownAction =
    | PageSuccessAction
    | PageAddSuccessAction
    | PageDeleteSuccessAction
    | PageUpdateSuccessAction
    | PageSelectAction
    | PageFailAction
    | PageStartAction
    | HeaderStartAction
    | FooterStartAction
    | HeaderSuccessAction
    | HeaderSelectAction
    | FooterSuccessAction
    | FooterSelectAction
    | HeaderStartAction
    | FooterStartAction;

export const actionCreators = {
    getFooter: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'FOOTER_START' });
        const appState = getState();

        if (!appState.auth)
            return;

        const url = '/page/footer';
        Axios.get(url)
            .then(response => {
                SnackBarUtils.success('Footer wurde geladen.');
                dispatch({ type: 'FOOTER_SUCCESS', footerData: response.data });
            })
            .catch(error => {
                SnackBarUtils.error('Beim Laden des Footers ist ein Fehler aufgetreten.');
                dispatch({ type: 'PAGE_FAIL', error: error.response.data.error });
            })
    },
    getHeader: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'HEADER_START' });
        const appState = getState();

        if (!appState.auth)
            return;

        const url = '/page/header';
        Axios.get(url)
            .then(response => {
                SnackBarUtils.success('Header wurde geladen.');
                dispatch({ type: 'HEADER_SUCCESS', headerData: response.data });
            })
            .catch(error => {
                SnackBarUtils.error('Beim Laden des Headers ist ein Fehler aufgetreten.');
                dispatch({ type: 'PAGE_FAIL', error: error.response.data.error });
            })
    },
    selectHeader: (selected: boolean): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'HEADER_SELECT', selected: selected });
    },
    selectFooter: (selected: boolean): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'FOOTER_SELECT', selected: selected });
    },
    getPages: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'PAGE_START' });
        const appState = getState();

        if (!appState.auth)
            return;

        const url = '/page';
        Axios.get(url)
            .then(response => {
                SnackBarUtils.success('Seiten wurden geladen.');
                dispatch({ type: 'PAGE_SUCCESS', pages: response.data });
            })
            .catch(error => {
                SnackBarUtils.error('Beim Laden der Seiten ist ein Fehler aufgetreten.');
                dispatch({ type: 'PAGE_FAIL', error: error.response.data.error });
            })
    },
    createPage: (newPage: PageData): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'PAGE_START' });
        const appState = getState();

        if (!appState.auth)
            return;

        const url = '/page';
        Axios.post(url, newPage)
            .then(response => {
                SnackBarUtils.success('Seite wurde erfolgreich erstellt.');
                dispatch({ type: 'PAGE_ADD_SUCCESS', page: response.data });

            })
            .catch(error => {
                SnackBarUtils.error('Ein Fehler ist aufgetreten');
                dispatch({ type: 'PAGE_FAIL', error: error.response.data.error });
            })
    },
    updatePage: (newPage: PageData): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'PAGE_START' });
        const appState = getState();

        if (!appState.auth)
            return;

        const url = '/page/update';
        Axios.post(url, newPage)
            .then(response => {
                SnackBarUtils.success('Seite wurde erfolgreich geändert.');
                dispatch({ type: 'PAGE_UPDATE_SUCCESS', page: response.data });
            })
            .catch(error => {
                SnackBarUtils.error('Ein Fehler ist aufgetreten');
                dispatch({ type: 'PAGE_FAIL', error: error.response.data.error });
            })
    },
    selectPage: (page: PageData | null): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'PAGE_SELECT', page: page });
    },
    deletePage: (page: PageData): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'PAGE_START' });
        const appState = getState();

        if (!appState.auth)
            return;

        const url = '/page/' + page.id;
        Axios.delete(url)
            .then(response => {
                SnackBarUtils.success('Seite wurde erfolgreich gelöscht.');
                dispatch({ type: 'PAGE_DELETE_SUCCESS', page: page });
            })
            .catch(error => {
                SnackBarUtils.success('Beim Löschen der Seite ist ein Fehler aufgetreten.');
                dispatch({ type: 'PAGE_FAIL', error: error.response.data.error });
            })
    }
};

const unloadedState: PageState = {
    pages: [],
    header: [],
    footer: [],
    selectedPage: null,
    error: "",
    loading: false,
    headerLoading: false,
    footerLoading: false,
    footerSelected: false,
    headerSelected: false,
};

export const reducer: Reducer<PageState | undefined> = (state: PageState | undefined, incomingAction: KnownAction): PageState | undefined => {
    if (state === undefined) {
        return unloadedState;
    }
    switch (incomingAction.type) {
        case 'PAGE_SUCCESS':
            return updateObject(state, { pages: [...incomingAction.pages], error: null, loading: false });
        case 'PAGE_ADD_SUCCESS':
            return updateObject(state, { pages: [...state.pages, incomingAction.page], error: null, loading: false });
        case 'PAGE_UPDATE_SUCCESS':
            return updateObject(state, { pages: [...state.pages.filter((x: PageData) => x.id !== incomingAction.page.id), { ...incomingAction.page }], error: null, loading: false });
        case 'PAGE_DELETE_SUCCESS':
            return updateObject(state, { pages: [...state.pages.filter(x => x.id !== incomingAction.page.id)], error: null, loading: false });
        case 'PAGE_SELECT':
            return updateObject(state, { selectedPage: incomingAction.page });
        case 'PAGE_START':
            return updateObject(state, { error: null, loading: true });
        case 'HEADER_START':
            return updateObject(state, { error: null, headerLoading: true });
        case 'FOOTER_START':
            return updateObject(state, { error: null, footerLoading: true });
        case 'PAGE_FAIL':
            return updateObject(state, { error: incomingAction.error, loading: false, headerLoading: false, footerLoading: false });
        case 'HEADER_SUCCESS':
            return updateObject(state, { header:  [...incomingAction.headerData] , error: null, headerLoading: false });
        case 'FOOTER_SUCCESS':
            return updateObject(state, { footer:  [...incomingAction.footerData] , error: null, footerLoading: false });
        case 'HEADER_SELECT':
            return updateObject(state, { headerSelected: incomingAction.selected });
        case 'FOOTER_SELECT':
            return updateObject(state, { footerSelected: incomingAction.selected });
        default:
            return state;
    }
}