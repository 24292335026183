/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react';

import React from 'react';
import { useSelector } from 'react-redux';
import { BlockData } from '../../store/Block';
import { DynDataEntry } from '../../store/DynData';
import Block from './Block';

interface Props {
    blocks: BlockData[];
    selectedBlockId: number;
    scrollHeight: number;
    canvasHeight: number;
    disabled: boolean;
    canvasMode: string;
    viewMode: string;
    dataEntry?: DynDataEntry;
}

const BlockWall = (props: Props) => {

    const { blocks, selectedBlockId, disabled, canvasMode, viewMode, scrollHeight, canvasHeight, dataEntry } = props;


    const displayedBlocks = blocks.length ? blocks
        .filter(x => x.locationPath?.indexOf('-') === -1)
        .sort((a, b) => a.sortPath - b.sortPath)
        .map((block, index) => (
            <Block
                key={block.id}
                currentBlock={block}
                blocks={blocks.filter(x => x.locationPath?.startsWith(block.id + '-'))}
                selectedBlockId={selectedBlockId}
                disabled={disabled}
                previousBlock={undefined}
                canvasMode={canvasMode}
                viewMode={viewMode}
                scrollHeight={scrollHeight}
                canvasHeight={canvasHeight}
                dataEntry={dataEntry}
            />
        )) : null;

    return (
        <>
            {displayedBlocks}
        </>
    );
}

export default BlockWall;