import { Box, TextField, FormControl, InputLabel, Select, MenuItem, FormControlLabel, Checkbox, Button, Dialog, DialogActions, DialogContent, DialogTitle, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { BlockData, BlockEvent, EventType } from '../../../store/Block';
import useDeepEffect from '../../../hooks/useDeepEffect';
import { DynPropAssignment, DynProps } from '../../../store/DynData';
import PropAssignmentControl from '../PropAssignmentControl';
import AddIcon from '@mui/icons-material/Add';
import { useSelector } from 'react-redux';
import { selectSelectedPropAssignments } from '../../../store/selectors/BlockSelector';

const PREFIX = 'PropAssignmentDialog';

const classes = {
    formControl: `${PREFIX}-formControl`,
    dialogContent: `${PREFIX}-dialogContent`,
    addButton: `${PREFIX}-addButton`
};

const StyledDialog = styled(Dialog)((
    {
        theme
    }
) => ({
    [`& .${classes.formControl}`]: {
        width: '100%',
    },
    [`& .${classes.dialogContent}`]: {
        '& > *': {
            marginTop: theme.spacing(1),
        },
    },
    [`& .${classes.addButton}`]: {
        width: '20px',
        minWidth: '20px',
        height: 'inherit'
    }
}));

interface Props {
    propAssignments: DynPropAssignment[],
    dialogOpenHandler: () => void;
    submitHandler: (propAssignments: DynPropAssignment[]) => void;
    isOpen: boolean;
    dynProps: DynProps[];
    selectedBlockType: string;
}

const PropAssignmentDialog = (props: Props) => {

    const { propAssignments, isOpen, dialogOpenHandler, submitHandler, dynProps, selectedBlockType } = props;

    const [currentPropAssignments, setCurrentPropAssignments] = useState<DynPropAssignment[]>([]);

    useEffect(() => {
        if(isOpen){
            setCurrentPropAssignments(propAssignments);
        }
    }, [propAssignments, isOpen]);

    const handleBlockPropChange = (value: string, id: number) => {
        let newAssignments = [...currentPropAssignments];
        let blockProp = newAssignments.find(x => x.id === id);

        if (blockProp) {
            blockProp.blockProp = value;
        }

        setCurrentPropAssignments(newAssignments);
    }

    const handleDynPropChange = (value: string, id: number) => {
        let newAssignments = [...currentPropAssignments];
        let dynProp = newAssignments.find(x => x.id === id);
        let newDynProp = dynProps.find(x => x.tag === value);
        if (dynProp && newDynProp) {
            dynProp.dynProp = newDynProp;
        }

        setCurrentPropAssignments(newAssignments);
    }

    const handleDeletePropAssignment = (id: number) => {
        setCurrentPropAssignments([...currentPropAssignments.filter(x => x.id !== id)]);
    }

    const handleNewPropAssignment = () => {
        let newId = 999 + Math.random().toString().substr(2, 5);
        let newAssignments = [...currentPropAssignments];
        newAssignments.push({ id: Number(newId), blockProp: '', dynProp: dynProps[0] });
        setCurrentPropAssignments(newAssignments);
    }

    const handleOnClose = () => {
        setCurrentPropAssignments([]);
        dialogOpenHandler();
    }

    const handleOnSubmit = () => {
        submitHandler(currentPropAssignments);
        handleOnClose();
    }
    
    return (
        <StyledDialog
            open={isOpen}
            onClose={handleOnClose}
            fullWidth
            maxWidth="md"
        >
            <DialogTitle id="alert-dialog-title">{"Prop assignment"}</DialogTitle>
            <DialogContent className={classes.dialogContent}>

                <Stack direction="row" spacing={2}>
                    <Button className={classes.addButton} variant="contained" onClick={handleNewPropAssignment}>
                        <AddIcon />
                    </Button>
                    <TableContainer component={Paper}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Dyn prop</TableCell>
                                    <TableCell>Block prop</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    currentPropAssignments ?
                                        currentPropAssignments?.map((prop) => (
                                            <PropAssignmentControl key={prop.id} propAssignment={prop} selectedBlockType={selectedBlockType} dynProps={dynProps} handleBlockPropChange={handleBlockPropChange} handleDynPropChange={handleDynPropChange} handleDeletePropAssignment={handleDeletePropAssignment} />
                                        )) : null
                                }
                            </TableBody>
                        </Table>
                    </TableContainer >
                </Stack>

            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={handleOnClose}>
                    Cancel
                </Button>
                <Button color="primary" onClick={handleOnSubmit}>
                    Save
                </Button>
            </DialogActions>
        </StyledDialog>
    );
}

export default PropAssignmentDialog;