import * as React from 'react';
import { useSelector } from 'react-redux';
import useDeepEffect from '../../hooks/useDeepEffect';
import { Signal } from '../../services/events/Signal';
import { BlockEvent, EventTriggerType, EventType } from '../../store/Block';
import { selectLanguages } from '../../store/selectors/ConfigSelector';
import { selectTranslationTags } from '../../store/selectors/LanguageSelector';

interface TranslationContext {
    translate: (tag: string) => string,
    setSelectedLanguageIndex: (index: number) => void,
    languageIndex: number,
}

export const TranslationContext = React.createContext<TranslationContext>({
    translate: () => "",
    setSelectedLanguageIndex: () => { },
    languageIndex: 0,
});

interface Props {
    children: React.ReactNode
}

const BlockTranslationContext = (props: Props) => {
    const { children } = props;

    const translationTags = useSelector(selectTranslationTags);
    const languages = useSelector(selectLanguages);
    const [languageIndex, setLanguageIndex] = React.useState(0);

    const setSelectedLanguageIndex = (index: number) => {
        setLanguageIndex(prev => prev + index);
    }

    const translate = (tag: string): string => {
        const language = languages[languageIndex];
        if(!translationTags.length)
            return tag;
            
        try{
            const translationTag = translationTags?.find(x => x.name === tag);

            if(translationTag){
                const translation = translationTag?.translations?.find(x => x.language.id === language.id);
                return translation?.text ?? tag;
            }
        }
        catch (e) {
            console.log(translationTags);
            console.log(e);
        }

        return tag;
    }

    return (
        <TranslationContext.Provider value={{ translate: translate, setSelectedLanguageIndex: setSelectedLanguageIndex, languageIndex: languageIndex }}>
            {children}
        </TranslationContext.Provider>
    )
}

export default BlockTranslationContext;