import { DialogTitle, DialogContent, TextField, DialogActions, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { ChangeEvent, FormEvent, useState } from 'react';
import { Theme } from '@mui/material/styles';
import { TemplateData } from '../../../store/Template';

const PREFIX = 'TemplateModal';

const classes = {
    root: `${PREFIX}-root`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
    {
        theme
    }
) => ({
    [`& .${classes.root}`]: {
        '& > *': {
            marginTop: theme.spacing(1),
        },
    }
}));

interface Props {
    templateData: TemplateData | undefined,
    toggleTemplateModal: () => void,
    submitTemplateModal: (newTemplate: TemplateData) => void
}

const TemplateModal = (props: Props) => {


    const {toggleTemplateModal, submitTemplateModal, templateData} = props;

    const [templateName, setTemplateName] = useState(templateData ? templateData.name : "");
    
    const templateNameChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        setTemplateName(event.target.value);
    }
    const templateSubmitHandler = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if(templateData){
            let newTemplateData = {...templateData};
            newTemplateData.name = templateName;

            submitTemplateModal(newTemplateData);
        } else {
            let newTemplateData: TemplateData = {
                id: 0,
                name: templateName
            }
            submitTemplateModal(newTemplateData);
        }
    }

    return (
        (<Root>
            <form noValidate autoComplete="off" onSubmit={templateSubmitHandler}>
                  <DialogTitle id="form-dialog-title">New Template</DialogTitle>
                  <DialogContent className={classes.root}>
                      <TextField
                          autoFocus
                          label="Name"
                          type="text"
                          value={templateName}
                          onChange={templateNameChangeHandler}
                          fullWidth
                      />
                  </DialogContent>
                  <DialogActions>
                      <Button onClick={toggleTemplateModal} color="primary">
                          Cancel
                      </Button>
                      <Button type="submit" color="primary">
                          Submit
                      </Button>
                  </DialogActions>
              </form>
        </Root>)
    );
}

export default TemplateModal;